import * as React from 'react';
import styles from '~/containers/Layout/Header/Header.module.scss';
import { ReactComponent as Chevron } from '~/assets/icons/chevron.svg';
import classNames from 'classnames';
import { ReactComponent as AllGroups } from '~/assets/icons/all-groups.svg';
import { ReactComponent as Add } from '~/assets/icons/add.svg';
import { useOnClickOutside } from 'usehooks-ts';
import { State } from '~/store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import Avatar from '~/components/User/Avatar';
import { BUTTON_COLOR, BUTTON_SIZE, LOADERS, LOADERS_TYPE, META } from '~/const';
import { changeVisiblePopup, updateLoaders, updateMeta } from '~/store/actions/actionApp';
import Button from '~/components/Button/Button';
import { POPUPS_NAME } from '~/components/Popup/Manager';
import { POPUP_WRAPPER } from '~/components/Popup/Popup';
import Input from '~/components/Form/Input/Input';
import { _updateGroup, updateGroup } from '~/store/actions/actionAccount';
import { apiGetGroup } from '~/api/user';
import store from '~/store';
import { ReactComponent as Attention } from '~/assets/icons/attention.svg';
import CircleLoader from '~/components/Loader/Circle/Circle';
import { _updateTaskListOptions } from '~/store/actions/actionTasks';
import { defaultOptions as defaultOptionsTasks } from '~/utils/tasks';
import { defaultOptions as defaultOptionsProjects } from '~/utils/projects';
import { defaultOptions as defaultOptionsUsers } from '~/utils/users';
import { _updateProjectListOptions } from '~/store/actions/actionProjects';
import { _updateUserListOptions } from '~/store/actions/actionUsers';
import { useLocation, useNavigate } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';

const plural = require('plural-ru');

interface IStateSelector {
    meta: IAppMeta;
    account: IAccount;
    usersCount: number;
    loaders: ILoaders;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        meta: state.reducerApp.meta,
        account: state.reducerAccount.account,
        usersCount: state.reducerUsers.userList.totalCount,
        loaders: state.reducerApp.loaders,
    };
};

const Group = () => {
    const ref = React.useRef(null);
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const location = useLocation();
    const navigate = useNavigate();
    const groups = state.account.groups;
    const [drop, setDrop] = React.useState(false);
    const activeGroup = state.account?.groups?.find(
        (group) => group.id === state.meta[META.CURRENT_GROUP],
    );
    const canEdit = activeGroup?.group_role_id === 1;
    const hasInvitations = state.account.groups?.find((group) => group.is_invite);

    useOnClickOutside(ref, () => setDrop(false));

    const toggleHandler = (group: IGroup | null) => {
        if (group?.is_invite) {
            dispatch(
                changeVisiblePopup({
                    name: POPUPS_NAME.ACCEPT_GROUP,
                    data: { group },
                    wrapper: POPUP_WRAPPER.FILTER,
                }),
            );
        } else {
            dispatch(
                updateLoaders({
                    [LOADERS.GROUP_LIST]: {
                        [group?.id || LOADERS_TYPE.LOADING]: true,
                    },
                }),
            );
            dispatch(
                // @ts-ignore
                updateMeta(META.CURRENT_GROUP, group?.id || null, () => {
                    dispatch(
                        updateLoaders({
                            [LOADERS.GROUP_LIST]: {
                                [LOADERS_TYPE.LOADING]: false,
                            },
                        }),
                    );
                }),
            );
            if (group?.id) {
                apiGetGroup(group.id)
                    .then((res) => {
                        store.dispatch(_updateGroup(res.group));
                    })
                    .finally(() => {
                        dispatch(
                            updateLoaders({
                                [LOADERS.GROUP_LIST]: {
                                    [group?.id]: false,
                                },
                            }),
                        );

                        const pathnames = location.pathname.split('/');
                        if (pathnames.length > 2) {
                            navigate(urlsMap.index + pathnames[1]);
                        }
                    });
            }
        }
    };

    const editHandler = (title: string) => {
        // @ts-ignore
        dispatch(updateGroup({ ...activeGroup, title }, activeGroup));
    };

    const deleteHandler = () => {
        dispatch(
            changeVisiblePopup({
                name: POPUPS_NAME.DELETE_GROUP,
                data: { activeGroup },
                wrapper: POPUP_WRAPPER.WHITE,
            }),
        );
    };

    const inviteHandler = () => {
        setDrop(false);
        dispatch(
            changeVisiblePopup({
                name: POPUPS_NAME.GROUP_INVITE,
                data: { groupId: activeGroup?.id },
            }),
        );
    };

    const leaveHandler = () => {
        dispatch(
            changeVisiblePopup({
                name: POPUPS_NAME.LEAVE_GROUP,
                data: { activeGroup },
                wrapper: POPUP_WRAPPER.FILTER,
            }),
        );
    };

    const loading =
        groups?.map((group) => state.loaders[LOADERS.GROUP_LIST][group.id]).includes(true) ||
        state.loaders[LOADERS.GROUP_LIST][LOADERS_TYPE.LOADING] ||
        state.loaders[LOADERS.GROUP][LOADERS_TYPE.LOADING] ||
        state.loaders[LOADERS.TASK_LIST][LOADERS_TYPE.LOADING] ||
        state.loaders[LOADERS.USER_LIST][LOADERS_TYPE.LOADING] ||
        state.loaders[LOADERS.PROJECT_LIST][LOADERS_TYPE.LOADING];

    return (
        <div
            className={classNames({
                [styles.groupDrop]: drop,
            })}
            ref={ref}
        >
            <div className={styles.group} onClick={() => setDrop(!drop)}>
                {activeGroup ? (
                    <Avatar avatar={{ name: activeGroup.title }} />
                ) : (
                    <div className={styles.avatar} style={{ backgroundColor: 'transparent' }}>
                        <AllGroups />
                    </div>
                )}
                {hasInvitations && (
                    <div className={styles.attention}>
                        <Attention />
                    </div>
                )}
                <div className={styles.chevron}>
                    <Chevron />
                </div>
            </div>
            <div className={styles.groupDropdown}>
                <div className={styles.groups}>
                    <div
                        className={classNames({
                            [styles.allActive]: !activeGroup,
                            ['disabled']: loading,
                        })}
                        onClick={() => toggleHandler(null)}
                    >
                        {state.loaders[LOADERS.GROUP_LIST][LOADERS_TYPE.LOADING] ? (
                            <CircleLoader className={styles.groupListLoader} />
                        ) : (
                            <AllGroups />
                        )}
                    </div>
                    {groups?.map((group) => (
                        <div
                            onClick={() => toggleHandler(group)}
                            className={classNames({
                                [styles.active]: activeGroup?.id === group.id,
                                ['disabled']: loading,
                            })}
                            key={group.id}
                        >
                            {state.loaders[LOADERS.GROUP_LIST][group.id] ? (
                                <CircleLoader className={styles.groupListLoader} />
                            ) : (
                                <Avatar
                                    avatar={{ name: group.title }}
                                    attention={group.is_invite}
                                />
                            )}
                        </div>
                    ))}
                    <div
                        onClick={() =>
                            dispatch(
                                changeVisiblePopup({
                                    name: POPUPS_NAME.CREATE_GROUP,
                                    data: undefined,
                                    wrapper: POPUP_WRAPPER.WHITE,
                                }),
                            )
                        }
                    >
                        <Add />
                    </div>
                </div>
                <div className={styles.info}>
                    <div className={styles.infoHeader}>
                        {loading ? (
                            <div className={styles.loader}>
                                <CircleLoader />
                            </div>
                        ) : activeGroup ? (
                            <Avatar avatar={{ name: activeGroup.title }} />
                        ) : (
                            <AllGroups />
                        )}
                        <div className={styles.infoTitle}>
                            {activeGroup ? (
                                <Input
                                    defaultValue={activeGroup.title}
                                    changeHandler={(value: string) => editHandler(value)}
                                    disabled={!canEdit}
                                    debounce={true}
                                    withoutFormik={true}
                                    withoutBorder={true}
                                />
                            ) : (
                                'Все группы'
                            )}
                            <div className={styles.infoSubTitle}>
                                {state.usersCount +
                                    ' ' +
                                    plural(
                                        state.usersCount,
                                        'пользователь',
                                        'пользователя',
                                        'пользователей',
                                    )}
                            </div>
                        </div>
                    </div>
                    {canEdit ? (
                        <div className={styles.buttons}>
                            <Button size={BUTTON_SIZE.SMALL} onClick={inviteHandler}>
                                Пригласить пользователей
                            </Button>
                            <Button
                                size={BUTTON_SIZE.SMALL}
                                color={BUTTON_COLOR.RED}
                                onClick={deleteHandler}
                            >
                                Удалить группу
                            </Button>
                        </div>
                    ) : (
                        activeGroup && (
                            <div className={styles.buttons}>
                                <Button
                                    size={BUTTON_SIZE.SMALL}
                                    color={BUTTON_COLOR.RED}
                                    onClick={leaveHandler}
                                >
                                    Покинуть группу
                                </Button>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default Group;
