import * as React from 'react';
import { useIsFirstRender } from 'usehooks-ts';
import styles from '~/components/Form/Checkbox/Checkbox.module.scss';
import classNames from 'classnames';
import { useDebounce } from 'usehooks-ts';
import Circle from '~/components/Loader/Circle/Circle';

interface IFC {
    label: string;
    checked: boolean;
    disabled?: boolean;
    loading?: boolean;
    name?: string;
    changeHandler?: (value: boolean) => void;
    className?: string;
    debounce?: boolean;
}

const Checkbox = ({
    label,
    checked,
    disabled,
    loading,
    changeHandler,
    name,
    className,
    debounce,
}: IFC) => {
    const [value, setValue] = React.useState<boolean>(checked);
    const debouncedValue = useDebounce(value, 500);

    const isFirstRender = useIsFirstRender();

    React.useEffect(() => {
        !isFirstRender && debounce && value !== checked && changeHandler && changeHandler(value);
    }, [debouncedValue]);

    React.useEffect(() => {
        !isFirstRender && !debounce && changeHandler && changeHandler(value);
    }, [value]);

    React.useEffect(() => {
        setValue(checked);
    }, [checked]);

    return (
        <div className={classNames(styles.checkbox, className)}>
            <label
                htmlFor={name}
                className={classNames(styles.checkboxLabel, {
                    [styles.checked]: value,
                    [styles.disabled]: disabled || loading,
                })}
            >
                <div className={styles.checkboxContainer}>
                    <input
                        id={name}
                        type="checkbox"
                        disabled={disabled || loading}
                        onChange={(event) => setValue(event.target.checked)}
                        checked={value}
                        className={styles.checkboxInput}
                    />
                    <div className={styles.checkboxes} />
                    {loading && <Circle className={styles.checkboxLoader} />}
                </div>
                <span className={styles.checkboxText}>{label}</span>
            </label>
        </div>
    );
};

export default Checkbox;
