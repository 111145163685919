import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '~/containers/Auth/Auth.module.scss';
import { Form, Formik } from 'formik';
import Input from '~/components/Form/Input/Input';
import { validateEmail, validateRequired } from '~/utils/form';
import { BUTTON_SIZE, INPUT_SIZE, INPUT_TYPE } from '~/const';
import Button from '~/components/Button/Button';
import { apiRegister } from '~/api/user';
import { useDocumentTitle } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';

const Register = () => {
    useDocumentTitle('Регистрация');
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = (
        values: Record<string, string>,
        setSubmitting: (isSubmitting: boolean) => void,
        setFieldError: (field: string, message: string) => void,
    ) => {
        const { name, email, password } = values;
        apiRegister({ name, email, password }, true)
            .then(() => {
                navigate(urlsMap.emailConfirm + '?email=' + email);
            })
            .catch((error) => {
                setFieldError('error', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <div className={styles.authContent}>
            <h2>{t('Вас приветствует foach.todo')}</h2>
            <p>{t('Пожалуйста, зарегистрируйтесь')}</p>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    password: '',
                    error: '',
                }}
                onSubmit={(values, { setSubmitting, setFieldError }) =>
                    handleSubmit(
                        { ...values, name: values.name.trim() },
                        setSubmitting,
                        setFieldError,
                    )
                }
            >
                {({ errors, touched, isSubmitting }) => {
                    return (
                        <Form>
                            <Input
                                name="name"
                                label="Имя"
                                error={errors.name}
                                isTouched={touched.name}
                                validate={validateRequired}
                                size={INPUT_SIZE.BIG}
                                className={styles.inputBlock}
                            />
                            <Input
                                name="email"
                                label="E-mail адрес"
                                error={errors.email}
                                isTouched={touched.email}
                                validate={validateEmail}
                                size={INPUT_SIZE.BIG}
                                className={styles.inputBlock}
                            />
                            <Input
                                name="password"
                                label="Пароль"
                                error={errors.password}
                                isTouched={touched.password}
                                validate={validateRequired}
                                type={INPUT_TYPE.PASSWORD}
                                size={INPUT_SIZE.BIG}
                                className={styles.inputBlock}
                            />
                            {errors.error && (
                                <div className={styles.serverError}>{errors.error}</div>
                            )}
                            <Button
                                size={BUTTON_SIZE.BIG}
                                loading={isSubmitting}
                                disabled={!!Object.keys(errors).length}
                            >
                                {t('Зарегистрироваться')}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default Register;
