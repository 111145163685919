import {
    IAddProjects,
    IAddSprint,
    IUpdateBacklogIds,
    IUpdateProject,
    IUpdateProjectList,
    IUpdateProjectListOptions,
    IUpdateSprintIds,
    IUpdateTaskProjectList,
    IUpdateTaskProjectListOptions,
    IUpdateWikiPage,
} from '~/store/actions/types/actionProjectsTypes';
import { Dispatch } from 'react';
import { IUpdateLoaders, IUpdateMeta } from '~/store/actions/types/actionAppTypes';
import { updateLoaders, updateMeta } from '~/store/actions/actionApp';
import { LOADERS, LOADERS_TYPE, META, PROJECT_FIELDS, USER_META } from '~/const';
import { apiUpdateMeta } from '~/api/user';
import {
    apiCreateWikiPage,
    apiDeleteWikiPage,
    apiGetProject,
    apiGetProjectList,
    apiGetSprint,
    apiGetWiki,
    apiGetWikiPage,
    apiUpdateProject,
    apiUpdateSprint,
    apiUpdateWikiPage,
    apiGetProjectTags,
} from '~/api/project';
import { IAddTasks } from '~/store/actions/types/actionTasksTypes';
import { apiGetTaskList } from '~/api/task';
import { addTasks } from '~/store/actions/actionTasks';
import { defaultOptions, TASKS_FILTERS } from '~/utils/tasks';
import { prepareProjectFromServer } from '~/utils/projects';
import { _updateEntity } from '~/store/actions/actionEntities';
import { ENTITIES } from '~/store/reducers/reducerEntities';
import { IUpdateEntity } from '~/store/actions/types/actionEntitiesTypes';
import { NavigateFunction } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import { IUpdateProjectProcessesOptions } from '~/store/actions/types/actionProjectsTypes';

export const ADD_PROJECTS = 'ADD_PROJECTS';
export const _addProjects = (projects: IProject[], withClearState?: boolean): IAddProjects => {
    return {
        type: ADD_PROJECTS,
        projects,
        withClearState,
    };
};

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const _updateProject = (data: any, id: number): IUpdateProject => {
    return {
        type: UPDATE_PROJECT,
        data,
        id,
    };
};

export const UPDATE_PROJECT_LIST = 'UPDATE_PROJECT_LIST';
export const _updateProjectList = (
    ids: number[],
    totalCount?: number,
    additional?: boolean,
): IUpdateProjectList => {
    return {
        type: UPDATE_PROJECT_LIST,
        ids,
        totalCount,
        additional,
    };
};

export const UPDATE_PROJECT_LIST_OPTIONS = 'UPDATE_PROJECT_LIST_OPTIONS';
export const _updateProjectListOptions = (options: IMeta): IUpdateProjectListOptions => {
    return {
        type: UPDATE_PROJECT_LIST_OPTIONS,
        options,
    };
};

export const UPDATE_TASK_PROJECT_LIST = 'UPDATE_TASK_PROJECT_LIST';
export const _updateTaskProjectList = (
    ids: number[],
    totalCount?: number,
    additional?: boolean,
    pid?: number,
): IUpdateTaskProjectList => {
    return {
        type: UPDATE_TASK_PROJECT_LIST,
        ids,
        totalCount,
        additional,
        pid,
    };
};

export const UPDATE_TASK_PROJECT_LIST_OPTIONS = 'UPDATE_TASK_PROJECT_LIST_OPTIONS';
export const _updateTaskProjectListOptions = (options: IMeta): IUpdateTaskProjectListOptions => {
    return {
        type: UPDATE_TASK_PROJECT_LIST_OPTIONS,
        options,
    };
};

export const UPDATE_BACKLOG_IDS = 'UPDATE_BACKLOG_IDS';
export const _updateBacklogIds = (
    ids: number[],
    totalCount: number,
    pid: number,
    additional: boolean,
): IUpdateBacklogIds => {
    return {
        type: UPDATE_BACKLOG_IDS,
        ids,
        totalCount,
        pid,
        additional,
    };
};

export const UPDATE_SPRINT_IDS = 'UPDATE_SPRINT_IDS';
export const _updateSprintIds = (ids: number[], sid: number): IUpdateSprintIds => {
    return {
        type: UPDATE_SPRINT_IDS,
        ids,
        sid,
    };
};

export const ADD_SPRINT = 'ADD_SPRINT';
export const _addSprint = (sprint: number, pid: number): IAddSprint => {
    return {
        type: ADD_SPRINT,
        sprint,
        pid,
    };
};

export const UPDATE_WIKI_PAGE = 'UPDATE_WIKI_PAGE';
export const _updateWikiPage = (
    data: any,
    pid: number,
    wid: number,
    add?: boolean,
    remove?: boolean,
): IUpdateWikiPage => {
    return {
        type: UPDATE_WIKI_PAGE,
        data,
        pid,
        wid,
        add,
        remove,
    };
};

export const UPDATE_PROJECT_PROCESSES_OPTIONS = 'UPDATE_PROJECT_PROCESSES_OPTIONS';
export const _updateProjectProcessesOptions = (
    id: number,
    options: IMeta,
): IUpdateProjectProcessesOptions => {
    return {
        type: UPDATE_PROJECT_PROCESSES_OPTIONS,
        options,
        id,
    };
};

export const addProjects = (projects: IProject[], withClearState = false) => {
    return async (dispatch: Dispatch<IAddProjects>): Promise<void> => {
        const preparedProjects = projects.map((project) =>
            prepareProjectFromServer(project, dispatch),
        );
        dispatch(_addProjects(preparedProjects, withClearState));
    };
};

export const loadProject = async (id: number) => {
    return async (
        dispatch: Dispatch<IUpdateProjectList | IUpdateProject | IUpdateLoaders | any>,
    ): Promise<void> => {
        dispatch(
            updateLoaders({
                [LOADERS.PROJECT_LIST]: {
                    [id]: true,
                },
            }),
        );
        await apiGetProject(id).then((res) => {
            dispatch(addProjects([res.project]));
        });
        dispatch(
            updateLoaders({
                [LOADERS.PROJECT_LIST]: {
                    [id]: false,
                },
            }),
        );
    };
};

export const updateProject = async (
    data: { [field: string]: any },
    projectId: number,
    previousData: IProject,
    dataForServer?: any,
    callback?: () => void,
) => {
    return async (dispatch: Dispatch<IUpdateProject | IUpdateLoaders>): Promise<void> => {
        const key = Object.keys(data)[0];

        dispatch(_updateProject({ project: { ...data } }, projectId));
        dispatch(
            updateLoaders({
                [LOADERS.PROJECT_LIST]: {
                    [key]: true,
                },
            }),
        );

        return apiUpdateProject(projectId, dataForServer ?? data)
            .then((res) => {
                const project = prepareProjectFromServer(res.project, dispatch);
                dispatch(_updateProject({ project }, projectId));
                callback && callback();
            })
            .catch((error) => {
                dispatch(_updateProject({ project: { ...previousData } }, projectId));
                throw error;
            })
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.PROJECT_LIST]: {
                            [key]: false,
                        },
                    }),
                );
            });
    };
};

export const loadProjectList = (
    offset: number,
    options: IMeta,
    additional = false,
    withClearState = false,
) => {
    return async (
        dispatch: Dispatch<IUpdateProjectList | IAddProjects | IUpdateLoaders | any>,
    ): Promise<void> => {
        dispatch(
            updateLoaders({
                [LOADERS.PROJECT_LIST]: {
                    [LOADERS_TYPE.LOADING]: !additional,
                    [LOADERS_TYPE.ADDITIONAL_LOADING]: additional,
                },
            }),
        );
        apiGetProjectList(offset, options)
            .then((res) => {
                dispatch(
                    _updateProjectList(
                        res.rows.map((project: IProject) => project.id),
                        res.totalCount,
                        additional,
                    ),
                );
                dispatch(addProjects(res.rows, withClearState));
            })
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.PROJECT_LIST]: {
                            [LOADERS_TYPE.LOADING]: false,
                            [LOADERS_TYPE.ADDITIONAL_LOADING]: false,
                        },
                    }),
                );
            });
    };
};

export const updateProjectListOptions = (options: IMeta, withoutLoadingProjects?: boolean) => {
    return async (dispatch: Dispatch<IUpdateProjectListOptions>): Promise<void> => {
        await apiUpdateMeta(USER_META.PROJECTS_OPTIONS, {
            orderby: options.orderby,
        }).then(async () => {
            dispatch(_updateProjectListOptions(options));
            if (!withoutLoadingProjects) {
                // @ts-ignore
                dispatch(await loadProjectList(0, options));
            }
        });
    };
};

export const loadTaskProjectList = (
    offset: number,
    options: IMeta,
    additional = false,
    pid?: number,
) => {
    return async (
        dispatch: Dispatch<IUpdateTaskProjectList | IAddTasks | IUpdateLoaders | any>,
    ): Promise<void> => {
        dispatch(
            updateLoaders({
                [LOADERS.TASK_PROJECT_LIST]: {
                    [LOADERS_TYPE.LOADING]: !additional,
                    [LOADERS_TYPE.ADDITIONAL_LOADING]: additional,
                },
            }),
        );
        apiGetTaskList(offset, options)
            .then((res) => {
                dispatch(
                    _updateTaskProjectList(
                        res.rows.map((task: ITask) => task.id),
                        res.totalCount,
                        additional,
                        pid,
                    ),
                );
                dispatch(addTasks(res.rows));
            })
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.TASK_PROJECT_LIST]: {
                            [LOADERS_TYPE.LOADING]: false,
                            [LOADERS_TYPE.ADDITIONAL_LOADING]: false,
                        },
                    }),
                );
            });
    };
};

export const updateTaskProjectListOptions = async (
    options: IMeta,
    withoutLoadingProjects?: boolean,
) => {
    return async (
        dispatch: Dispatch<IUpdateTaskProjectListOptions | IUpdateMeta>,
    ): Promise<void> => {
        dispatch(_updateTaskProjectListOptions(options));
        if (!withoutLoadingProjects) {
            // @ts-ignore
            dispatch(await loadTaskProjectList(0, options, false, options.filters.pid));
        }
    };
};

export const updateBacklogIds = (projectId: number, offset: number, additional = false) => {
    return async (
        dispatch: Dispatch<IUpdateLoaders | IAddTasks | IUpdateBacklogIds | any>,
    ): Promise<void> => {
        dispatch(
            updateLoaders({
                [LOADERS.SPRINT_BACKLOG_LIST]: {
                    [LOADERS_TYPE.LOADING]: !additional,
                    [LOADERS_TYPE.ADDITIONAL_LOADING]: additional,
                },
            }),
        );
        apiGetTaskList(offset, {
            ...defaultOptions,
            filters: {
                ...defaultOptions.filters,
                [TASKS_FILTERS.STATUS]: 'open',
                [TASKS_FILTERS.PID]: `${projectId}`,
            },
        })
            .then((res) => {
                dispatch(addTasks(res.rows));
                dispatch(
                    _updateBacklogIds(
                        res.rows.map((task: ITask) => task.id),
                        res.totalCount,
                        projectId,
                        additional,
                    ),
                );
            })
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.SPRINT_BACKLOG_LIST]: {
                            [LOADERS_TYPE.LOADING]: false,
                            [LOADERS_TYPE.ADDITIONAL_LOADING]: false,
                        },
                    }),
                );
            });
    };
};

export const updateSprintIds = (sprintId: number) => {
    return async (
        dispatch: Dispatch<IUpdateLoaders | IAddTasks | IUpdateSprintIds | IUpdateEntity | any>,
    ): Promise<void> => {
        dispatch(
            updateLoaders({
                [LOADERS.SPRINT_TASKS_LIST]: {
                    [LOADERS_TYPE.LOADING]: true,
                },
            }),
        );
        apiGetSprint(sprintId)
            .then((res: { sprint: ISprint }) => {
                const sprint = res.sprint;
                dispatch(addTasks(sprint.tasks));
                dispatch(
                    _updateSprintIds(
                        res.sprint.tasks.map((task: ITask) => task.id),
                        sprintId,
                    ),
                );
                dispatch(_updateEntity(ENTITIES.SPRINTS, res.sprint, true));
            })
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.SPRINT_TASKS_LIST]: {
                            [LOADERS_TYPE.LOADING]: false,
                        },
                    }),
                );
            });
    };
};

export const updateSprint = (
    data: { [field: string]: any },
    sprintId: number,
    projectId: number,
    previousData: ISprint,
) => {
    return async (dispatch: Dispatch<IUpdateLoaders | IUpdateEntity>): Promise<void> => {
        const key = Object.keys(data)[0];

        dispatch(_updateEntity(ENTITIES.SPRINTS, { ...previousData, ...data }));
        dispatch(
            updateLoaders({
                [LOADERS.SPRINT]: {
                    [key]: true,
                },
            }),
        );

        await apiUpdateSprint(sprintId, data)
            .then((res) => {
                dispatch(_updateEntity(ENTITIES.SPRINTS, { ...res }));
            })
            .catch(() => {
                dispatch(_updateEntity(ENTITIES.SPRINTS, { ...previousData }));
            })
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.SPRINT]: {
                            [key]: false,
                        },
                    }),
                );
            });
    };
};

export const loadWiki = (id: number) => {
    return async (dispatch: Dispatch<IUpdateProject>): Promise<void> => {
        apiGetWiki(id).then((res) => {
            dispatch(_updateProject({ project: { [PROJECT_FIELDS.WIKI]: res.pages } }, id));
            // dispatch(_updateProject({ project: { wiki: res.pages } }, id));
        });
    };
};

export const loadWikiPage = (id: number) => {
    return async (dispatch: Dispatch<IUpdateWikiPage>): Promise<void> => {
        apiGetWikiPage(id).then((res) => {
            dispatch(
                _updateWikiPage({ page: { content: res.page.content } }, res.page.project_id, id),
            );
        });
    };
};

export const updateWikiPage = (data: { [field: string]: any }, pageId: number) => {
    return async (dispatch: Dispatch<IUpdateLoaders | IUpdateWikiPage>): Promise<void> => {
        dispatch(
            updateLoaders({
                [LOADERS.WIKI]: {
                    [LOADERS_TYPE.LOADING]: true,
                },
            }),
        );
        apiUpdateWikiPage(pageId, data)
            .then((res) => {
                dispatch(_updateWikiPage({ page: data }, res.page.project_id, pageId));
            })
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.WIKI]: {
                            [LOADERS_TYPE.LOADING]: false,
                        },
                    }),
                );
            });
    };
};

export const addWikiPage = (
    projectId: number,
    parentId?: number,
    data?: IWikiPage,
    navigate?: NavigateFunction,
) => {
    return async (dispatch: Dispatch<IUpdateWikiPage>): Promise<void> => {
        apiCreateWikiPage({
            project_id: projectId,
            parent_id: parentId || 0,
            order: data?.order,
            content: data?.content,
            title: data?.title,
            slug: data?.slug,
        }).then((res) => {
            dispatch(
                _updateWikiPage({ ...res.page, order: data?.order }, projectId, res.page.id, true),
            );
            if (navigate) {
                navigate(`${urlsMap.projectList}/${projectId}${urlsMap.wiki}/${res.page.id}`);
            }
        });
    };
};

export const removeWikiPage = (projectId: number, pageId: number) => {
    return async (dispatch: Dispatch<IUpdateWikiPage>): Promise<void> => {
        apiDeleteWikiPage(pageId).then(() => {
            dispatch(_updateWikiPage({}, projectId, pageId, undefined, true));
        });
    };
};

export const updateProjectProcessesOptions = async (id: number, options: IMeta) => {
    return async (
        dispatch: Dispatch<IUpdateProjectProcessesOptions | IUpdateMeta>,
    ): Promise<void> => {
        // @ts-ignore
        dispatch(updateMeta(`${META.PROJECTS}${id}Processes`, options));
        dispatch(_updateProjectProcessesOptions(id, options));
    };
};

export const getProjectTags = (id: number) => {
    return async (dispatch: Dispatch<IUpdateProject>): Promise<void> => {
        apiGetProjectTags(id).then((res) => {
            dispatch(_updateProject({ project: { [PROJECT_FIELDS.TAGS]: res } }, id));
        });
    };
};
