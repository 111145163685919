import { get, post, put, _delete } from '~/utils/api';
import store from '~/store';
import { META } from '~/const';

export const apiGetTaskList = async (offset: number, options: IMeta) => {
    const groupId = store.getState().reducerApp.meta[META.CURRENT_GROUP];

    return await get(
        `tasks?limit=20&offset=${offset}&filters=${JSON.stringify(
            options.filters,
        )}&orderby=${JSON.stringify(options.orderby)}${groupId ? '&group_id=' + groupId : ''}`,
    );
};

export const apiGetTasksTiming = async (date: string) => {
    const groupId = store.getState().reducerApp.meta[META.CURRENT_GROUP];

    return await get(`online/users?date=${date}${groupId ? '&group_id=' + groupId : ''}`);
};

export const saveFilter = async (filters: IFilter[]) => await post('me/filters', filters);

export const createTask = async (task: { project_id: string; title: string }) =>
    await post('tasks', task);

export const apiUpdateTask = async (id: number, data: any) => await put(`tasks/${id}`, data);

export const apiUploadFilesToTask = async (id: number, data: FormData) =>
    await post(`tasks/${id}/files`, data, false, true);

export const apiDeleteFileFromTask = async (taskId: number, fileId: number) =>
    await _delete(`tasks/${taskId}/files/${fileId}`);

export const apiGetTask = async (id: number) => await get(`tasks/${id}`);

export const apiCreateComment = async (id: number, data: any) =>
    await post(`tasks/${id}/comments`, data);

export const apiUpdateComment = async (taskId: number, commentId: number, data: any) =>
    await put(`tasks/${taskId}/comments/${commentId}`, data);

export const apiUploadFilesToComment = async (taskId: number, commentId: number, data: FormData) =>
    await post(`tasks/${taskId}/comments/${commentId}/files`, data, false, true);

export const apiDeleteFilesFromComment = async (
    taskId: number,
    commentId: number,
    fileId: number,
) => await _delete(`tasks/${taskId}/comments/${commentId}/files/${fileId}`);

export const apiGetCalendar = async () => await get('tasks/calendar');

export const apiTaskStart = async (id: number) => await get(`tasks/${id}/start`);

export const apiTaskStop = async (id: number) => await get(`tasks/${id}/stop`);

export const apiTaskAutocomplete = async (projectId: string, query: string) =>
    await get(`tasks/autocomplete?project_id=${projectId}&query=${query}`);

export const apiTaskMove = async (id: number, data: any) => await put(`tasks/${id}/move`, data);

export const apiGetTaskLogs = async (id: number) => await get(`tasks/${id}/logs`);

export const apiGetTaskTimings = async (id: number) => await get(`tasks/${id}/timings`);

export const apiUpdateTaskTiming = async (taskId: number, timingId: number, data: any) =>
    await put(`tasks/${taskId}/timings/${timingId}`, data);

export const apiCancelTiming = async (taskId: number, timingId: number) =>
    await put(`tasks/${taskId}/timings/${timingId}/cancel`);

export const apiApproveTiming = async (taskId: number, timingId: number) =>
    await put(`tasks/${taskId}/timings/${timingId}/approve`);

export const apiTaskRecurringDelete = async (taskId: number) =>
    await _delete(`tasks/${taskId}/recuring`);

export const apiTaskRecurringUpdate = async (taskId: number, data: any) =>
    await put(`tasks/${taskId}/recuring`, data);

export const apiTaskRecurringCreate = async (taskId: number, data: any) =>
    await post(`tasks/${taskId}/recuring`, data);
