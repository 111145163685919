/**
 * @typedef {import('mdast').Root} Root
 *
 * @typedef {import('mdast').PhrasingContent} PhrasingContent
 *
 * @typedef {import('mdast-util-find-and-replace').ReplaceFunction} ReplaceFunction
 *
 * @typedef Options
 *  Configuration
 * @property {(username: string) => string} usernameLink
 */

import { findAndReplace } from 'mdast-util-find-and-replace';

const userGroup = '[\\da-z][-\\da-z_]{0,38}';
const mentionRegex = new RegExp('(?:^|\\s)@(' + userGroup + ')', 'gi');

/**
 *
 * @type {import("unified").Plugin<[Options?]|void[], Root>}
 */
export default function remarkMentions(opts: { users: IUserEntities | undefined }) {
    // @ts-ignore
    return (tree, _file) => {
        // @ts-ignore
        findAndReplace(tree, [[mentionRegex, replaceMention]]);
    };

    /**
     * @type {ReplaceFunction}
     * @param {string} value
     * @param {string} mention
     */
    function replaceMention(value: string, mention: any) {
        /** @type {PhrasingContent[]} */
        const whitespace = [];

        // Separate leading white space
        if (value.indexOf('@') > 0) {
            whitespace.push({
                type: 'text',
                value: value.substring(0, value.indexOf('@')),
            });
        }

        const userId = mention.substring(2);
        if (!(opts?.users && opts.users[userId])) {
            return false;
        }
        const userName = `@${opts.users[userId].name}`;

        return [
            ...whitespace,
            {
                type: 'link',
                url: `../users/${userId}`,
                children: [{ type: 'strong', children: [{ type: 'text', value: userName }] }],
            },
        ];
    }
}
