import { IUpdateEntity } from '~/store/actions/types/actionEntitiesTypes';
import { entitiesState } from '~/store/reducers/reducerEntities';

export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const _updateEntity = (
    entity: keyof entitiesState,
    data: any,
    add?: boolean,
    remove?: boolean,
): IUpdateEntity => {
    return {
        type: UPDATE_ENTITY,
        entity,
        data,
        add,
        remove,
    };
};
