import * as React from 'react';
import Circle from '~/components/Loader/Circle/Circle';
import styles from './Image.module.scss';

interface IImage {
    src: string;
    className?: string;
    withLoader?: boolean;
    alt?: string;
}

const Image = ({ src, className, withLoader = false, alt = '' }: IImage) => {
    const [isLoaded, setIsLoaded] = React.useState(false);

    return (
        <div
            className={
                withLoader && !isLoaded
                    ? `${styles.loading} ${className}`
                    : `${styles.loaded} ${className}`
            }
        >
            {withLoader && !isLoaded && <Circle />}
            <img
                className={className}
                src={src}
                onLoad={() => {
                    setIsLoaded(true);
                }}
            />
        </div>
    );
};

export default Image;
