import * as React from 'react';
import styles from './Popup.module.scss';
import classNames from 'classnames';
import { ReactComponent as Close } from '~/assets/icons/cross.svg';
import { useDispatch } from 'react-redux';
import { changeVisiblePopup } from '~/store/actions/actionApp';
import { useOnClickOutside } from 'usehooks-ts';
import { useRef } from 'react';
import { useKeyPress } from '~/hooks/useKeyPress';
import { KEYBOARD } from '~/const';

export const POPUP_WRAPPER = {
    WHITE: 'WHITE',
    FILTER: 'FILTER',
};

interface IFC {
    children: React.ReactChild | React.ReactChild[];
    wrapper?: string;
    withoutClose?: boolean;
}

const Popup = ({ children, wrapper, withoutClose }: IFC) => {
    const ref = useRef(null);
    const dispatch = useDispatch();

    const closeHandler = () => {
        dispatch(changeVisiblePopup(null));
    };

    useOnClickOutside(ref, () => {
        if (wrapper !== POPUP_WRAPPER.WHITE && !withoutClose) closeHandler();
    });

    if (!withoutClose) {
        useKeyPress(KEYBOARD.ESC, closeHandler);
    }

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.whiteFill]: wrapper === POPUP_WRAPPER.WHITE,
                [styles.filter]: wrapper === POPUP_WRAPPER.FILTER,
            })}
        >
            <div className={styles.children} ref={ref}>
                {children}
                {!withoutClose && (
                    <div className={styles.close} onClick={closeHandler}>
                        <Close />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Popup;
