import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import { State } from '~/store/reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';

interface IPrivateRoutes {
    allowedRoles?: string[];
}

interface IStateSelector {
    account: IAccount;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        account: state.reducerAccount.account,
    };
};

const PrivateRoutes = ({ allowedRoles }: IPrivateRoutes) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const location = useLocation();

    return state.account?.id ? (
        !state.account?.is_email_valid ? (
            <Navigate to={urlsMap.emailConfirm} />
        ) : (
            <Outlet />
        )
    ) : (
        <Navigate to={urlsMap.index} state={{ prevState: location.pathname }} />
    );
};

export default PrivateRoutes;
