import * as React from 'react';
import styles from './Layout.module.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '~/containers/Layout/Header/Header';
import Aside from '~/containers/Layout/Aside/Aside';
import { socket } from '~/ws/socket';
import { State } from '~/store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import {
    _updateGroup,
    _updateProjectInvite,
    updateWorkedTime,
} from '~/store/actions/actionAccount';
import TracksTime from '~/containers/Layout/TracksTime/TracksTime';
import { POPUPS_NAME } from '~/components/Popup/Manager';
import { POPUP_WRAPPER } from '~/components/Popup/Popup';
import { INVITATIONS, META, TOAST } from '~/const';
import { prepareApp } from '~/preloaders/appPreloader';
import { prepareEntities } from '~/preloaders/entitiesPreloader';
import { addToast, changeVisiblePopup, updateMeta } from '~/store/actions/actionApp';
import { apiGetCalendar } from '~/api/task';
import store from '~/store';
import { addCalendar } from '~/store/actions/actionTasks';
import { Helmet } from 'react-helmet';
import favicon from '~/assets/icons/favicon.svg';
import faviconPlay from '~/assets/icons/favicon-play.svg';
import { deleteMascot } from '~/utils/utils';
import moment from 'moment';
import { getTracksUserDay } from '~/utils/utils';
import LineLoader from '~/components/Loader/Line/Line';
import Notifications from '~/containers/Layout/Notifications/Notifications';
import { apiAcceptInvite as apiAcceptInviteGroup, apiGetGroup } from '~/api/user';
import { apiAcceptInvite as apiAcceptInviteProject } from '~/api/project';
import { urlsMap } from '~/utils/urls';
import { useAppSelector } from '~/store';
import { selectNotificationVisible } from '~/store/slices/app/slice';

interface IStateSelector {
    meta: IAppMeta;
    account: IAccount;
    tasks: ITaskEntities;
    projects: IProjectEntities;
    accountId?: number;
    workedTime?: number;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        meta: state.reducerApp.meta,
        account: state.reducerAccount.account,
        tasks: state.reducerTasks.tasks,
        projects: state.reducerProjects.projects,
        accountId: state.reducerAccount.account.id,
        workedTime: state.reducerAccount.account.workedTime,
    };
};

const Layout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const notificationVisible = useAppSelector((state) => selectNotificationVisible(state));
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const currentTask = state.account.currentTask;
    const accountId = state.account.id;
    const urlParams = new URL(window.location.href);

    React.useEffect(() => {
        prepareApp();

        if (state.account.calendarOn) {
            apiGetCalendar().then((res) => {
                if (res.msg) {
                    return;
                }

                // @ts-ignore
                store.dispatch(addCalendar(res));
            });
        }
    }, []);

    React.useEffect(() => {
        if (state.account?.is_password_empty === true && !state.meta[META.PASSWORD_SET]) {
            dispatch(
                changeVisiblePopup({
                    name: POPUPS_NAME.NEW_PASSWORD,
                    wrapper: POPUP_WRAPPER.WHITE,
                    withoutClose: true,
                }),
            );
            deleteMascot();
        } else if (
            state.account.groups?.[0]?.is_invite &&
            state.meta[META.CURRENT_GROUP] === null
        ) {
            const groupId = Number(urlParams.pathname.split('/')?.[3]);

            if (groupId) {
                apiAcceptInviteGroup(groupId).then(() => {
                    // @ts-ignore
                    dispatch(updateMeta(META.CURRENT_GROUP, groupId));
                    apiGetGroup(groupId).then((res) => {
                        store.dispatch(
                            _updateGroup({ ...res.group, is_invite: INVITATIONS.NOT_INVITED }),
                        );
                    });
                });
            } else {
                dispatch(
                    changeVisiblePopup({
                        name: POPUPS_NAME.ACCEPT_GROUP,
                        data: { group: state.account.groups[0] },
                        wrapper: POPUP_WRAPPER.FILTER,
                    }),
                );
            }

            deleteMascot();
        } else if (!!state.account.projects_invites.length) {
            const invite = state.account.projects_invites[0];
            const alreadyAccept = urlParams.pathname.split('/')?.[3];

            if (alreadyAccept) {
                apiAcceptInviteProject(invite.project_id).then(async () => {
                    dispatch(_updateProjectInvite(invite, undefined, true));
                    navigate(`${urlsMap.projectList}/${invite.project_id}`);
                    dispatch(
                        addToast({
                            type: TOAST.SUCCESS,
                            title: `Приглашение принято`,
                            timer: 5000,
                        }),
                    );
                });
            } else {
                dispatch(
                    changeVisiblePopup({
                        name: POPUPS_NAME.ACCEPT_PROJECT,
                        data: { invite },
                        wrapper: POPUP_WRAPPER.FILTER,
                        withoutClose: true,
                    }),
                );
            }

            deleteMascot();
        } else if (state.meta[META.CURRENT_GROUP] !== undefined) {
            prepareEntities();

            if (!state.account.groups?.length) {
                dispatch(
                    changeVisiblePopup({
                        name: POPUPS_NAME.CREATE_GROUP,
                        data: undefined,
                        wrapper: POPUP_WRAPPER.WHITE,
                    }),
                );
            }
        }
    }, [
        state.meta[META.CURRENT_GROUP],
        state.meta[META.PASSWORD_SET],
        state.account.projects_invites,
    ]);

    React.useEffect(() => {
        if (currentTask) {
            const totalTime = setInterval(() => {
                dispatch(updateWorkedTime());
            }, 60000);

            return () => clearInterval(totalTime);
        }
    }, [currentTask]);

    React.useEffect(() => {
        accountId && socket(accountId);
    }, [accountId]);

    const timeZone = moment().utcOffset() / 60 - 3;

    const dayData = getTracksUserDay(
        moment().subtract(timeZone, 'hours'),
        state.tasks,
        state.accountId,
        state.accountId,
    );

    return (
        <div className={styles.layout}>
            <Helmet>
                <link
                    id="favicon"
                    rel="icon"
                    href={currentTask ? faviconPlay : favicon}
                    type="image/x-icon"
                />
            </Helmet>
            {state.account.calendarOn && (
                <TracksTime
                    className={styles.layoutTrackTimer}
                    dayData={dayData}
                    day={moment().subtract(timeZone, 'hours')}
                />
            )}
            <Header />
            <div className={styles.flex}>
                <Aside />
                <React.Suspense fallback={<LineLoader />}>
                    <Outlet />
                </React.Suspense>
                {notificationVisible && <Notifications />}
            </div>
        </div>
    );
};

export default Layout;
