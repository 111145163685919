import * as React from 'react';
import classNames from 'classnames';
import styles from '~/components/Form/Select/Select.module.scss';
import { customStyles } from '~/components/Form/Select/style';
import AsyncSelect from 'react-select/async';
import { SingleValue } from 'react-select';

interface IFC {
    label?: string;
    className?: string;
    classNameWrapper?: string;
    changeHandler?: (
        newValue: SingleValue<{ value: string; label: string; disabled?: boolean | undefined }>,
    ) => void;
    disabled?: boolean;
    loadOptions?: (value: string) => void;
    placeholder?: string;
}

const AsyncSelectField = ({
    label,
    className,
    classNameWrapper,
    changeHandler,
    disabled,
    loadOptions,
    placeholder,
}: IFC) => {
    return (
        <div className={classNames(styles.selectContainer, classNameWrapper)}>
            <div className={styles.selectTop}>
                {label && <label className={styles.selectLabel}>{label}</label>}
            </div>
            <AsyncSelect
                // @ts-ignore
                styles={customStyles()}
                className={className}
                onChange={(newValue) => changeHandler && changeHandler(newValue)}
                // onInputChange={(newValue) => setInputValue(newValue)}
                // onKeyDown={handleKeyDown}
                placeholder={placeholder}
                // value={value}
                isDisabled={disabled}
                cacheOptions
                loadOptions={(inputValue) => loadOptions && loadOptions(inputValue)}
                components={{
                    LoadingMessage: (props) => (
                        <div className={styles.noOptionsMessage}>
                            {props.selectProps.inputValue.length < 3
                                ? 'Поиск начинается от 3 символов'
                                : 'Загрузка..'}
                        </div>
                    ),
                    NoOptionsMessage: (props) => (
                        <div className={styles.noOptionsMessage}>
                            {props.selectProps.inputValue.length < 3
                                ? 'Поиск начинается от 3 символов'
                                : 'Ничего не найдено'}
                        </div>
                    ),
                    DropdownIndicator: () => null,
                }}
            />
        </div>
    );
};

export default AsyncSelectField;
