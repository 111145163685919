import { apiGetUserList, apiUpdateMeta, apiGetUser, apiUpdateUser } from '~/api/user';
import { LOADERS, USER_FIELDS, USER_META } from '~/const';
import { Dispatch } from 'react';
import { IUpdateLoaders } from '~/store/actions/types/actionAppTypes';
import { updateLoaders } from '~/store/actions/actionApp';
import {
    IUpdateUserListOptions,
    IUpdateUserList,
    IAddUsers,
    IUpdateUser,
    IDeleteUser,
    IAddUsersOnline,
} from '~/store/actions/types/actionUsersTypes';
import { LOADERS_TYPE } from '~/const';
import { prepareUserFromServer } from '~/utils/users';
import { IUpdateConfirmEmail, IUpdateName } from '~/store/actions/types/actionAccountTypes';
import { _updateAccountName, _updateConfirmEmail } from '~/store/actions/actionAccount';

export const ADD_USERS = 'ADD_USERS';
export const _addUsers = (users: IUser[], withClearState?: boolean): IAddUsers => {
    return {
        type: ADD_USERS,
        users,
        withClearState,
    };
};

export const UPDATE_USER = 'UPDATE_USER';
export const _updateUser = (data: any, id: number): IUpdateUser => {
    return {
        type: UPDATE_USER,
        data,
        id,
    };
};

export const DELETE_USER = 'DELETE_USER';
export const _deleteUser = (id: number): IDeleteUser => {
    return {
        type: DELETE_USER,
        id,
    };
};

export const UPDATE_USER_LIST = 'UPDATE_USER_LIST';
export const _updateUserList = (
    ids: number[],
    totalCount?: number,
    additional?: boolean,
): IUpdateUserList => {
    return {
        type: UPDATE_USER_LIST,
        ids,
        totalCount,
        additional,
    };
};

export const UPDATE_USER_LIST_OPTIONS = 'UPDATE_USER_LIST_OPTIONS';
export const _updateUserListOptions = (options: IMeta): IUpdateUserListOptions => {
    return {
        type: UPDATE_USER_LIST_OPTIONS,
        options,
    };
};

export const ADD_USERS_ONLINE = 'ADD_USERS_ONLINE';
export const _addUsersOnline = (ids: number[]): IAddUsersOnline => {
    return {
        type: ADD_USERS_ONLINE,
        ids,
    };
};

export const addUsers = (users: IUser[]) => {
    return async (dispatch: Dispatch<IAddUsers>): Promise<void> => {
        const preparedUsers = users.map((user) => prepareUserFromServer(user));
        dispatch(_addUsers(preparedUsers));
    };
};

export const updateUser = async (
    data: { [field: string]: any },
    userId: number,
    previousData?: IUser,
) => {
    return async (
        dispatch: Dispatch<IUpdateUser | IUpdateLoaders | IUpdateConfirmEmail | IUpdateName>,
    ): Promise<void> => {
        let key = Object.keys(data)[0];

        if (data.properties) {
            key = Object.keys(data.properties)[0];
        } else {
            dispatch(_updateUser({ user: { ...data } }, userId));
        }
        dispatch(
            updateLoaders({
                [LOADERS.USER_LIST]: {
                    [key]: true,
                },
            }),
        );

        return apiUpdateUser(userId, data)
            .then((res) => {
                dispatch(_updateUser({ ...res }, userId));

                if (key === USER_FIELDS.EMAIL) {
                    dispatch(_updateConfirmEmail(false));
                }

                if (key === USER_FIELDS.NAME) {
                    dispatch(_updateAccountName(data[USER_FIELDS.NAME]));
                }
            })
            .catch((error) => {
                dispatch(_updateUser({ user: { ...previousData } }, userId));
                throw error;
            })
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.USER_LIST]: {
                            [key]: false,
                        },
                    }),
                );
            });
    };
};

export const loadUser = (id: number, additional?: boolean) => {
    return async (
        dispatch: Dispatch<IUpdateUserList | IUpdateUser | IAddUsers | IUpdateLoaders>,
    ): Promise<void> => {
        dispatch(
            updateLoaders({
                [LOADERS.USER_LIST]: {
                    [id]: true,
                },
            }),
        );
        await apiGetUser(id)
            .then((res) => {
                res.user.fullData = true;
                if (additional) {
                    dispatch(_addUsers([res.user]));
                } else {
                    dispatch(_updateUser({ ...res }, Number(id)));
                }
            })
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.USER_LIST]: {
                            [id]: false,
                        },
                    }),
                );
            });
    };
};

export const loadUserList = (
    offset: number,
    options: IMeta,
    additional = false,
    withClearState = false,
) => {
    return async (
        dispatch: Dispatch<IUpdateUserList | IAddUsers | IUpdateLoaders>,
    ): Promise<void> => {
        dispatch(
            updateLoaders({
                [LOADERS.USER_LIST]: {
                    [LOADERS_TYPE.LOADING]: !additional,
                    [LOADERS_TYPE.ADDITIONAL_LOADING]: additional,
                },
            }),
        );
        apiGetUserList(offset, options)
            .then((res) => {
                dispatch(
                    _updateUserList(
                        res.rows.map((user: IUser) => user.id),
                        res.totalCount,
                        additional,
                    ),
                );
                dispatch(_addUsers(res.rows, withClearState));
            })
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.USER_LIST]: {
                            [LOADERS_TYPE.LOADING]: false,
                            [LOADERS_TYPE.ADDITIONAL_LOADING]: false,
                        },
                    }),
                );
            });
    };
};

export const updateUserListOptions = (options: IMeta, withoutLoadingTasks?: boolean) => {
    return async (dispatch: Dispatch<IUpdateUserListOptions>): Promise<void> => {
        await apiUpdateMeta(USER_META.USERS_OPTIONS, {
            ...options,
        }).then(async () => {
            dispatch(_updateUserListOptions(options));
            if (!withoutLoadingTasks) {
                // @ts-ignore
                dispatch(await loadUserList(0, options));
            }
        });
    };
};

export const addUsersOnline = (users: IUser[]) => {
    return (dispatch: Dispatch<IAddUsers | IAddUsersOnline | any>) => {
        const ids = users.map((user) => user.id);

        dispatch(addUsers(users));
        dispatch(_addUsersOnline(ids));
    };
};
