import { Actions } from '~/store/actions';
import {
    SET_ACCOUNT_LOGIN,
    ACCOUNT_LOGOUT,
    UPDATE_FILTERS,
    UPDATE_WORKED_TIME,
    UPDATE_CONFIRM_EMAIL,
    UPDATE_CURRENT_TASK,
    UPDATE_GROUP,
    UPDATE_PROJECT_INVITE,
    UPDATE_NAME,
} from '~/store/actions/actionAccount';
import { prepareMe } from '~/utils/account';

export interface accountState {
    account: IAccount;
    checkAuth: boolean;
}

export const initialState: accountState = {
    account: {
        projects_invites: [],
        is_email_valid: true,
        filters: [],
    },
    checkAuth: false,
};

const reducerAccount = (state: accountState = initialState, action: Actions) => {
    switch (action.type) {
        case UPDATE_FILTERS:
            return {
                ...state,
                account: {
                    ...state.account,
                    filters: action.filters,
                },
            };

        case UPDATE_WORKED_TIME:
            return {
                ...state,
                account: {
                    ...state.account,
                    workedTime: (state.account.workedTime || 0) + 60,
                },
            };

        case UPDATE_CONFIRM_EMAIL:
            return {
                ...state,
                account: {
                    ...state.account,
                    is_email_valid: action.isValid,
                },
            };

        case UPDATE_CURRENT_TASK:
            return {
                ...state,
                account: {
                    ...state.account,
                    currentTask: action.currentTask,
                },
            };

        case SET_ACCOUNT_LOGIN:
            if (action.account === null) {
                return {
                    ...initialState,
                    checkAuth: true,
                };
            }

            return {
                ...state,
                account: prepareMe(action.account),
                checkAuth: true,
            };

        case ACCOUNT_LOGOUT:
            return {
                ...initialState,
                checkAuth: !action.withoutCheck,
            };

        case UPDATE_GROUP:
            const groups = state.account.groups || [];
            return {
                ...state,
                account: {
                    ...state.account,
                    groups: action.add
                        ? [...groups, action.group]
                        : action.remove
                        ? groups.filter((group) => group.id !== action.group.id)
                        : groups?.map((group) =>
                              group.id === action.group.id
                                  ? {
                                        ...group,
                                        ...action.group,
                                    }
                                  : group,
                          ),
                },
            };

        case UPDATE_PROJECT_INVITE:
            const projectInvites = state.account.projects_invites || [];
            return {
                ...state,
                account: {
                    ...state.account,
                    projects_invites: action.add
                        ? [...projectInvites, action.invite]
                        : action.remove
                        ? projectInvites.filter((invite) => invite.id !== action.invite.id)
                        : projectInvites?.map((invite) =>
                              invite.id === action.invite.id
                                  ? {
                                        ...invite,
                                        ...action.invite,
                                    }
                                  : invite,
                          ),
                },
            };

        case UPDATE_NAME:
            return {
                ...state,
                account: {
                    ...state.account,
                    name: action.name,
                },
            };

        default:
            return { ...state };
    }
};

export default reducerAccount;
