import * as React from 'react';
import styles from './Notifications.module.scss';
import { NOTIFICATION } from '~/const';
import { ReactComponent as Notification } from '~/assets/icons/notification.svg';
import { ReactComponent as System } from '~/assets/icons/info-system.svg';
import classNames from 'classnames';
import Scroll from '~/components/Scroll/Scroll';
import { useDebounce, useOnClickOutside } from 'usehooks-ts';
import PrepareText from '~/components/Text/PrepareText';
import { useAppSelector, useAppDispatch } from '~/store';
import {
    selectNotifications,
    selectNotificationsCount,
    selectNotificationsAll,
} from '~/store/slices/notification/slice';
import { selectNotificationVisible, changeVisibleNotification } from '~/store/slices/app/slice';
import {
    readNotifications,
    readAllNotifications,
    getNotifications,
} from '~/store/slices/notification/reducers';

const Notifications = React.memo(() => {
    const dispatch = useAppDispatch();
    const refWrapper = React.useRef(null);
    const refList = React.useRef<HTMLDivElement>(null);
    const { totalCount, viewedCount } = useAppSelector((state) => selectNotifications(state));
    const notifications = useAppSelector((state) => selectNotificationsAll(state));
    const notificationsCount = useAppSelector((state) => selectNotificationsCount(state));
    const notificationVisible = useAppSelector((state) => selectNotificationVisible(state));

    const renderIcon = (type: string) => {
        switch (type) {
            case NOTIFICATION.INFO:
                return <Notification />;
            case NOTIFICATION.WARDEN:
            case NOTIFICATION.SYSTEM:
                return <System />;
        }
    };

    const renderNotification = (notification: INotification) => {
        return (
            <div
                className={classNames(styles.row, [styles[notification.tag]])}
                key={notification.id}
            >
                <div className={styles.icon}>{renderIcon(notification.tag)}</div>
                <div className={styles.content}>
                    <div className={styles.title}>{notification.title}</div>
                    {!!notification.text && (
                        <PrepareText className={styles.text} text={notification.text} />
                    )}
                </div>
                {!notification.is_read && <div className={styles.viewed}></div>}
            </div>
        );
    };

    useOnClickOutside(refWrapper, (e: any) => {
        if (e.target.id !== 'notificationSvg') {
            dispatch(changeVisibleNotification(false));
        }
    });

    const [visibleArea, setVisibleArea] = React.useState<number | null>(null);
    const debouncedValue = useDebounce<number | null>(visibleArea, 500);
    const [prevIndex, setPrevIndex] = React.useState<number | null>(null);

    const scrollHandler = (value: number) => {
        setVisibleArea(value);
    };

    React.useEffect(() => {
        if (visibleArea && visibleArea > 0) {
            if (refList.current) {
                let totalHeight = 0;

                const key = Array.from(refList.current.childNodes).findIndex((el: any) => {
                    totalHeight += el.clientHeight;
                    return totalHeight - 20 > visibleArea;
                });

                if (key !== -1) {
                    const readIds: number[] = [];

                    notifications.slice(prevIndex ?? 0, key || 1).forEach((notification) => {
                        if (!notification.is_read) readIds.push(notification.id);
                    });

                    if (readIds.length) {
                        dispatch(
                            readNotifications({
                                ids: readIds,
                                viewedCount: viewedCount + readIds.length,
                            }),
                        );
                    }

                    setPrevIndex(key);
                } else {
                    readAll();
                }
            }
        } else if (visibleArea === 0) {
            readAll();
        }
    }, [debouncedValue]);

    const infiniteScroll = () => {
        dispatch(getNotifications());
    };

    const readAll = () => dispatch(readAllNotifications());

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.open]: notificationVisible,
            })}
        >
            <div className={styles.container} ref={refWrapper}>
                <div className={classNames('contentHeader', styles.contentHeader)}>
                    {totalCount > 0 ? (
                        <>
                            <h2>Уведомления</h2>
                            <h5>{notificationsCount > 0 && notificationsCount}</h5>
                            <div>
                                {notificationsCount > 0 && (
                                    <span onClick={readAll}>прочитать все</span>
                                )}
                            </div>
                        </>
                    ) : (
                        <h2>У вас нет новых уведомлений</h2>
                    )}
                </div>
                <div className={classNames('withoutScrollBar', styles.list)} ref={refList}>
                    {notifications.map(renderNotification)}
                </div>
                <Scroll
                    element={refList}
                    className={styles.scroll}
                    currentCount={notifications.length}
                    totalCount={totalCount}
                    infiniteScroll={infiniteScroll}
                    onScroll={scrollHandler}
                    limit={100}
                />
            </div>
        </div>
    );
});

export default Notifications;
