import * as React from 'react';
import styles from './TracksTime.module.scss';
import moment, { Moment } from 'moment';
import Track from '~/containers/Layout/TracksTime/Track';
import { timeDiff } from '~/containers/Tasks/Calendar/Calendar';
import { BACKGROUND, SERVER_DATE_FORMAT_HHMMSS, DATE_FORMAT_HHMM } from '~/const';
import { generateRangeArray } from '~/utils/utils';
import classNames from 'classnames';

interface ITracksTime {
    className: string;
    day: Moment;
    dayData: {
        timeText: string;
        time: number;
        tasks: ITask[];
    };
}

const TracksTime = ({ className, dayData, day }: ITracksTime) => {
    const startDate = day.hours(0).minutes(0).seconds(0);
    const rangeHoursArray: Moment[] = generateRangeArray(24, startDate, 'hours');

    const RenderTrack = (timing: ITiming, hour: Moment, task: ITask) => {
        let time = 0;
        let bg = BACKGROUND.TRACK_DEFAULT;
        let startTime = timing.stime;

        if (timing.ftime && timing.stime) {
            if (
                moment(startTime).isSame(day, 'dates') &&
                moment(timing.ftime).isSame(day, 'dates')
            ) {
                time = timeDiff(startTime, timing.ftime);
            } else if (
                moment(startTime).isSame(day, 'dates') &&
                moment(timing.ftime).isAfter(day, 'dates')
            ) {
                time = timeDiff(
                    moment(startTime),
                    moment(day, DATE_FORMAT_HHMM).hours(24).minutes(0).seconds(0),
                );
            } else if (
                moment(timing.stime).isBefore(day, 'dates') &&
                moment(timing.ftime).isSame(day, 'dates')
            ) {
                startTime = moment(day, DATE_FORMAT_HHMM)
                    .hours(0)
                    .minutes(0)
                    .seconds(0)
                    .format(SERVER_DATE_FORMAT_HHMMSS);
                if (hour.isSame(startTime, 'hours')) {
                    time = timeDiff(startTime, timing.ftime);
                }
            } else {
                return null;
            }
        } else {
            time = timeDiff(startTime, moment());
            bg = BACKGROUND.TRACK_WORKING;
        }

        if (!hour.isSame(startTime, 'hours')) {
            return null;
        }

        return (
            <Track
                key={timing.id}
                width={Math.round((time / 1000 / 60 / 60) * 100)}
                left={(moment(startTime).minutes() / 60) * 100}
                bg={bg}
                hour={hour.hours()}
                taskId={task.id}
                taskTitle={task?.title || `Задача #${task.id}`}
                taskTime={time / 1000 / 60}
            />
        );
    };

    return (
        <div className={classNames(styles.trackTime, className)}>
            {rangeHoursArray.map((hour, key) => (
                <p key={key} className={styles.trackHours}>
                    <span className={styles.half} />
                    {dayData.tasks.map((task) =>
                        task.timings?.map((timing) => RenderTrack(timing, hour, task)),
                    )}
                    <span className={styles.hours}>{hour.hours()}</span>
                    <span className={styles.borderRight} />
                </p>
            ))}
            <div className={styles.workedOut}>{dayData.timeText}</div>
        </div>
    );
};

export default TracksTime;
