import * as React from 'react';
import styles from './NewPassword.module.scss';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, INPUT_TYPE, LOADERS, LOADERS_TYPE, META, USER_FIELDS } from '~/const';
import Input from '~/components/Form/Input/Input';
import { apiUpdatePassword } from '~/api/user';
import { useDispatch, useSelector } from 'react-redux';
import { changeVisiblePopup, updateLoaders, updateMeta } from '~/store/actions/actionApp';
import { State } from '~/store/reducers';
import equal from 'fast-deep-equal/react';
import { deleteMascot } from '~/utils/utils';
import { updateUser } from '~/store/actions/actionUsers';

interface IStateSelector {
    loaders: ILoaders;
    account: IAccount;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        loaders: state.reducerApp.loaders,
        account: state.reducerAccount.account,
    };
};

const NewPassword = () => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const needChangeName = state.account.name === state.account.email;

    const [name, setName] = React.useState('');
    const [password, setPassword] = React.useState('');

    React.useEffect(() => {
        deleteMascot();
    }, []);

    const handleSave = async () => {
        dispatch(
            updateLoaders({
                [LOADERS.SET_PASSWORD]: {
                    [LOADERS_TYPE.LOADING]: true,
                },
            }),
        );
        if (name.length) {
            // @ts-ignore
            dispatch(await updateUser({ [USER_FIELDS.NAME]: name }, state.account.id));
        }
        apiUpdatePassword(password)
            .then(() => {
                dispatch(changeVisiblePopup(null));
                // @ts-ignore
                dispatch(updateMeta(META.PASSWORD_SET, true));
            })
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.SET_PASSWORD]: {
                            [LOADERS_TYPE.LOADING]: false,
                        },
                    }),
                );
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                Установите пароль {needChangeName && 'и задайте имя'}
            </div>
            <div className={styles.form}>
                {needChangeName && (
                    <Input
                        withoutFormik={true}
                        defaultValue={name}
                        changeHandler={(newValue: string) => setName(newValue)}
                        label="Имя"
                    />
                )}
                <Input
                    withoutFormik={true}
                    defaultValue={password}
                    changeHandler={(newValue: string) => setPassword(newValue)}
                    label="Пароль*"
                    type={INPUT_TYPE.PASSWORD}
                    enterHandler={handleSave}
                />
                <div className={styles.buttons}>
                    <Button
                        color={BUTTON_COLOR.BLUE}
                        disabled={password.length < 1}
                        onClick={handleSave}
                        loading={state.loaders[LOADERS.SET_PASSWORD][LOADERS_TYPE.LOADING]}
                    >
                        Сохранить
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default NewPassword;
