import * as React from 'react';
import { unstable_HistoryRouter as HistoryRouter, Routes, Route, Navigate } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import { State } from '~/store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { setAccountLogin, accountLogout } from '~/store/actions/actionAccount';
import { appHistory } from '~/routes/history';
import Layout from '~/containers/Layout/Layout';
import OtherRoutes from '~/routes/OtherRoutes';
import Auth from '~/containers/Auth/Auth';
import Login from '~/containers/Auth/Login';
import Register from '~/containers/Auth/Register';
import ResetPassword from '~/containers/Auth/ResetPassword';
import ResetPasswordSuccess from '~/containers/Auth/ResetPasswordSuccess';
import PrivateRoutes from '~/routes/PrivateRoutes';
import ConfirmEmail from '~/containers/Auth/ConfitmEmail/ConfirmEmail';
import Popup from '~/components/Popup/Popup';
import Manager from '~/components/Popup/Manager';
import Toasts from '~/containers/Toasts/Toasts';
import Gallery from '~/components/Gallery/Gallery';
import { useAppSelector } from '~/store';
import { selectGalleryShown } from '~/store/slices/app/slice';

const Tasks = React.lazy(() => import('~/containers/Tasks/Tasks'));
const Logs = React.lazy(() => import('~/containers/Tasks/Logs/Logs'));
const Timings = React.lazy(() => import('~/containers/Tasks/Timings/Timings'));
const CreateTask = React.lazy(() => import('~/containers/Tasks/CreateTask/CreateTask'));
const Projects = React.lazy(() => import('~/containers/Projects/Projects'));
const CreateProject = React.lazy(() => import('~/containers/Projects/Create/Create'));
const Users = React.lazy(() => import('~/containers/Users/Users'));
const Online = React.lazy(() => import('~/containers/Online/Online'));

interface IStateSelector {
    checkAuth: boolean;
    popup: IPopup | null;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        checkAuth: state.reducerAccount.checkAuth,
        popup: state.reducerApp.popup,
    };
};

const Routing = () => {
    const urlParams = new URL(window.location.href).searchParams;
    const token = urlParams.get('token') || urlParams.get('invite');
    const dispatch = useDispatch();
    const galleryShown = useAppSelector((state) => selectGalleryShown(state));
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const socket = localStorage.getItem('socket');

    const checkAuth = async () => {
        if (!accessToken || !refreshToken || !socket) {
            // @ts-ignore
            dispatch(accountLogout());
        } else dispatch(await setAccountLogin(accessToken, refreshToken, socket));
    };

    React.useEffect(() => {
        if (token) {
            (async () => {
                dispatch(await setAccountLogin(token, token, token));
            })();
        } else {
            checkAuth();
        }
    }, []);

    if (!state.checkAuth) return null;

    return (
        <HistoryRouter history={appHistory}>
            <Routes>
                <Route element={<OtherRoutes />}>
                    <Route path={urlsMap.index} element={<Auth />}>
                        <Route path={urlsMap.index} element={<Login />} />
                        <Route path={urlsMap.register} element={<Register />} />
                        <Route path={urlsMap.resetPassword} element={<ResetPassword />} />
                        <Route
                            path={urlsMap.resetPasswordSuccess}
                            element={<ResetPasswordSuccess />}
                        />
                    </Route>
                </Route>
                <Route element={<PrivateRoutes />}>
                    <Route path={urlsMap.index} element={<Layout />}>
                        <Route path={`${urlsMap.taskList}/*`} element={<Tasks />} />
                        <Route path={`${urlsMap.taskList}/:id${urlsMap.logs}`} element={<Logs />} />
                        <Route
                            path={`${urlsMap.taskList}/:id${urlsMap.timings}`}
                            element={<Timings />}
                        />
                        <Route path={urlsMap.createTask} element={<CreateTask />} />
                        <Route path={`${urlsMap.projectList}/*`} element={<Projects />} />
                        <Route path={urlsMap.createProject} element={<CreateProject />} />
                        <Route path={`${urlsMap.userList}/*`} element={<Users />} />
                        <Route path={`${urlsMap.online}`} element={<Online />} />
                        <Route path="*" element={<Navigate to={urlsMap.taskList} />} />
                    </Route>
                </Route>
                <Route path={urlsMap.emailConfirm} element={<ConfirmEmail />} />
                <Route path={`${urlsMap.inviteGroup}/:id`} element={<ConfirmEmail />} />
                <Route path={`${urlsMap.inviteProject}/:id`} element={<ConfirmEmail />} />
            </Routes>
            {state.popup && (
                <Popup wrapper={state.popup.wrapper} withoutClose={state.popup.withoutClose}>
                    <Manager name={state.popup.name} data={state.popup.data} />
                </Popup>
            )}
            <Toasts />
            {galleryShown && (
                // TODO: move Gallery out from files. Should be somewhere else.
                <Gallery />
            )}
        </HistoryRouter>
    );
};

export default Routing;
