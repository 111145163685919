import { TASKS_FILTERS } from '~/utils/tasks';

export const prepareMe = (me: IAccount) => {
    const filters: IFilter[] = [];

    if (me !== undefined && me.filters !== undefined) {
        me.filters.map((filter) => {
            if (Array.isArray(filter?.options?.[TASKS_FILTERS.CREATOR])) {
                filters.push(filter);
            }
        });
    }

    return {
        ...me,
        filters,
    };
};
