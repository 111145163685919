import * as React from 'react';
import styles from '~/containers/Groups/Invites/Invites.module.scss';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, LOADERS, LOADERS_TYPE, TOAST } from '~/const';
import { addToast, changeVisiblePopup } from '~/store/actions/actionApp';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store/reducers';
import equal from 'fast-deep-equal/react';
import { _updateProject } from '~/store/actions/actionProjects';
import { apiKickInvite } from '~/api/project';

interface IStateSelector {
    loaders: ILoaders;
    userList: IUserList;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        loaders: state.reducerApp.loaders,
        userList: state.reducerUsers.userList,
    };
};

interface IFC {
    project: IProject;
    user: IUser;
}

const ProjectKick = ({ project, user }: IFC) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const relations = project?.relations || [];
    const projectId = project.id;

    const closeHandler = () => {
        dispatch(changeVisiblePopup(null));
    };

    const kickHandler = async () => {
        const inviteId = relations.find((relation) => relation.user_id === user.id)?.id;

        if (inviteId) {
            apiKickInvite(projectId, inviteId).then(async () => {
                dispatch(
                    _updateProject(
                        {
                            project: {
                                relations: relations.filter(
                                    (relation) => relation.user_id !== user.id,
                                ),
                            },
                        },
                        projectId,
                    ),
                );
                dispatch(
                    addToast({
                        type: TOAST.SUCCESS,
                        title: 'Пользователь удален из проекта',
                        timer: 3000,
                    }),
                );
            });
        }

        closeHandler();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Удаление пользователя из проекта</div>
            <p>Вы уверены, что хотите удалить {user.name} из проекта?</p>
            <div className={styles.buttons}>
                <Button color={BUTTON_COLOR.GRAY} onClick={closeHandler}>
                    Отмена
                </Button>
                <Button
                    color={BUTTON_COLOR.RED}
                    onClick={kickHandler}
                    loading={state.loaders[LOADERS.GROUP][LOADERS_TYPE.LOADING]}
                >
                    Удалить
                </Button>
            </div>
        </div>
    );
};

export default ProjectKick;
