import * as React from 'react';
import styles from '~/containers/Groups/Groups.module.scss';
import classNames from 'classnames';
import Input from '~/components/Form/Input/Input';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, INPUT_SIZE, LOADERS, LOADERS_TYPE, META, TOAST } from '~/const';
import { useDispatch, useSelector } from 'react-redux';
import { addToast, changeVisiblePopup, updateLoaders, updateMeta } from '~/store/actions/actionApp';
import { apiDeleteGroup } from '~/api/user';
import { _updateGroup } from '~/store/actions/actionAccount';
import { State } from '~/store/reducers';
import equal from 'fast-deep-equal/react';
import { strSanitize } from '~/utils/utils';

interface IStateSelector {
    loaders: ILoaders;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        loaders: state.reducerApp.loaders,
    };
};

interface IFC {
    group: IGroup;
}

const DeleteGroup = ({ group }: IFC) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const [name, setName] = React.useState<string>('');
    const title = strSanitize(group.title);

    const closeHandler = () => {
        dispatch(changeVisiblePopup(null));
    };

    const deleteHandler = async () => {
        dispatch(
            updateLoaders({
                [LOADERS.GROUP]: {
                    [LOADERS_TYPE.LOADING]: true,
                },
            }),
        );
        await apiDeleteGroup(group.id)
            .then(() => {
                dispatch(_updateGroup(group, undefined, true));
                // @ts-ignore
                dispatch(updateMeta(META.CURRENT_GROUP, null));
                closeHandler();
                dispatch(
                    addToast({
                        type: TOAST.SUCCESS,
                        title: `Группа ${group.title} удалена`,
                        timer: 3000,
                    }),
                );
            })
            .finally(() =>
                dispatch(
                    updateLoaders({
                        [LOADERS.GROUP]: {
                            [LOADERS_TYPE.LOADING]: false,
                        },
                    }),
                ),
            );
    };

    return (
        <div className={styles.content}>
            <div className={classNames('contentHeader', styles.contentHeader)}>
                <h2>Удаление группы {group.title}</h2>
                <p>
                    Внимание! Вместе с удалением группы
                    <br />
                    пропадут все проекты и задачи.
                </p>
                <p>
                    Введите «{title}», чтобы подтвердить
                    <br />
                    удаление группы
                </p>
            </div>
            <div className={styles.form}>
                <Input
                    withoutFormik={true}
                    label="Название группы"
                    className={classNames(styles.bigLabel)}
                    size={INPUT_SIZE.BIG}
                    defaultValue={name}
                    changeHandler={(value) => setName(value)}
                    enterHandler={() => {
                        if (name === title) {
                            deleteHandler();
                        }
                    }}
                />
                <div className={styles.buttons}>
                    <Button color={BUTTON_COLOR.GRAY} onClick={closeHandler}>
                        Отмена
                    </Button>
                    <Button
                        color={BUTTON_COLOR.RED}
                        disabled={name !== title}
                        onClick={deleteHandler}
                        loading={state.loaders[LOADERS.GROUP][LOADERS_TYPE.LOADING]}
                    >
                        Удалить
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DeleteGroup;
