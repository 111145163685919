import * as React from 'react';
import styles from './Tooltip.module.scss';
import classNames from 'classnames';
import { PLACE } from '~/const';

interface IFC {
    text?: string | IChildElement;
    children: React.ReactChild | React.ReactChild[];
    place?: string;
    className?: string;
    onClick?: () => void;
}

const Tooltip = ({ text = '', children, place, className, onClick }: IFC) => {
    return (
        <div className={classNames(styles.tooltip, className)} onClick={onClick}>
            {children}
            {!!text && (
                <div
                    className={classNames(styles.text, {
                        [styles.top]: place === PLACE.TOP,
                        [styles.left]: place === PLACE.LEFT,
                        [styles.right]: place === PLACE.RIGHT,
                    })}
                >
                    <span>{text}</span>
                </div>
            )}
        </div>
    );
};

export default Tooltip;
