import {
    IAccountLogout,
    ISetAccountLogin,
    IUpdateConfirmEmail,
    IUpdateCurrentTask,
    IUpdateFilters,
    IUpdateGroup,
    IUpdateName,
    IUpdateProjectInvite,
    IUpdateWorkedTime,
} from '~/store/actions/types/actionAccountTypes';
import { apiGetMe, apiRefreshToken, apiUpdateGroup } from '~/api/user';
import { removeTokens, setTokens } from '~/utils/storage';
import { Dispatch } from 'react';
import {
    IClearState,
    IUpdateFirstLoaded,
    IUpdateLoaders,
} from '~/store/actions/types/actionAppTypes';
import { _clearState, updateLoaders } from '~/store/actions/actionApp';
import { LOADERS, LOADERS_TYPE } from '~/const';

export const UPDATE_NAME = 'UPDATE_NAME';
export const _updateAccountName = (name: string): IUpdateName => {
    return {
        type: UPDATE_NAME,
        name,
    };
};

export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const updateFilters = (filters: IFilter[]): IUpdateFilters => {
    return {
        type: UPDATE_FILTERS,
        filters,
    };
};

export const UPDATE_WORKED_TIME = 'UPDATE_WORKED_TIME';
export const updateWorkedTime = (): IUpdateWorkedTime => {
    return {
        type: UPDATE_WORKED_TIME,
    };
};

export const UPDATE_CONFIRM_EMAIL = 'UPDATE_CONFIRM_EMAIL';
export const _updateConfirmEmail = (isValid: boolean): IUpdateConfirmEmail => {
    return {
        type: UPDATE_CONFIRM_EMAIL,
        isValid,
    };
};

export const UPDATE_CURRENT_TASK = 'UPDATE_CURRENT_TASK';
export const updateCurrentTask = (currentTask: ICurrentTask | null): IUpdateCurrentTask => {
    return {
        type: UPDATE_CURRENT_TASK,
        currentTask,
    };
};

export const SET_ACCOUNT_LOGIN = 'SET_ACCOUNT_LOGIN';
export const setAccountLogin = async (
    accessToken: string,
    refreshToken: string,
    socket: string,
): Promise<ISetAccountLogin> => {
    setTokens(accessToken, refreshToken, socket);

    let account: IAccount | null = null;

    // TODO: какая-то ерунда со смешиванием промисов и async/await
    await apiGetMe(true)
        .then((response: IAccount) => {
            account = response;
        })
        .catch(async () => {
            await apiRefreshToken(refreshToken)
                .then(async (response) => {
                    setTokens(response.accessToken, response.refreshToken);

                    return apiGetMe();
                })
                .then((response: IAccount) => {
                    account = response;
                })
                .catch(() => {
                    removeTokens();
                });
        });

    return {
        type: SET_ACCOUNT_LOGIN,
        account,
    };
};

export const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT';
export const _accountLogout = (withoutCheck?: boolean): IAccountLogout => {
    removeTokens();

    return {
        type: ACCOUNT_LOGOUT,
        withoutCheck: withoutCheck,
    };
};

export const UPDATE_GROUP = 'UPDATE_GROUP';
export const _updateGroup = (group: IGroup, add?: boolean, remove?: boolean): IUpdateGroup => {
    return {
        type: UPDATE_GROUP,
        group,
        add,
        remove,
    };
};

export const UPDATE_PROJECT_INVITE = 'UPDATE_PROJECT_INVITE';
export const _updateProjectInvite = (
    invite: IProjectInvite,
    add?: boolean,
    remove?: boolean,
): IUpdateProjectInvite => {
    return {
        type: UPDATE_PROJECT_INVITE,
        invite,
        add,
        remove,
    };
};

export const accountLogout = (withoutCheck?: boolean) => {
    return async (
        dispatch: Dispatch<IAccountLogout | IClearState | IUpdateFirstLoaded>,
    ): Promise<void> => {
        dispatch(_accountLogout(withoutCheck));
        dispatch(_clearState());
    };
};

export const updateGroup = (group: IGroup, prevData: IGroup) => {
    return async (dispatch: Dispatch<IUpdateGroup | IUpdateLoaders>): Promise<void> => {
        dispatch(_updateGroup(group));
        dispatch(
            updateLoaders({
                [LOADERS.GROUP]: {
                    [LOADERS_TYPE.LOADING]: true,
                },
            }),
        );
        await apiUpdateGroup(group)
            .then((res) => dispatch(_updateGroup(res.group)))
            .catch(() => dispatch(_updateGroup(prevData)))
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.GROUP]: {
                            [LOADERS_TYPE.LOADING]: false,
                        },
                    }),
                );
            });
    };
};
