export const validateEmail = (value: string) => {
    let error;

    value = value.trim();

    error = validateRequired(value);

    if (
        !value.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
    ) {
        error = 'введите валидный e-mail, формата todo@foach.site';
    }

    return error;
};

export const validateRequired = (value: string) => {
    let error;

    if (!value) {
        error = 'обязательно для заполнения';
    }

    return error;
};
