import { get, post } from '~/utils/api';
import store from '~/store';
import { META } from '~/const';

export const apiGetDictionaries = async () => {
    const groupId = store.getState().reducerApp.meta[META.CURRENT_GROUP];

    return await get(`dictionaries?${groupId ? '&group_id=' + groupId : ''}`);
};

export const apiGetTags = async () => await get('tags');

export const apiGetNotifications = async () => {
    const offset = store.getState().reducerApp.notifications.rows.length;

    return await get(`notifications?limit=100&offset=${offset}`);
};

export const apiReadNotifications = async (data?: any) => await post('notifications/read', data);

export const apiReadAllNotifications = async () => await post('notifications/readAll');
