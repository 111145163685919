import * as React from 'react';
import styles from './Auth.module.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import Input from '~/components/Form/Input/Input';
import { BUTTON_SIZE, INPUT_SIZE } from '~/const';
import { validateEmail, validateRequired } from '~/utils/form';
import Button from '~/components/Button/Button';
import { apiPasswordRecovery } from '~/api/user';
import { urlsMap } from '~/utils/urls';
import { useDocumentTitle } from 'usehooks-ts';

const ResetPassword = () => {
    useDocumentTitle('Восстановление пароля');
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = (
        values: Record<string, string>,
        setSubmitting: (isSubmitting: boolean) => void,
        setFieldError: (field: string, message: string) => void,
    ) => {
        const { email } = values;
        apiPasswordRecovery({ email }, true)
            .then(() => {
                navigate(urlsMap.resetPasswordSuccess);
            })
            .catch((error) => {
                setFieldError('error', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <div className={styles.authContent}>
            <h2>{t('Забыли пароль?')}</h2>
            <p>{t('Пожалуйста, напишите свой email адрес и мы вышлем ссылку для авторизации')}</p>
            <Formik
                initialValues={{
                    email: '',
                    error: '',
                }}
                onSubmit={(values, { setSubmitting, setFieldError }) =>
                    handleSubmit(values, setSubmitting, setFieldError)
                }
            >
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <Input
                            name="email"
                            label="E-mail адрес"
                            error={errors.email}
                            isTouched={touched.email}
                            validate={validateEmail}
                            size={INPUT_SIZE.BIG}
                            className={styles.inputBlock}
                        />
                        {errors.error && <div className={styles.serverError}>{errors.error}</div>}
                        <Button
                            size={BUTTON_SIZE.BIG}
                            loading={isSubmitting}
                            disabled={!!Object.keys(errors).length}
                        >
                            {t('Отправить')}
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ResetPassword;
