import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n.ts';
import App from './App';
import * as Sentry from '@sentry/react';

const PRODUCTION_MODE = 'production';

if (process.env.NODE_ENV === PRODUCTION_MODE) {
    Sentry.init({
        dsn: 'https://bca0df5ea733422c964dbb7224216552@o4505464622874624.ingest.sentry.io/4505464626479104',
        tunnel: `${process.env.REACT_APP_API_HOST}proxy/sentry`,
    });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
