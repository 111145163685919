import * as React from 'react';
import styles from './Invites.module.scss';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, LOADERS, LOADERS_TYPE, TOAST } from '~/const';
import { addToast, changeVisiblePopup, updateLoaders } from '~/store/actions/actionApp';
import { useDispatch, useSelector } from 'react-redux';
import { apiKickInvite } from '~/api/user';
import { State } from '~/store/reducers';
import equal from 'fast-deep-equal/react';
import { _updateUserList } from '~/store/actions/actionUsers';

interface IStateSelector {
    loaders: ILoaders;
    userList: IUserList;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        loaders: state.reducerApp.loaders,
        userList: state.reducerUsers.userList,
    };
};

interface IFC {
    groupId: number;
    user: IUser;
}

const Kick = ({ groupId, user }: IFC) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);

    const closeHandler = () => {
        dispatch(changeVisiblePopup(null));
    };

    const kickHandler = () => {
        const inviteId = user.groups.find((group) => group.id === groupId)?.invite_id;

        if (!inviteId) {
            return;
        }

        dispatch(
            updateLoaders({
                [LOADERS.GROUP]: {
                    [LOADERS_TYPE.LOADING]: true,
                },
            }),
        );
        apiKickInvite(groupId, inviteId)
            .then(() => {
                dispatch(
                    _updateUserList(
                        state.userList.ids.filter((innerUser) => innerUser !== user.id),
                        state.userList.totalCount - 1,
                    ),
                );
                closeHandler();
                dispatch(
                    addToast({
                        type: TOAST.SUCCESS,
                        title: 'Пользователь удален из группы',
                        timer: 3000,
                    }),
                );
            })
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.GROUP]: {
                            [LOADERS_TYPE.LOADING]: false,
                        },
                    }),
                );
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Удаление пользователя из группы</div>
            <p>Вы уверены, что хотите удалить {user.name} из группы?</p>
            <div className={styles.buttons}>
                <Button color={BUTTON_COLOR.GRAY} onClick={closeHandler}>
                    Отмена
                </Button>
                <Button
                    color={BUTTON_COLOR.RED}
                    onClick={kickHandler}
                    loading={state.loaders[LOADERS.GROUP][LOADERS_TYPE.LOADING]}
                >
                    Удалить
                </Button>
            </div>
        </div>
    );
};

export default Kick;
