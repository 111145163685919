import store from '~/store';
import { loadTaskList } from '~/store/actions/actionTasks';
import { loadProjectList } from '~/store/actions/actionProjects';
import { loadUserList } from '~/store/actions/actionUsers';
import { apiGetDictionaries } from '~/api/app';
import { updateDictionaries } from '~/store/actions/actionApp';

export const prepareEntities = async () => {
    // TODO: сейчас запросы кидаются последовательно, нужно сделать параллельные одновременные запросы (те, которые не зависят от предыдущих)

    const localDictionaries = localStorage.getItem('dictionaries');
    if (!localDictionaries) {
        await apiGetDictionaries().then((res) => {
            store.dispatch(updateDictionaries(res));
        });
    } else {
        store.dispatch(updateDictionaries(JSON.parse(localDictionaries)));
        apiGetDictionaries().then((res) => {
            store.dispatch(updateDictionaries(res));
        });
    }

    const tasksState = store.getState().reducerTasks;

    store.dispatch(
        // @ts-ignore
        loadTaskList(0, tasksState.taskList.options, undefined),
    );

    const projectsState = store.getState().reducerProjects;

    store.dispatch(
        // @ts-ignore
        loadProjectList(0, projectsState.projectList.options, undefined),
    );

    const usersState = store.getState().reducerUsers;

    store.dispatch(
        // @ts-ignore
        loadUserList(0, usersState.userList.options, undefined),
    );
};
