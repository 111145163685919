import { Centrifuge } from 'centrifuge';
import { SOCKET_MODEL, META } from '~/const';
import store from '~/store';
import { _updateTask, _addTaskTiming, _updateTaskList } from '~/store/actions/actionTasks';
import { prepareTaskFromServer } from '~/utils/tasks';

export const socket = (accountId: number) => {
    const centrifuge = new Centrifuge(`${process.env.REACT_APP_WS_HOST}`, {
        token: localStorage.getItem('socket'),
    });

    const sub = centrifuge.newSubscription(`channel_${accountId}`);

    sub.on('publication', (ctx) => {
        const data = ctx.data.data;
        switch (ctx.data.model) {
            case SOCKET_MODEL.TASK:
                const task: ITask = prepareTaskFromServer(data, store.dispatch);
                store.dispatch(_updateTask({ task }, data.id));

                const taskList = store.getState().reducerTasks.taskList;
                // @ts-ignore
                const projects = store.getState().reducerProjects.projects;

                if (!taskList.ids.includes(task.id)) {
                    const groupId = store.getState().reducerApp.meta[META.CURRENT_GROUP];
                    if (
                        !groupId ||
                        (projects && task.project && groupId === projects[task.project].group_id)
                    ) {
                        store.dispatch(_updateTaskList([task.id], taskList.totalCount + 1, true));
                    }
                }

                break;
            case SOCKET_MODEL.TIMING:
                store.dispatch(_addTaskTiming(data, data.taskid));
                break;
            case SOCKET_MODEL.NOTIFICATION:
                store.getState().notifications.totalCount =
                    store.getState().notifications.totalCount + 1;

                store.dispatch({ type: `notifications/add`, payload: [data] });
                break;
            default:
                break;
        }
    });

    sub.subscribe();

    centrifuge.connect();
};
