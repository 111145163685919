import * as React from 'react';
import Invite from '~/containers/Groups/Invites/Invite';
import Kick from '~/containers/Groups/Invites/Kick';
import Create from '~/containers/Groups/Create';
import Accept from '~/containers/Groups/Invites/Accept';
import NewPassword from '~/containers/Auth/NewPassword/NewPassword';
import DeleteGroup from '~/containers/Groups/Delete';
import DeleteProject from '~/containers/Projects/Delete/Delete';
import LeaveGroup from '~/containers/Groups/Leave';
import ConfirmEmail from '~/containers/Auth/ConfitmEmail/ConfirmEmail';
import TaskTransfer from '~/containers/Tasks/EditTask/Transfer/Transfer';
import ProjectInvite from '~/containers/Projects/Users/Invite';
import ProjectKick from '~/containers/Projects/Users/Kick';
import ProjectAccept from '~/containers/Projects/Users/Accept';
import DeleteWikiPage from '~/containers/Projects/Wiki/Delete/Delete';
import RepeatTaskPopup from '~/containers/Tasks/EditTask/Repeat/Repeat';

interface IFC {
    name: string;
    data?: any;
}

export const POPUPS_NAME = {
    CREATE_GROUP: 'CREATE_GROUP',
    DELETE_GROUP: 'DELETE_GROUP',
    LEAVE_GROUP: 'LEAVE_GROUP',
    GROUP_INVITE: 'GROUP_INVITE',
    DELETE_USER_FROM_GROUP: 'DELETE_USER_FROM_GROUP',
    ACCEPT_GROUP: 'ACCEPT_GROUP',
    NEW_PASSWORD: 'NEW_PASSWORD',
    DELETE_PROJECT: 'DELETE_PROJECT',
    CONFIRM_EMAIL: 'CONFIRM_EMAIL',
    TASK_TRANSFER: 'TASK_TRANSFER',
    PROJECT_INVITE: 'PROJECT_INVITE',
    PROJECT_KICK: 'PROJECT_KICK',
    ACCEPT_PROJECT: 'ACCEPT_PROJECT',
    DELETE_WIKI_PAGE: 'DELETE_WIKI_PAGE',
    REPEAT_TASK: 'REPEAT_TASK',
};

const Manager = ({ name, data }: IFC) => {
    switch (name) {
        case POPUPS_NAME.CREATE_GROUP:
            return <Create />;
        case POPUPS_NAME.DELETE_GROUP:
            return <DeleteGroup group={data.activeGroup} />;
        case POPUPS_NAME.LEAVE_GROUP:
            return <LeaveGroup group={data.activeGroup} />;
        case POPUPS_NAME.GROUP_INVITE:
            return <Invite groupId={data.groupId} />;
        case POPUPS_NAME.DELETE_USER_FROM_GROUP:
            return <Kick groupId={data.groupId} user={data.user} />;
        case POPUPS_NAME.ACCEPT_GROUP:
            return <Accept group={data.group} />;
        case POPUPS_NAME.NEW_PASSWORD:
            return <NewPassword />;
        case POPUPS_NAME.DELETE_PROJECT:
            return <DeleteProject project={data.project} />;
        case POPUPS_NAME.CONFIRM_EMAIL:
            return <ConfirmEmail />;
        case POPUPS_NAME.TASK_TRANSFER:
            return <TaskTransfer taskId={data.taskId} />;
        case POPUPS_NAME.PROJECT_INVITE:
            return <ProjectInvite project={data.project} />;
        case POPUPS_NAME.PROJECT_KICK:
            return <ProjectKick project={data.project} user={data.user} />;
        case POPUPS_NAME.ACCEPT_PROJECT:
            return <ProjectAccept invite={data.invite} />;
        case POPUPS_NAME.DELETE_WIKI_PAGE:
            return <DeleteWikiPage projectId={data.projectId} page={data.page} />;
        case POPUPS_NAME.REPEAT_TASK:
            return <RepeatTaskPopup taskId={data.taskId} />;
        default:
            return null;
    }
};

export default Manager;
