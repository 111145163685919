import * as React from 'react';
import styles from './Skeleton.module.scss';
import classNames from 'classnames';
import { ReactComponent as Priority } from '~/assets/icons/priority0.svg';

export const SKELETON = {
    PRIORITY: 'priority',
    STATUS: 'status',
    TYPE: 'type',
    TEXT: 'text',
};

interface ISkeleton {
    className?: string;
    type?: string;
}

const Skeleton = ({ className, type }: ISkeleton) => {
    const render = () => {
        switch (type) {
            case SKELETON.PRIORITY:
                return <Priority />;
        }
    };

    return (
        <div className={classNames(styles.skeleton, type && styles[type], className)}>
            {render()}
        </div>
    );
};

export default Skeleton;
