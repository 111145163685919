import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getNotifications } from '~/store/slices/notification/reducers';
import { readNotifications } from '~/store/slices/notification/reducers';
import { readAllNotifications } from '~/store/slices/notification/reducers';

export const notificationsAdapter = createEntityAdapter<INotification>();

export interface INotificationState {
    ids: number[];
    entities: Record<number, INotification[]>;
    totalCount: 0;
    viewedCount: 0;
}

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        ...notificationsAdapter.getInitialState(),
        totalCount: 0,
        viewedCount: 0,
    },
    reducers: {
        create: notificationsAdapter.setMany,
        add: notificationsAdapter.addMany,
        update: notificationsAdapter.upsertMany,
        remove: notificationsAdapter.removeMany,
        clear: notificationsAdapter.removeAll,
    },
    extraReducers: (builder) => {
        builder.addCase(getNotifications.fulfilled, (state, action) => {
            if (action.payload) {
                notificationsAdapter.addMany(state, action.payload.rows);
                state.totalCount = action.payload.totalCount;
            }
        });
        builder.addCase(readNotifications.fulfilled, (state, action) => {
            action.payload.ids.map((item) => {
                if (state.entities?.[item]) {
                    state.entities[item].is_read = true;
                }
            });

            state.viewedCount = action.payload.viewedCount ?? state.viewedCount;
        });
        builder.addCase(readAllNotifications.fulfilled, (state) => {
            state.ids.map((id) => {
                if (state.entities?.[id]) {
                    state.entities[id].is_read = true;
                }
            });

            state.viewedCount = state.totalCount;
        });
    },
    selectors: {
        selectNotifications: (sliceState) => sliceState,
        selectNotificationsAll: (sliceState) => sliceState.ids.map((id) => sliceState.entities[id]),
        selectNotificationsCount: (sliceState) => sliceState.totalCount - sliceState.viewedCount,
    },
});

export type notificationsSlice = {
    [notificationsSlice.name]: ReturnType<typeof notificationsSlice['reducer']>;
};
export const { create, update, remove, clear, add } = notificationsSlice.actions;
export const { selectNotifications, selectNotificationsAll, selectNotificationsCount } =
    notificationsSlice.selectors;
