import * as React from 'react';
import styles from './Popover.module.scss';
import {
    calculateTimeTask,
    isFutureDay,
    IPopoverPopup,
} from '~/containers/Tasks/Calendar/Calendar';
import moment, { Moment } from 'moment';
import 'moment/locale/ru';
import { prepareTimeFromMinutes, difficultyToMs, groupTasksProject } from '~/utils/utils';
import Status from '~/components/Icons/Status/Status';
import { Link } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import classNames from 'classnames';
import { TASK_STATUS, TASK_TYPES, SERVER_DATE_FORMAT_HHMMSS } from '~/const';
import { State } from '~/store/reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';

interface IFC {
    props?: IPopoverPopup;
    closeHandler: () => void;
}

interface IStateSelector {
    projects: IProjectEntities;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        projects: state.reducerProjects.projects,
    };
};

const Popover = ({ props, closeHandler }: IFC) => {
    if (!props) {
        return null;
    }

    const state = useSelector<State, IStateSelector>(stateSelector, equal);

    const taskPlanned = props.popover.filter(
        (item) =>
            item.type !== TASK_TYPES.MEETS &&
            !item.timings?.find((timing) =>
                moment(timing.stime, SERVER_DATE_FORMAT_HHMMSS).isSame(moment(), 'day'),
            ),
    );

    const task = props.popover.filter(
        (item) =>
            item.type !== TASK_TYPES.MEETS &&
            !!item.timings?.find((timing) =>
                moment(timing.stime, SERVER_DATE_FORMAT_HHMMSS).isSame(moment(), 'day'),
            ),
    );

    const tasksMeets = props.popover.filter((item) => item.type === TASK_TYPES.MEETS);

    const taskTime = (timing: ITiming[] = [], date?: string, day?: Moment, difficulty?: number) => {
        let time = 0;
        if (isFutureDay(date, day)) {
            time += difficultyToMs(difficulty);

            if (timing && difficulty) {
                time = calculateTimeTask(time, timing, false);
            }
        } else {
            time = calculateTimeTask(time, timing, false, day);
        }
        return time / 1000 / 60;
    };

    const RenderTasks = (tasks: ITask[]) => {
        return tasks.map((task) => {
            return (
                <div
                    className={classNames(styles.task, {
                        [styles.completed]: task.status
                            ? [
                                  TASK_STATUS.ACCEPTED,
                                  TASK_STATUS.CANCELED,
                                  TASK_STATUS.COMPLETED,
                              ].includes(task.status)
                            : false,
                    })}
                    key={task.id}
                >
                    <div className={styles.title}>
                        <Link to={urlsMap.taskList + '/' + task.id} onClick={closeHandler}>
                            {task.title}
                        </Link>
                    </div>
                    <div className={styles.difficulty}>
                        {task.type === TASK_TYPES.MEETS
                            ? task.wdate
                                ? `${moment(task.wdate, SERVER_DATE_FORMAT_HHMMSS).format(
                                      'HH:mm',
                                  )} - ${moment(task.wdate, SERVER_DATE_FORMAT_HHMMSS)
                                      .add(task.difficulty, 'minutes')
                                      .format('HH:mm')}`
                                : ''
                            : prepareTimeFromMinutes(
                                  taskTime(
                                      task.timings,
                                      task.wdate,
                                      props.date,
                                      !!task.has_subtasks ? 15 : task.difficulty,
                                  ),
                              )}
                    </div>
                    <Status
                        statusId={task.status}
                        projectId={task.project}
                        className={styles.status}
                    />
                </div>
            );
        });
    };

    const RenderGroup = (tasks: ITask[]) => {
        const groupTasks = groupTasksProject(tasks);
        return Object.keys(groupTasks).map((item, key) => {
            const projects = Object.values(state.projects || {}).find((p) => p.id === Number(item));

            return (
                <div className={styles.groupContainer} key={key}>
                    <div className={styles.groupTitle}>
                        {!!projects && (
                            <Link
                                to={urlsMap.projectList + '/' + projects.id}
                                onClick={closeHandler}
                            >
                                {projects.title}
                            </Link>
                        )}
                        :
                    </div>
                    <div className={styles.groupTasks}>{RenderTasks(groupTasks[item])}</div>
                </div>
            );
        });
    };

    return (
        <div
            className={styles.popover}
            style={{ top: props.top, right: props.right, maxHeight: `calc(100% - ${props.top}px)` }}
        >
            <div className={styles.date}>
                Задачи на <span>{moment(props.date).locale('ru').format('D MMMM')}</span>
            </div>
            {props.isCurrentDate ? (
                <>
                    {!!tasksMeets.length && (
                        <>
                            <p className={styles.popoverTitle}>Встречи</p>
                            {RenderGroup(tasksMeets)}
                        </>
                    )}
                    {!!taskPlanned.length && (
                        <>
                            <p className={styles.popoverTitle}>Запланированные</p>
                            {RenderGroup(taskPlanned)}
                        </>
                    )}
                    {!!task.length && (
                        <>
                            <p className={styles.popoverTitle}>Выполнявшиеся</p>
                            {RenderGroup(task)}
                        </>
                    )}
                </>
            ) : (
                <>
                    {!!tasksMeets.length && (
                        <>
                            <p className={styles.popoverTitle}>Встречи</p>
                            {RenderGroup(tasksMeets)}
                        </>
                    )}
                    {(!!taskPlanned.length || !!task.length) && (
                        <>
                            <p className={styles.popoverTitle}>Задачи</p>
                            {RenderGroup([...taskPlanned, ...task])}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default Popover;
