import * as React from 'react';
import styles from './Delete.module.scss';
import { urlsMap } from '~/utils/urls';
import { changeVisiblePopup } from '~/store/actions/actionApp';
import { BUTTON_COLOR } from '~/const';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import Button from '~/components/Button/Button';
import { apiDeleteWikiPage } from '~/api/project';
import { _updateWikiPage } from '~/store/actions/actionProjects';

interface IFC {
    projectId: number;
    page?: IWikiPage;
}

const DeleteWikiPage = ({ projectId, page }: IFC) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);

    const closeHandler = () => {
        dispatch(changeVisiblePopup(null));
    };

    const handleDelete = () => {
        if (page) {
            setLoading(true);

            apiDeleteWikiPage(page?.id)
                .then(() => {
                    navigate(`${urlsMap.projectList}/${projectId}${urlsMap.wiki}/0`);

                    dispatch(_updateWikiPage({}, projectId, page?.id, undefined, true));
                })
                .finally(() => {
                    setLoading(false);
                    closeHandler();
                });
        }
    };

    return (
        <div className={styles.content}>
            <div className={classNames('contentHeader', styles.contentHeader)}>
                <h2>Удаление страницы</h2>
                <p>Вы уверены, что хотите удалить страницу “{page?.title}”?</p>
            </div>
            <div className={styles.form}>
                <div className={styles.buttons}>
                    <Button color={BUTTON_COLOR.GRAY} onClick={closeHandler}>
                        Отмена
                    </Button>
                    <Button color={BUTTON_COLOR.RED} onClick={handleDelete} loading={loading}>
                        Удалить
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DeleteWikiPage;
