import * as React from 'react';
import styles from './Invites.module.scss';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, LOADERS, LOADERS_TYPE, TOAST } from '~/const';
import CreatableInputField from '~/components/Form/Select/CreatableInput';
import { addToast, changeVisiblePopup, updateLoaders } from '~/store/actions/actionApp';
import { useDispatch, useSelector } from 'react-redux';
import { apiGroupInvite } from '~/api/user';
import { State } from '~/store/reducers';
import equal from 'fast-deep-equal/react';
import { _addUsers, _updateUserList } from '~/store/actions/actionUsers';

interface IStateSelector {
    loaders: ILoaders;
    users: IUserList;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        loaders: state.reducerApp.loaders,
        users: state.reducerUsers.userList,
    };
};

interface IFC {
    groupId: number;
}

const Invite = ({ groupId }: IFC) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const [emails, setEmails] = React.useState<string[]>([]);

    const closeHandler = () => {
        dispatch(changeVisiblePopup(null));
    };

    const inviteHandler = () => {
        dispatch(
            updateLoaders({
                [LOADERS.GROUP]: {
                    [LOADERS_TYPE.LOADING]: true,
                },
            }),
        );
        apiGroupInvite(groupId, { emails })
            .then((res) => {
                dispatch(_addUsers(res.relations));
                dispatch(
                    _updateUserList(
                        res.relations.map((user: IUser) => user.id),
                        state.users.totalCount + emails.length,
                        true,
                    ),
                );
                closeHandler();
                dispatch(
                    addToast({
                        type: TOAST.SUCCESS,
                        title: 'Новые пользователи приглашены в группу',
                        timer: 3000,
                    }),
                );
            })
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.GROUP]: {
                            [LOADERS_TYPE.LOADING]: false,
                        },
                    }),
                );
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Приглашение пользователей</div>
            <CreatableInputField
                label="Введите email-адреса сотрудников"
                className={styles.select}
                classNameWrapper={styles.selectWrapper}
                changeHandler={(value) => setEmails(value)}
            />
            <div className={styles.buttons}>
                <Button color={BUTTON_COLOR.GRAY} onClick={closeHandler}>
                    Отмена
                </Button>
                <Button
                    color={BUTTON_COLOR.BLUE}
                    disabled={emails.length < 1}
                    onClick={inviteHandler}
                    loading={state.loaders[LOADERS.GROUP][LOADERS_TYPE.LOADING]}
                >
                    Пригласить
                </Button>
            </div>
        </div>
    );
};

export default Invite;
