import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiGetNotifications, apiReadNotifications, apiReadAllNotifications } from '~/api/app';

const getNotifications = createAsyncThunk('notifications/add', async () => {
    return await apiGetNotifications();
});

const readNotifications = createAsyncThunk(
    'notifications/read',
    async ({ ids, viewedCount }: { ids: number[]; viewedCount?: number }) => {
        await apiReadNotifications({ ids });

        return {
            ids,
            viewedCount,
        };
    },
);

const readAllNotifications = createAsyncThunk('notifications/readAll', async () => {
    await apiReadAllNotifications();
});

export { getNotifications, readNotifications, readAllNotifications };
