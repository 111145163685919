import { Actions } from '~/store/actions';
import { defaultOptions } from '~/utils/users';
import {
    UPDATE_USER_LIST_OPTIONS,
    UPDATE_USER_LIST,
    ADD_USERS,
    UPDATE_USER,
    DELETE_USER,
    ADD_USERS_ONLINE,
} from '~/store/actions/actionUsers';
import { CLEAR_STATE } from '~/store/actions/actionApp';

export interface usersState {
    users: { [value: string]: IUser };
    userList: IUserList;
    onlineList: IUserOnlineList;
}

export const initialState: usersState = {
    users: {},
    userList: {
        ids: [],
        totalCount: 0,
        options: defaultOptions,
    },
    onlineList: {
        ids: [],
    },
};

const reducerUsers = (state: usersState = initialState, action: Actions) => {
    switch (action.type) {
        case CLEAR_STATE:
            return initialState;

        case ADD_USERS:
            const preparedUsers: { [value: string]: IUser } = {};
            action.users.forEach((user) => {
                if (user.id) preparedUsers[user.id] = { ...state.users[user.id], ...user };
            });

            return {
                ...state,
                users: action.withClearState ? preparedUsers : { ...state.users, ...preparedUsers },
            };

        case UPDATE_USER:
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.id]: {
                        ...state.users[action.id],
                        ...action.data.user,
                    },
                },
            };

        case DELETE_USER:
            delete state.users[action.id];

            return {
                ...state,
                userList: {
                    ...state.userList,
                    ids: state.userList.ids.filter((id) => id !== action.id),
                    totalCount: state.userList.totalCount - 1,
                },
            };

        case UPDATE_USER_LIST:
            return {
                ...state,
                userList: {
                    ...state.userList,
                    ids: action.additional
                        ? [...state.userList.ids, ...action.ids]
                        : [...action.ids],
                    totalCount: action.totalCount ?? state.userList.totalCount,
                },
            };

        case UPDATE_USER_LIST_OPTIONS:
            return {
                ...state,
                userList: {
                    ...state.userList,
                    options: {
                        ...state.userList.options,
                        ...action.options,
                    },
                },
            };

        case ADD_USERS_ONLINE:
            return {
                ...state,
                onlineList: {
                    ids: action.ids,
                },
            };

        default:
            return { ...state };
    }
};

export default reducerUsers;
