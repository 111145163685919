import * as React from 'react';
import styles from '~/containers/Groups/Invites/Invites.module.scss';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, INVITATIONS, LOADERS, LOADERS_TYPE, META } from '~/const';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store/reducers';
import equal from 'fast-deep-equal/react';
import { changeVisiblePopup, updateLoaders, updateMeta } from '~/store/actions/actionApp';
import { apiAcceptInvite, apiGetGroup, apiRefuseInvite } from '~/api/user';
import { _updateGroup } from '~/store/actions/actionAccount';
import store from '~/store';

interface IStateSelector {
    loaders: ILoaders;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        loaders: state.reducerApp.loaders,
    };
};

interface IFC {
    group: IGroup;
}

const Accept = ({ group }: IFC) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);

    const setLoader = () => {
        dispatch(
            updateLoaders({
                [LOADERS.GROUP]: {
                    [LOADERS_TYPE.LOADING]: true,
                },
            }),
        );
    };

    const removeLoader = () => {
        dispatch(
            updateLoaders({
                [LOADERS.GROUP]: {
                    [LOADERS_TYPE.LOADING]: false,
                },
            }),
        );
    };

    const handleClose = () => {
        dispatch(changeVisiblePopup(null));
    };

    const handleAccept = () => {
        setLoader();
        apiAcceptInvite(group.id)
            .then(() => {
                // @ts-ignore
                dispatch(updateMeta(META.CURRENT_GROUP, group.id));
                apiGetGroup(group.id).then((res) => {
                    store.dispatch(
                        _updateGroup({ ...res.group, is_invite: INVITATIONS.NOT_INVITED }),
                    );
                });
                handleClose();
            })
            .finally(() => {
                removeLoader();
            });
    };

    const handleRefuse = () => {
        setLoader();
        apiRefuseInvite(group.id)
            .then(() => {
                dispatch(_updateGroup(group, undefined, true));
                handleClose();
            })
            .finally(() => {
                removeLoader();
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Вас пригласили в группу {group.title}</div>
            <div className={classNames(styles.buttons, styles.buttonsMargin)}>
                <Button
                    color={BUTTON_COLOR.GRAY}
                    loading={state.loaders[LOADERS.GROUP][LOADERS_TYPE.LOADING]}
                    onClick={handleRefuse}
                >
                    Отклонить
                </Button>
                <Button
                    color={BUTTON_COLOR.BLUE}
                    loading={state.loaders[LOADERS.GROUP][LOADERS_TYPE.LOADING]}
                    onClick={handleAccept}
                >
                    Принять
                </Button>
            </div>
        </div>
    );
};

export default Accept;
