export const tableConfig = [
    { name: 'name', title: 'Имя' },
    { name: 'roleId', title: 'Роль' },
    { name: 'groups', title: 'Группы' },
    { name: 'controls', title: '' },
];

export const defaultOptions = {
    filters: {
        name: '',
    },
};

export const prepareUserFromServer = (user: any) => {
    return user;
};
