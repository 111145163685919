import * as React from 'react';
import styles from './Circle.module.scss';
import classNames from 'classnames';

interface IFC {
    className?: string;
}

const CircleLoader = ({ className }: IFC) => {
    return (
        <div className={classNames(styles.loader, className)}>
            <svg className={styles.circular} viewBox={'25 25 50 50'}>
                <circle
                    className={styles.path}
                    cx={'50'}
                    cy={'50'}
                    r={'20'}
                    fill={'none'}
                    strokeWidth={'4'}
                    strokeMiterlimit={'10'}
                />
            </svg>
        </div>
    );
};

export default CircleLoader;
