import * as React from 'react';
import styles from './Scroll.module.scss';
import classNames from 'classnames';
import { isNaN } from 'formik';
import moment from 'moment';

interface IScroll {
    element: React.RefObject<HTMLDivElement>;
    currentCount?: number;
    totalCount?: number;
    infiniteScroll?: () => void;
    className?: string;
    loading?: boolean;
    onScroll?: (visibleHeight: number) => void;
    limit?: number;
}

const Scroll = ({
    element,
    currentCount,
    totalCount,
    className,
    infiniteScroll,
    loading,
    onScroll,
    limit = 20,
}: IScroll) => {
    const [barHeight, setBarHeight] = React.useState(0);
    const [sliderHeight, setSliderHeight] = React.useState(0);
    const [sliderLoadingHeight, setSliderLoadingHeight] = React.useState(0);

    React.useEffect(() => {
        const container = element?.current;
        let _currentCount = currentCount;

        if (!container) return;

        const handleScroll = () => {
            const _differentHeight = container.scrollHeight - container.offsetHeight;
            const _sliderHeight = (container.scrollTop / _differentHeight) * 100;

            if (totalCount && currentCount) {
                const differentCount = currentCount / totalCount;
                setBarHeight(differentCount * 100);
                setSliderHeight(_sliderHeight * differentCount);
                if (
                    !loading &&
                    infiniteScroll &&
                    currentCount !== totalCount &&
                    container.scrollTop > _differentHeight - 50 &&
                    !isNaN(_sliderHeight * differentCount)
                ) {
                    if (_currentCount != currentCount) {
                        return;
                    }
                    _currentCount = 0;

                    infiniteScroll();
                    const afterLoadCount =
                        currentCount + limit < totalCount ? currentCount + limit : totalCount;
                    setSliderLoadingHeight((afterLoadCount / totalCount) * 100);
                }
            } else {
                setBarHeight(100);
                setSliderHeight(_sliderHeight);
            }

            if (onScroll) {
                onScroll(
                    container.scrollHeight - container.offsetHeight > 0
                        ? container.scrollTop + container.offsetHeight
                        : 0,
                );
            }
        };

        handleScroll();

        container.addEventListener('scroll', handleScroll);

        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [currentCount, totalCount, loading]);

    return (
        <div
            className={classNames(styles.scroll, className, {
                [styles.hiddenScroll]: isNaN(sliderHeight),
            })}
        >
            <div
                className={styles.scrollSliderLoading}
                style={{ height: sliderLoadingHeight + '%' }}
            ></div>
            <div className={styles.scrollBar} style={{ height: barHeight + '%' }}></div>
            <div className={styles.scrollSlider} style={{ height: sliderHeight + '%' }}></div>
        </div>
    );
};

export default Scroll;
