import * as React from 'react';
import styles from './Auth.module.scss';
import { useTranslation } from 'react-i18next';
import { BUTTON_SIZE } from '~/const';
import Button from '~/components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import { useDocumentTitle } from 'usehooks-ts';

const ResetPasswordSuccess = () => {
    useDocumentTitle('Восстановление пароля');
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={styles.authContent}>
            <h2>{t('Вам отправили новый пароль')}</h2>
            <p>{t('Пожалуйста, проверьте Ваш email адрес')}</p>
            <Button size={BUTTON_SIZE.BIG} onClick={() => navigate(urlsMap.index)}>
                {t('К авторизации')}
            </Button>
        </div>
    );
};

export default ResetPasswordSuccess;
