import { DATE_FORMAT, DATE_FORMAT_HHMM, ORDER_BY, TASK_FIELDS, TASK_TYPES } from '~/const';
import { prepareDateToLocal } from '~/utils/utils';
import { addTasks } from '~/store/actions/actionTasks';
import { Dispatch } from 'react';
import { addUsers } from '~/store/actions/actionUsers';
import { addProjects } from '~/store/actions/actionProjects';
import { _updateEntity } from '~/store/actions/actionEntities';
import { ENTITIES } from '~/store/reducers/reducerEntities';

export const tableConfig = [
    { name: TASK_FIELDS.TITLE, title: 'Заголовок', orderby: true, visible: true, disabled: true },
    { name: TASK_FIELDS.TYPE, title: 'Тип', orderby: false, visible: true },
    { name: TASK_FIELDS.PRIORITY, title: 'Приоритет', orderby: true, visible: true },
    { name: TASK_FIELDS.DIFFICULTY, title: 'Осталось времени', orderby: false, visible: true },
    { name: TASK_FIELDS.STATUS, title: 'Статус', orderby: false, visible: true },
    { name: TASK_FIELDS.WDATE, title: 'Запланировано', orderby: true, visible: true },
    { name: TASK_FIELDS.EDATE, title: 'Дата сдачи', orderby: true, visible: true },
    { name: TASK_FIELDS.CREATED_AT, title: 'Дата создания', orderby: true, visible: false },
    { name: TASK_FIELDS.UPDATED_AT, title: 'Дата изменения', orderby: true, visible: false },
];

export const TASKS_FILTERS = {
    STATUS: 'status',
    STATUS_ID: 'statusId',
    SEARCH: 'search',
    CREATOR: 'creator',
    EXECUTIVE: 'executive',
    PID: 'pid',
    WDATE: 'wdate',
    TAG: 'tag',
    EDATE: 'edate',
    TYPE: 'type',
};

export const defaultOptions = {
    filters: {
        [TASKS_FILTERS.STATUS]: 'my',
        [TASKS_FILTERS.SEARCH]: '',
        [TASKS_FILTERS.STATUS_ID]: [],
        [TASKS_FILTERS.CREATOR]: [],
        [TASKS_FILTERS.EXECUTIVE]: [],
        [TASKS_FILTERS.PID]: [],
        [TASKS_FILTERS.WDATE]: [],
        [TASKS_FILTERS.TAG]: [],
    },
    orderby: ['priority', ORDER_BY.DESC],
    filterName: '',
};

export const labelColors = [
    'F2D604',
    'DB995B',
    'D56657',
    'BD77D7',
    '2C84B8',
    '00C2E0',
    '50E898',
    'FF78CC',
    '355263',
    'CBD557',
    '8DDEE3',
];

export const prepareTaskFromServer = (task: any, dispatch: Dispatch<any>) => {
    const itAMeet = task.type === TASK_TYPES.MEETS;
    const timeFormat = itAMeet ? DATE_FORMAT_HHMM : DATE_FORMAT;
    task.wdate = prepareDateToLocal(task.wdate, timeFormat, itAMeet);
    task.edate = prepareDateToLocal(task.edate, timeFormat, itAMeet);
    task.created_at = prepareDateToLocal(task.cdate, timeFormat, itAMeet);
    task.updated_at = prepareDateToLocal(task.updated_at, timeFormat, itAMeet);

    if (!!task?.assignees?.length) {
        const assignees = [...task.assignees];
        task.assignees = {};
        assignees.forEach((assignee: any) => {
            dispatch(addUsers([assignee.user]));
            delete assignee['user'];
            task.assignees[assignee.user_id] = assignee;
        });
    }

    if (!!task?.observers?.length) {
        task.observers = task.observers.map((observer: IUser) => {
            dispatch(addUsers([observer]));
            return observer.id;
        });
    }

    if (task?.children) {
        dispatch(addTasks(task.children));
        task.children = task.children?.map((_task: ITask) => _task.id);
    }

    if (task?.sprint) {
        dispatch(_updateEntity(ENTITIES.SPRINTS, task.sprint, true));
        task.sprint = task.sprint.id;
    }

    task?.creator && dispatch(addUsers([task.creator]));
    task.creator = task?.creator?.id || task?.creator;

    task?.executive && dispatch(addUsers([task.executive]));
    task.executive = task?.executive?.id || task?.executive;

    task?.parent && dispatch(addTasks([task.parent]));
    task.parent = task?.parent?.id || task?.parent;

    task?.project && dispatch(addProjects([task.project]));
    task.project = task?.project?.id || task?.project || task?.pid;

    return task;
};
