import * as React from 'react';
import styles from './Repeat.module.scss';
import Input from '~/components/Form/Input/Input';
import {
    DATE_FORMAT,
    INPUT_SIZE,
    INPUT_TYPE,
    REPEAT_INTERVAL_TYPE,
    REPEAT_INTERVAL,
    BUTTON_SIZE,
    BUTTON_COLOR,
    DATE_FORMAT_HHMM,
    SERVER_DATE_FORMAT,
    TOAST,
} from '~/const';
import Select from '~/components/Form/Select/Select';
import Checkbox from '~/components/Form/Checkbox/Checkbox';
import DatePicker from '~/components/Form/DatePicker/DatePicker';
import moment from 'moment';
import { State } from '~/store/reducers';
import { useSelector, useDispatch } from 'react-redux';
import equal from 'fast-deep-equal/react';
import Button from '~/components/Button/Button';
import { apiTaskRecurringCreate, apiTaskRecurringUpdate } from '~/api/task';
import { _updateTask } from '~/store/actions/actionTasks';
import { changeVisiblePopup, addToast } from '~/store/actions/actionApp';

interface IStateSelector {
    tasks: ITaskEntities;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        tasks: state.reducerTasks.tasks,
    };
};

interface IFC {
    taskId: number;
}

const weekdayArray: { name: string; value: number }[] = [
    { name: 'Пн', value: 1 },
    { name: 'Вт', value: 2 },
    { name: 'Ср', value: 3 },
    { name: 'Чт', value: 4 },
    { name: 'Пт', value: 5 },
    { name: 'Сб', value: 6 },
    { name: 'Вс', value: 0 },
];

const RepeatTaskPopup = ({ taskId }: IFC) => {
    const dispatch = useDispatch();
    const timeZone = moment().utcOffset() / 60 - 3;
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const task = state.tasks[taskId];
    const [loading, setLoading] = React.useState<boolean>(false);
    const [intervalSize, setIntervalSize] = React.useState<number>(
        task?.recuring_task?.interval_size || 1,
    );
    const [intervalPeriod, setIntervalPeriod] = React.useState<REPEAT_INTERVAL>(
        task?.recuring_task?.interval_period || REPEAT_INTERVAL_TYPE.day,
    );
    const [weekday, setWeekday] = React.useState<number[]>(task?.recuring_task?.weekday || []);
    const [endDate, setEndDate] = React.useState<string>(
        task?.recuring_task?.end_date
            ? moment(task?.recuring_task?.end_date)
                  .subtract(timeZone, 'hours')
                  .format(DATE_FORMAT_HHMM)
            : moment().format(DATE_FORMAT),
    );
    const [endAfter, setEndAfter] = React.useState<number>(task?.recuring_task?.end_after || 1);
    const [radio, setRadio] = React.useState<string>('never');

    const handleCreateRepeat = () => {
        const data: { [name: string]: any } = {
            interval_size: intervalSize,
            interval_period: intervalPeriod,
        };

        if (REPEAT_INTERVAL_TYPE.week === intervalPeriod) {
            data.weekday = weekday;
        } else {
            data.weekday = null;
        }

        if (radio === 'date') {
            data.end_date = moment(endDate, DATE_FORMAT_HHMM).format(SERVER_DATE_FORMAT);
        } else {
            data.end_date = null;
        }

        if (radio === 'after') {
            data.end_after = endAfter;
        } else {
            data.end_after = null;
        }

        setLoading(true);

        if (task.recuring_task) {
            apiTaskRecurringUpdate(taskId, data)
                .then(async (res) => {
                    dispatch(changeVisiblePopup(null));
                    dispatch(
                        addToast({
                            type: TOAST.SUCCESS,
                            title: 'Периодичность сохранена',
                            timer: 2500,
                        }),
                    );
                    dispatch(
                        _updateTask({ task: { recuring_task: res.task.recuring_task } }, taskId),
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            apiTaskRecurringCreate(taskId, data)
                .then(async (res) => {
                    dispatch(changeVisiblePopup(null));
                    dispatch(
                        addToast({
                            type: TOAST.SUCCESS,
                            title: 'Периодичность сохранена',
                            timer: 2500,
                        }),
                    );
                    dispatch(
                        _updateTask({ task: { recuring_task: res.task.recuring_task } }, taskId),
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.block}>
                <div className={styles.blockTitle}>Повторять с интервалом</div>
                <div className={styles.interval}>
                    <Input
                        containerClassName={styles.inputNumber}
                        withoutFormik={true}
                        type={INPUT_TYPE.NUMBER}
                        size={INPUT_SIZE.SMALL}
                        changeHandler={(newValue: string) => setIntervalSize(Number(newValue))}
                        defaultValue={String(intervalSize)}
                    />
                    <Select
                        className={styles.select}
                        options={REPEAT_INTERVAL}
                        defaultValue={intervalPeriod}
                        withoutBorder={true}
                        onChange={(value: ISelectValue) =>
                            setIntervalPeriod(value!.value as REPEAT_INTERVAL)
                        }
                    />
                </div>
            </div>
            {intervalPeriod === REPEAT_INTERVAL_TYPE.week && (
                <div className={styles.block}>
                    <div className={styles.blockTitle}>Дни повторения</div>
                    <div className={styles.switches}>
                        {weekdayArray.map((item) => (
                            <div
                                key={item.value}
                                className={weekday.includes(item.value) ? styles.active : ''}
                                onClick={() => {
                                    setWeekday((prevState) => {
                                        let state = [...prevState];

                                        if (state.includes(item.value)) {
                                            state = state.filter((itemS) => itemS !== item.value);
                                        } else {
                                            state.push(item.value);
                                        }

                                        return state;
                                    });
                                }}
                            >
                                {item.name}
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div className={styles.block}>
                <div className={styles.blockTitle}>Окончание</div>
                <div className={styles.radioBlock}>
                    <Checkbox
                        label={'Никогда'}
                        checked={radio === 'never'}
                        changeHandler={(value) => {
                            if (value) {
                                setRadio('never');
                            }
                        }}
                    />
                </div>
                <div className={styles.radioBlock}>
                    <Checkbox
                        label={'Дата'}
                        checked={radio === 'date'}
                        changeHandler={(value) => {
                            if (value) {
                                setRadio('date');
                            }
                        }}
                    />
                    <DatePicker
                        className={styles.datePicker}
                        handleChange={(date) => {
                            setEndDate(date);
                        }}
                        startDate={endDate}
                        disabled={radio !== 'date'}
                    />
                </div>
                <div className={styles.radioBlock}>
                    <Checkbox
                        label={'После'}
                        checked={radio === 'after'}
                        changeHandler={(value) => {
                            if (value) {
                                setRadio('after');
                            }
                        }}
                    />
                    <Input
                        className={styles.inputNumber}
                        withoutFormik={true}
                        type={INPUT_TYPE.NUMBER}
                        size={INPUT_SIZE.SMALL}
                        defaultValue={String(endAfter)}
                        disabled={radio !== 'after'}
                        changeHandler={(newValue: string) => setEndAfter(Number(newValue))}
                    />
                    повторов
                </div>
            </div>
            <div className={styles.block}>
                <Button
                    className={styles.button}
                    size={BUTTON_SIZE.SMALL}
                    color={BUTTON_COLOR.BLUE}
                    onClick={handleCreateRepeat}
                    loading={loading}
                >
                    Сохранить
                </Button>
            </div>
        </div>
    );
};

export default RepeatTaskPopup;
