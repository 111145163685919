import * as React from 'react';
import styles from '~/containers/Groups/Invites/Invites.module.scss';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, TOAST } from '~/const';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store/reducers';
import equal from 'fast-deep-equal/react';
import { addToast, changeVisiblePopup } from '~/store/actions/actionApp';
import CreatableSelectField from '~/components/Form/Select/CreatableSelect';
import { apiProjectInvite } from '~/api/project';
import { _updateProject } from '~/store/actions/actionProjects';

interface IStateSelector {
    dictionaries: IDictionaries;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        dictionaries: state.reducerApp.dictionaries,
    };
};

interface IFC {
    project: IProject;
}

const ProjectInvite = ({ project }: IFC) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const [values, setValues] = React.useState<ISelectOption[]>([]);
    const usersGroup = { ...state.dictionaries['users'] };

    const prepareUsersId = Object.keys(usersGroup).filter((id) => {
        return (
            !project.users.includes(Number(id)) &&
            !project.relations?.find((relation) => relation.user_id === Number(id))
        );
    });
    const [loading, setLoading] = React.useState(false);

    const closeHandler = () => {
        dispatch(changeVisiblePopup(null));
    };

    const inviteHandler = () => {
        setLoading(true);
        const data = { invited: values.map((value) => value.value) };
        apiProjectInvite(data, project.id)
            .then(async (res) => {
                dispatch(
                    _updateProject(
                        {
                            project: {
                                relations: [...(project.relations || []), ...res.relations],
                            },
                        },
                        project.id,
                    ),
                );

                closeHandler();
            })
            .catch((error) => {
                dispatch(
                    addToast({
                        type: TOAST.ERROR,
                        title: error,
                        timer: 3000,
                    }),
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChange = async (data: any) => {
        setValues(data);
    };

    const handleCreate = async (newValue: string) => {
        const array = [...values];
        array.push({ label: newValue, value: newValue });
        setValues(array);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                Приглашение пользователей
                <br />
                {`${!!project.title && 'в проект ' + project.title}`}
            </div>
            <CreatableSelectField
                label={'Добавить сотрудников'}
                placeholder={'Введите имя или email'}
                formatCreateLabel={'Пригласить'}
                classNameWrapper={styles.selectWrapper}
                defaultValue={values}
                options={prepareUsersId.map((id) => {
                    return { value: id, label: usersGroup[id] };
                })}
                handleCreate={handleCreate}
                handleChange={handleChange}
            />
            <div className={styles.buttons}>
                <Button color={BUTTON_COLOR.GRAY} onClick={closeHandler}>
                    Отмена
                </Button>
                <Button color={BUTTON_COLOR.BLUE} onClick={inviteHandler} loading={loading}>
                    Пригласить
                </Button>
            </div>
        </div>
    );
};

export default ProjectInvite;
