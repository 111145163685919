import * as React from 'react';
import styles from './Line.module.scss';
import { deleteMascot } from '~/utils/utils';

const LineLoader = () => {
    React.useEffect(() => {
        return () => {
            deleteMascot();
        };
    }, []);

    return <div className={styles.wrapper} />;
};

export default LineLoader;
