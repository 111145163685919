import * as React from 'react';
import styles from './Toasts.module.scss';
import { useSelector } from 'react-redux';
import { State } from '~/store/reducers';
import equal from 'fast-deep-equal/react';
import Toast from '~/components/Toast/Toast';

interface IStateSelector {
    toasts: IToast[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        toasts: state.reducerApp.toasts,
    };
};

const Toasts = () => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const ids = state.toasts.filter((toast) => toast.visible).map((toast) => toast.id);

    return (
        <div className={styles.wrapper}>
            {ids.map((id) => <Toast key={id} id={id} />).reverse()}
        </div>
    );
};

export default Toasts;
