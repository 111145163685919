import thunk from 'redux-thunk';
import reducers from '~/store/reducers';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { configureStore, Tuple } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';

const PRODUCTION_MODE = 'production';

const store = configureStore({
    reducer: reducers,
    // middleware: () => new Tuple(thunk, logger)
});

if (process.env.NODE_ENV !== PRODUCTION_MODE) {
    window.store = store;
}

export default store;

// TODO: FIX 'TS2345: Argument of type '(dispatch: Dispatch ) => Promise ' is not assignable to parameter of type 'AnyAction'.'

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
