import * as React from 'react';
import styles from './Avatar.module.scss';
import classNames from 'classnames';
import { AVATAR_TYPE } from '~/const';
import { getColor } from '~/utils/utils';

interface IAvatar {
    avatar?: {
        avatar?: string;
        name?: string;
        fillPercent?: number;
        lvl?: number;
    };
    type?: string;
    withPercent?: boolean;
    className?: string;
    attention?: boolean;
}

const Avatar = ({ avatar, type, withPercent, className, attention }: IAvatar) => {
    const userNoPhoto = (name: string) => {
        return name
            .split(' ')
            .map((item: string) => {
                return item[0];
            })
            .join('');
    };

    const styleAvatar = { '--fillPercent': avatar?.fillPercent };

    return (
        <div
            className={classNames(
                styles.avatar,
                styles[`lvl${avatar?.lvl}`],
                getColor(avatar?.name || ''),
                className,
                {
                    [styles.userBig]: type === AVATAR_TYPE.USER_BIG,
                    [styles.userSmall]: type === AVATAR_TYPE.USER_SMALL,
                    [styles.userProfile]: type === AVATAR_TYPE.USER_PROFILE,
                    [styles.projectSmall]: type === AVATAR_TYPE.PROJECT_SMALL,
                    [styles.attention]: attention,
                },
            )}
            // @ts-ignore
            style={withPercent ? styleAvatar : {}}
        >
            {userNoPhoto(avatar?.name || '')}
            {avatar?.avatar && <img src={avatar?.avatar} alt={avatar?.name} />}
        </div>
    );
};

export default Avatar;
