import store from '~/store';
import { addToast } from '~/store/actions/actionApp';
import { TOAST } from '~/const';
import { appHistory } from '~/routes/history';
import { urlsMap } from '~/utils/urls';
import { apiRefreshToken } from '~/api/user';
import { setTokens } from '~/utils/storage';
import { accountLogout } from '~/store/actions/actionAccount';
import AbortController from 'abort-controller';

export const get = async (url = '', silentError?: boolean) => {
    return await makeRequest('GET', url, undefined, silentError, undefined);
};

export const _delete = async (url = '') => {
    return await makeRequest('DELETE', url);
};

export const post = async (url = '', data?: any, silentError?: boolean, formData?: boolean) => {
    return await makeRequest(
        'POST',
        url,
        typeof data === 'object' &&
            !Array.isArray(data) &&
            data !== null &&
            !(data instanceof FormData)
            ? { ...data, lang: 'ru' }
            : data,
        silentError,
        formData,
    );
};

export const put = async (url = '', data?: any, formData?: boolean) => {
    return await makeRequest('PUT', url, data, undefined, formData);
};

const host = process.env.REACT_APP_API_HOST;

const requestQueue: { [value: string]: AbortController }[] = [];

export const makeRequest = async (
    method: string,
    url: string,
    data?: any,
    silentError?: boolean,
    formData?: boolean,
) => {
    if (!navigator.onLine) {
        store.dispatch(
            addToast({
                type: TOAST.ERROR,
                title: 'Отсутствует подключение к интернету',
            }),
        );
        return;
    }

    const urlPresent = (name: string) => url.includes(name);
    const controller = new AbortController();
    const queueKey = method + url;

    requestQueue.map((queue, index) => {
        const key = Object.keys(queue)[0];
        if (key === queueKey) {
            queue[key].abort();
            requestQueue.splice(index, 1);
        }
    });

    const request = async () => {
        requestQueue.push({ [queueKey]: controller });
        return await fetch(host + url, {
            method,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                ...(!formData && { 'Content-Type': 'application/json' }),
            },
            body: formData ? data : JSON.stringify(data),
            // TODO: сейчас абортятся нужные запросы, необходимо доработать
            // signal: controller.signal,
        });
    };

    let response = await request();

    if (response.status === 401 && !urlPresent('auth/refresh')) {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
            await apiRefreshToken(refreshToken)
                .then(async (res) => {
                    setTokens(res.accessToken, res.refreshToken);
                    response = await request();
                })
                .catch(() => {
                    // @ts-ignore
                    store.dispatch(accountLogout());
                });
        }
    }

    let ok;
    let responseData;

    try {
        ok = response.ok;
        if (response.statusText !== 'No Content' && response.status !== 204) {
            responseData = await response.json();
        }
    } catch (err) {
        ok = false;
        responseData = { status: 'error' };
    } finally {
        const queueIndex = requestQueue.findIndex((queue) => Object.keys(queue)[0] === queueKey);
        requestQueue.splice(queueIndex, 1);
    }

    if (!ok) {
        const message = responseData.msg;
        const code = response.status;

        switch (code) {
            case 401:
                if (!urlPresent('auth/login')) {
                    store.dispatch(
                        addToast({
                            type: TOAST.ERROR,
                            title: 'Вы не авторизованы',
                        }),
                    );
                    return;
                }
                break;
            case 403:
                if (method === 'GET' && !urlPresent('groups/')) {
                    appHistory.push(urlsMap.index);
                }
                break;
            case 404:
                if (method === 'GET' && !urlPresent('groups/')) {
                    appHistory.push(urlsMap.index);
                }
                break;
            default:
                break;
        }

        if (typeof message === 'object' && message !== null) {
            Object.keys(message).forEach((err: string) => {
                if (!silentError) {
                    store.dispatch(
                        addToast({
                            type: TOAST.ERROR,
                            title:
                                message[err][0] ||
                                (code === 500
                                    ? 'Ошибка на сервере, попробуйте позже'
                                    : 'Произошла какая-то ошибка'),
                        }),
                    );
                }

                throw message[err][0];
            });
        }

        if (!silentError) {
            store.dispatch(
                addToast({
                    type: TOAST.ERROR,
                    title:
                        responseData.msg ||
                        (code === 500
                            ? 'Ошибка на сервере, попробуйте позже'
                            : 'Произошла какая-то ошибка'),
                }),
            );
        }

        throw responseData.msg;
    } else {
        return responseData;
    }
};
