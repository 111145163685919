import * as React from 'react';
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';
import styles from '~/components/Form/Select/Select.module.scss';
import { customStyles } from '~/components/Form/Select/style';

const components = {
    DropdownIndicator: null,
};

interface Option {
    readonly label: string;
    readonly value: string;
}

const createOption = (label: string) => ({
    label,
    value: label,
});

interface IFC {
    label?: string;
    className?: string;
    classNameWrapper?: string;
    changeHandler?: (value: string[]) => void;
}

const CreatableInputField = ({ label, className, classNameWrapper, changeHandler }: IFC) => {
    const [inputValue, setInputValue] = React.useState<string>('');
    const [value, setValue] = React.useState<Option[]>([]);
    const [useCTRL, setUseCTRL] = React.useState<boolean>(false);

    const preparedValues = (inputValue: string): Option[] => {
        const values = [...value, createOption(inputValue)];

        return Array.from(new Map(values.map((item) => [item['value'], item])).values());
    };

    React.useEffect(() => {
        if (useCTRL && inputValue) {
            setValue(preparedValues(inputValue));
            setInputValue('');
            setUseCTRL(false);
        }
    }, [useCTRL, inputValue]);

    const handleKeyDown: React.KeyboardEventHandler = (event) => {
        switch (event.keyCode) {
            case 86:
                if (event.ctrlKey || event.metaKey) {
                    setUseCTRL(true);
                }
                break;
            case 32:
            case 13:
            case 9:
                if (!inputValue) return;
                setValue(preparedValues(inputValue));
                setInputValue('');
                event.preventDefault();
        }
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (!inputValue) return;
        setValue(preparedValues(inputValue));
        setInputValue('');
        event.preventDefault();
    };

    React.useEffect(() => {
        changeHandler && changeHandler(value.map((v) => v.value));
    }, [value]);

    return (
        <div className={classNames(styles.selectContainer, classNameWrapper)}>
            <div className={styles.selectTop}>
                {label && <label className={styles.selectLabel}>{label}</label>}
            </div>
            <CreatableSelect
                // @ts-ignore
                styles={customStyles()}
                className={className}
                components={components}
                inputValue={inputValue}
                // @ts-ignore
                isMulti
                menuIsOpen={false}
                // @ts-ignore
                onChange={(newValue) => setValue(newValue)}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                placeholder=""
                value={value}
                isClearable={false}
            />
        </div>
    );
};

export default CreatableInputField;
