import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './translations/en.json';
import ru from './translations/ru.json';

const resources = {
    en: {
        translation: en,
    },
    ru: {
        translation: ru,
    },
};

const fallbackLng = ['en'];

i18next.use(LanguageDetector).use(initReactI18next).init({
    resources,
    fallbackLng,
});
