import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGemoji from 'remark-gemoji';
import rehypeRaw from 'rehype-raw';
import { Link } from 'react-router-dom';
import 'github-markdown-css/github-markdown-light.css';
import remarkGfm from 'remark-gfm';
import remarkMentions from '~/utils/remark_mentions';

interface IFC {
    content?: string;
    changeChecked?: (checked: boolean, item: number) => void;
    users?: IUserEntities;
}

const MarkdownReader = ({ content, changeChecked, users }: IFC) => {
    let n = 1;

    const plugins = [remarkGemoji, () => remarkMentions({ users })];

    if (content) {
        const longestWord = content
            .split(' ')
            .reduce((a, b) => (b.length > a.length ? b : a)).length;
        if (longestWord < 1000) plugins.push(remarkGfm);
    }

    return (
        <div className="markdown-body">
            <ReactMarkdown
                className={'markdown'}
                remarkPlugins={plugins}
                rehypePlugins={[rehypeRaw]}
                components={{
                    input: ({ ...props }) => {
                        const item = n++;
                        return (
                            <input
                                {...props}
                                disabled={false}
                                onChange={(event) => {
                                    changeChecked && changeChecked(event.target.checked, item);
                                }}
                            />
                        );
                    },
                    a: ({ ...props }) => {
                        const href = props.href;
                        if (!href?.includes('http')) {
                            return (
                                <Link to={`../${href}` || '#'} relative={'path'}>
                                    {props.children}
                                </Link>
                            );
                        }
                        return <a {...props} target="_blank" />;
                    },
                }}
            >
                {content || ''}
            </ReactMarkdown>
        </div>
    );
};

export default MarkdownReader;
