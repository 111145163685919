import * as React from 'react';
import styles from './Groups.module.scss';
import classNames from 'classnames';
import Form from '~/containers/Groups/Form';
import { apiCreateGroup } from '~/api/user';
import { useDispatch } from 'react-redux';
import { addToast, changeVisiblePopup, updateLoaders, updateMeta } from '~/store/actions/actionApp';
import { LOADERS, LOADERS_TYPE, META, TOAST } from '~/const';
import { _updateGroup } from '~/store/actions/actionAccount';
import { deleteMascot } from '~/utils/utils';

const CreateGroup = () => {
    const dispatch = useDispatch();

    const handleCreate = async (title: string) => {
        dispatch(
            updateLoaders({
                [LOADERS.GROUP]: {
                    [LOADERS_TYPE.LOADING]: true,
                },
            }),
        );
        await apiCreateGroup({ title })
            .then((res) => {
                dispatch(_updateGroup({ ...res.group, group_role_id: 1 }, true));
                // @ts-ignore
                dispatch(updateMeta(META.CURRENT_GROUP, res.group.id));
                dispatch(changeVisiblePopup(null));
                dispatch(
                    addToast({
                        type: TOAST.SUCCESS,
                        title: `Группа ${title} создана`,
                        timer: 3000,
                    }),
                );
            })
            .finally(() =>
                dispatch(
                    updateLoaders({
                        [LOADERS.GROUP]: {
                            [LOADERS_TYPE.LOADING]: false,
                        },
                    }),
                ),
            );
    };

    React.useEffect(() => {
        deleteMascot();
    }, []);

    return (
        <div className={styles.content}>
            <div className={classNames('contentHeader', styles.contentHeader)}>
                <h2>Создание группы</h2>
                <p>Создайте свою бесплатную группу{<br />}для персонального пользования.</p>
                <p>
                    В дальнейшем вы сможете улучшить группу{<br />}и пригласить больше сотрудников.
                </p>
            </div>
            <Form handleCreate={handleCreate} />
        </div>
    );
};

export default CreateGroup;
