import { createSlice } from '@reduxjs/toolkit';

export interface IAppState {
    notificationVisible: boolean;
    visibleGallerySlide: string;
    galleryShown: boolean;
    galleryFiles: IFile[];
}

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        notificationVisible: false,
        visibleGallerySlide: '',
        galleryShown: false,
        galleryFiles: [],
    },
    reducers: {
        changeVisibleNotification(state, action) {
            state.notificationVisible = action.payload;
        },
        changeVisibleGallerySlide(state, action) {
            state.visibleGallerySlide = action.payload;
        },
        toggleGallery(state, action) {
            state.galleryShown = action.payload;
        },
        updateGalleryFiles(state, action) {
            state.galleryFiles = action.payload;
        },
    },
    selectors: {
        selectNotificationVisible: (sliceState) => sliceState.notificationVisible,
        selectVisibleGallerySlide: (sliceState) => sliceState.visibleGallerySlide,
        selectGalleryShown: (sliceState) => sliceState.galleryShown,
        selectGalleryFiles: (sliceState) => sliceState.galleryFiles,
    },
});

export type appSlice = {
    [appSlice.name]: ReturnType<typeof appSlice['reducer']>;
};

export const {
    selectNotificationVisible,
    selectVisibleGallerySlide,
    selectGalleryShown,
    selectGalleryFiles,
} = appSlice.selectors;

export const {
    changeVisibleNotification,
    changeVisibleGallerySlide,
    toggleGallery,
    updateGalleryFiles,
} = appSlice.actions;
