import * as React from 'react';
import styles from './Priority.module.scss';
import classNames from 'classnames';
import Skeleton, { SKELETON } from '~/components/Skeleton/Skeleton';

interface IPriority {
    priority?: number | string | null;
    className?: string;
    withZero?: boolean;
}

const Priority = ({ priority, className, withZero }: IPriority) => {
    if (priority === undefined) return <Skeleton type={SKELETON.PRIORITY} />;

    if (!withZero && priority === null) {
        return null;
    }

    const element = (
        <div className={classNames(styles.priority, `priority${priority || 0}`, className)}></div>
    );

    return element;
};

export default Priority;
