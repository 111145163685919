import reducerAccount, { accountState } from '~/store/reducers/reducerAccount';
import reducerTasks, { tasksState } from '~/store/reducers/reducerTasks';
import reducerProjects, { projectsState } from '~/store/reducers/reducerProjects';
import reducerUsers, { usersState } from '~/store/reducers/reducerUsers';
import reducerApp, { appState } from '~/store/reducers/reducerApp';
import reducerEntities, { entitiesState } from '~/store/reducers/reducerEntities';
import { combineReducers } from 'redux';
import { notificationsSlice, INotificationState } from '~/store/slices/notification/slice';
import { appSlice, IAppState } from '~/store/slices/app/slice';

const reducers = {
    reducerAccount,
    reducerTasks,
    reducerProjects,
    reducerUsers,
    reducerApp,
    reducerEntities,
    [notificationsSlice.name]: notificationsSlice.reducer,
    [appSlice.name]: appSlice.reducer,
};

export interface State {
    reducerAccount: accountState;
    reducerTasks: tasksState;
    reducerProjects: projectsState;
    reducerUsers: usersState;
    reducerApp: appState;
    reducerEntities: entitiesState;
    [notificationsSlice.name]: INotificationState;
    [appSlice.name]: IAppState;
}

export default combineReducers(reducers);
