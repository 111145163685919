import * as React from 'react';
import styles from './TaskType.module.scss';
import classNames from 'classnames';
import Tooltip from '~/components/Tooltip/Tooltip';
import { State } from '~/store/reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';

interface IStateSelector {
    dictionaries: IDictionaries;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        dictionaries: state.reducerApp.dictionaries,
    };
};

interface IFC {
    type?: number | string;
    className?: string;
    withoutTooltip?: boolean;
}

const TaskType = ({ type, className, withoutTooltip }: IFC) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);

    const element = <div className={classNames(styles.type, `type${type}`, className)}></div>;

    if (!withoutTooltip) {
        return (
            <Tooltip text={type ? state.dictionaries.taskTypes?.[type] : 'Без типа'}>
                {element}
            </Tooltip>
        );
    }

    return element;
};

export default TaskType;
