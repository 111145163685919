import { Actions } from '~/store/actions';
import { defaultOptions } from '~/utils/projects';
import { defaultOptions as tasksDefaultOptions, TASKS_FILTERS } from '~/utils/tasks';
import {
    ADD_PROJECTS,
    UPDATE_PROJECT,
    UPDATE_PROJECT_LIST,
    UPDATE_PROJECT_LIST_OPTIONS,
    UPDATE_TASK_PROJECT_LIST,
    UPDATE_TASK_PROJECT_LIST_OPTIONS,
    UPDATE_BACKLOG_IDS,
    UPDATE_SPRINT_IDS,
    ADD_SPRINT,
    UPDATE_WIKI_PAGE,
} from '~/store/actions/actionProjects';
import { CLEAR_STATE } from '~/store/actions/actionApp';
import { UPDATE_PROJECT_PROCESSES_OPTIONS } from '~/store/actions/actionProjects';

export interface projectsState {
    projects: IProjectEntities | null;
    projectList: {
        ids: number[];
        totalCount: number;
        options: IMeta;
    };
    taskProjectList: ITaskProjectList;
    sprint: ICurrentSprint;
    sprints: ISprintEntities;
}

export const initialState: projectsState = {
    projects: null,
    projectList: {
        ids: [],
        totalCount: 0,
        options: defaultOptions,
    },
    taskProjectList: {
        ids: [],
        totalCount: 0,
        options: {
            ...tasksDefaultOptions,
            filters: {
                ...tasksDefaultOptions.filters,
                [TASKS_FILTERS.STATUS]: 'open',
            },
        },
        pid: null,
    },
    sprint: {
        pid: null,
        backlogIds: [],
        totalCount: 0,
        sid: null,
        sprintIds: [],
    },
    sprints: {},
};

const reducerProjects = (state: projectsState = initialState, action: Actions) => {
    switch (action.type) {
        case CLEAR_STATE:
            return initialState;

        case ADD_PROJECTS:
            const preparedProjects: { [value: string]: IProject } = {};
            action.projects.forEach((project) => {
                preparedProjects[project.id] = { ...state.projects?.[project.id], ...project };
            });

            return {
                ...state,
                projects: action.withClearState
                    ? preparedProjects
                    : { ...state.projects, ...preparedProjects },
            };

        case UPDATE_PROJECT:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    [action.id]: {
                        ...state.projects?.[action.id],
                        ...action.data.project,
                    },
                },
            };

        case UPDATE_PROJECT_LIST:
            return {
                ...state,
                projectList: {
                    ...state.projectList,
                    ids: action.additional
                        ? [...state.projectList.ids, ...action.ids]
                        : [...action.ids],
                    totalCount: action.totalCount ?? state.projectList.totalCount,
                },
            };

        case UPDATE_PROJECT_LIST_OPTIONS:
            return {
                ...state,
                projectList: {
                    ...state.projectList,
                    options: {
                        ...state.projectList.options,
                        ...action.options,
                    },
                },
            };

        case UPDATE_TASK_PROJECT_LIST:
            return {
                ...state,
                taskProjectList: {
                    ...state.taskProjectList,
                    ids: action.additional
                        ? [...state.taskProjectList.ids, ...action.ids]
                        : [...action.ids],
                    totalCount: action.totalCount ?? state.taskProjectList.totalCount,
                    pid: action.pid ?? state.taskProjectList.pid,
                },
            };

        case UPDATE_TASK_PROJECT_LIST_OPTIONS:
            return {
                ...state,
                taskProjectList: {
                    ...state.taskProjectList,
                    options: {
                        ...state.taskProjectList.options,
                        ...action.options,
                    },
                },
            };

        case UPDATE_BACKLOG_IDS:
            return {
                ...state,
                sprint: {
                    ...state.sprint,
                    backlogIds: action.additional
                        ? [...state.sprint.backlogIds, ...action.ids]
                        : [...action.ids],
                    totalCount: action.totalCount,
                    pid: action.pid,
                },
            };

        case UPDATE_SPRINT_IDS:
            return {
                ...state,
                sprint: {
                    ...state.sprint,
                    sprintIds: [...action.ids],
                    sid: action.sid,
                },
            };

        case ADD_SPRINT:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    [action.pid]: {
                        ...state.projects?.[action.pid],
                        sprints: [...(state.projects?.[action.pid].sprints || []), action.sprint],
                    },
                },
            };

        case UPDATE_WIKI_PAGE:
            if (action.add) {
                return {
                    ...state,
                    projects: {
                        ...state.projects,
                        [action.pid]: {
                            ...state.projects?.[action.pid],
                            wiki: [...(state.projects?.[action.pid].wiki || []), action.data],
                        },
                    },
                };
            }

            if (action.remove) {
                return {
                    ...state,
                    projects: {
                        ...state.projects,
                        [action.pid]: {
                            ...state.projects?.[action.pid],
                            wiki: state.projects?.[action.pid].wiki.filter(
                                (page) => page.id !== action.wid,
                            ),
                        },
                    },
                };
            }

            return {
                ...state,
                projects: {
                    ...state.projects,
                    [action.pid]: {
                        ...state.projects?.[action.pid],
                        wiki: state.projects?.[action.pid].wiki.map((wiki) =>
                            wiki.id === action.wid
                                ? {
                                      ...wiki,
                                      ...action.data.page,
                                  }
                                : wiki,
                        ),
                    },
                },
            };

        case UPDATE_PROJECT_PROCESSES_OPTIONS:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    [action.id]: {
                        ...state.projects?.[action.id],
                        options: action.options,
                    },
                },
            };

        default:
            return { ...state };
    }
};

export default reducerProjects;
