import * as React from 'react';

interface IFC {
    text: string;
    className?: string;
}

const PrepareText = ({ text, className }: IFC) => {
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const regExp = /(https?:\/\/|ftps?:\/\/|www\.)((?![.,?!;:()]*(\s|$))[^\s]){2,}/gim;
        const element = ref.current;

        if (!element) return;

        element.innerHTML = element.innerHTML.replace(
            regExp,
            (url: string) => '<a target="_blank" href="' + url + '">' + url + '</a>',
        );
    }, []);

    return (
        <p ref={ref} className={className}>
            {text}
        </p>
    );
};

export default PrepareText;
