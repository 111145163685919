import {
    IUpdateLoaders,
    IAddTags,
    IUpdateTags,
    IAddBlob,
    IAddToast,
    IRemoveToast,
    IUpdateMeta,
    IClearState,
    IUpdateDictionaries,
    IUpdateFirstLoaded,
    IChangeVisibleNotification,
    IAddNotifications,
    IReadNotifications,
} from '~/store/actions/types/actionAppTypes';
import { Dispatch } from 'react';
import { apiUpdateAppMeta } from '~/api/user';
import { apiGetNotifications } from '~/api/app';

export const CLEAR_STATE = 'CLEAR_STATE';
export const _clearState = (): IClearState => {
    return {
        type: CLEAR_STATE,
    };
};

export const UPDATE_FIRST_LOADED = 'UPDATE_FIRST_LOADED';
export const _updateFirstLoaded = (loaded: boolean): IUpdateFirstLoaded => {
    return {
        type: UPDATE_FIRST_LOADED,
        loaded,
    };
};

export const UPDATE_META = 'UPDATE_META';
export const _updateMeta = (options: IAppMeta): IUpdateMeta => {
    return {
        type: UPDATE_META,
        options,
    };
};

export const UPDATE_DICTIONARIES = 'UPDATE_DICTIONARIES';
export const updateDictionaries = (dictionaries: IDictionaries): IUpdateDictionaries => {
    return {
        type: UPDATE_DICTIONARIES,
        dictionaries,
    };
};

export const ADD_TAGS = 'ADD_TAGS';
export const addTags = (tags: ITag[]): IAddTags => {
    return {
        type: ADD_TAGS,
        tags,
    };
};

export const UPDATE_TAGS = 'UPDATE_TAGS';
export const updateTags = (tags: ITag[]): IUpdateTags => {
    return {
        type: UPDATE_TAGS,
        tags,
    };
};

export const UPDATE_LOADERS = 'UPDATE_LOADERS';
export const updateLoaders = (loaders: ILoaders): IUpdateLoaders => {
    return {
        type: UPDATE_LOADERS,
        loaders,
    };
};

export const ADD_BLOB = 'ADD_BLOB';
export const addBlob = (blob: IBlob): IAddBlob => {
    return {
        type: ADD_BLOB,
        blob,
    };
};

export const ADD_TOAST = 'ADD_TOAST';
export const addToast = (toast: IToast): IAddToast => {
    return {
        type: ADD_TOAST,
        toast,
    };
};

export const REMOVE_TOAST = 'REMOVE_TOAST';
export const removeToast = (id: string): IRemoveToast => {
    return {
        type: REMOVE_TOAST,
        id,
    };
};

export const CHANGE_VISIBLE_POPUP = 'CHANGE_VISIBLE_POPUP';
export const changeVisiblePopup = (popup: IPopup | null) => {
    return {
        type: CHANGE_VISIBLE_POPUP,
        popup,
    };
};

export const CHANGE_VISIBLE_NOTIFICATION = 'CHANGE_VISIBLE_NOTIFICATION';
export const _changeVisibleNotification = (visible: boolean): IChangeVisibleNotification => {
    return {
        type: CHANGE_VISIBLE_NOTIFICATION,
        visible,
    };
};

export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const _addNotifications = (row: INotification[], totalCount?: number): IAddNotifications => {
    return {
        type: ADD_NOTIFICATIONS,
        row,
        totalCount,
    };
};

export const READ_NOTIFICATIONS = 'READ_NOTIFICATIONS';
export const _readNotifications = (
    ids?: number[],
    totalCount?: number,
    viewedCount?: number,
): IReadNotifications => {
    return {
        type: READ_NOTIFICATIONS,
        ids,
        totalCount,
        viewedCount,
    };
};

export const getNotifications = () => {
    return async (dispatch: Dispatch<IAddNotifications>): Promise<void> => {
        try {
            const response = await apiGetNotifications();
            dispatch(_addNotifications(response.rows, response.totalCount));
        } catch {}
    };
};

export const updateMeta = (key: string, options: IAppMeta, successFunc?: () => void) => {
    return async (dispatch: Dispatch<IUpdateMeta>): Promise<void> => {
        const data = { [key]: options };
        await apiUpdateAppMeta(key, data).then(() => {
            dispatch(_updateMeta(data));

            if (successFunc) {
                successFunc();
            }
        });
    };
};
