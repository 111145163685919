import { Actions } from '~/store/actions';
import {
    ADD_TASKS,
    UPDATE_TASK,
    UPDATE_TASK_LIST,
    UPDATE_TASK_LIST_OPTIONS,
    UPDATE_TASK_LIST_CONFIG,
    ADD_CALENDAR,
    UPDATE_CALENDAR_IDS,
    ADD_TASK_TIMING,
    UPDATE_TASKS_FILTER_NAME,
    ADD_TASK_LOGS,
    ADD_TASK_TIMINGS,
    UPDATE_TASK_TIMING,
    APPROVE_TASK_TIMING,
    CANCEL_TASK_TIMING,
} from '~/store/actions/actionTasks';
import { defaultOptions, tableConfig } from '~/utils/tasks';
import { CLEAR_STATE } from '~/store/actions/actionApp';

export interface tasksState {
    tasks: { [value: string]: ITask };
    taskList: ITaskList;
    calendar: ICalendar;
    tasksFilterName: string;
}

export const initialState: tasksState = {
    tasks: {},
    taskList: {
        ids: [],
        totalCount: 0,
        options: defaultOptions,
        config: tableConfig,
    },
    calendar: {
        ids: [],
        userNorm: 0,
    },
    tasksFilterName: '',
};

const reducerTasks = (state: tasksState = initialState, action: Actions) => {
    switch (action.type) {
        case CLEAR_STATE:
            return initialState;

        case ADD_TASKS:
            const preparedTasks: { [value: string]: ITask } = {};
            action.tasks.forEach((task) => {
                const timings = [
                    ...(state.tasks?.[task.id]?.timings || []),
                    ...(task.timings || []),
                ];
                const preparedTimings = Array.from(
                    new Map(timings.map((item) => [item['id'], item])).values(),
                );
                preparedTasks[task.id] = {
                    ...state.tasks[task.id],
                    ...task,
                    timings: preparedTimings,
                };
            });

            return {
                ...state,
                tasks: action.withClearState ? preparedTasks : { ...state.tasks, ...preparedTasks },
            };

        case UPDATE_TASK:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.id]: {
                        ...state.tasks[action.id],
                        ...action.data.task,
                    },
                },
            };

        case UPDATE_TASK_LIST:
            return {
                ...state,
                taskList: {
                    ...state.taskList,
                    ids: action.additional
                        ? [...state.taskList.ids, ...action.ids]
                        : [...action.ids],
                    totalCount: action.totalCount ?? state.taskList.totalCount,
                },
            };

        case UPDATE_TASK_LIST_OPTIONS:
            return {
                ...state,
                taskList: {
                    ...state.taskList,
                    options: {
                        ...state.taskList.options,
                        ...action.options,
                    },
                },
            };

        case UPDATE_TASK_LIST_CONFIG:
            return {
                ...state,
                taskList: {
                    ...state.taskList,
                    config: action.config.map((innerConfig, key) => {
                        return {
                            ...innerConfig,
                            orderby: tableConfig[key].orderby,
                            name: innerConfig.name === 'cdate' ? 'created_at' : innerConfig.name,
                        };
                    }),
                },
            };

        case ADD_CALENDAR:
            return {
                ...state,
                calendar: {
                    ids: action.taskIds,
                    userNorm: action.userNorm,
                },
            };

        case UPDATE_CALENDAR_IDS:
            return {
                ...state,
                calendar: {
                    ...state.calendar,
                    ids: state.calendar.ids.includes(action.id)
                        ? action.additional
                            ? state.calendar.ids
                            : state.calendar.ids.filter((item) => item !== action.id)
                        : action.additional
                        ? [...state.calendar.ids, action.id]
                        : state.calendar.ids,
                },
            };

        case ADD_TASK_TIMING:
            const timings = [...(state.tasks?.[action.id]?.timings || []), action.timing];
            const preparedTimings = Array.from(
                new Map(timings.map((item) => [item['id'], item])).values(),
            );
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.id]: {
                        ...state.tasks[action.id],
                        id: action.id,
                        ...(action?.title ? { title: action.title } : {}),
                        timings: preparedTimings,
                    },
                },
            };

        case UPDATE_TASKS_FILTER_NAME:
            return {
                ...state,
                tasksFilterName: action.name,
            };

        case ADD_TASK_LOGS:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.taskId]: {
                        ...state.tasks[action.taskId],
                        logs: action.logs,
                    },
                },
            };

        case ADD_TASK_TIMINGS:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.taskId]: {
                        ...state.tasks[action.taskId],
                        timings: action.timings,
                    },
                },
            };

        case UPDATE_TASK_TIMING:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.taskId]: {
                        ...state.tasks[action.taskId],
                        timings: state.tasks[action.taskId].timings?.map((timing) => {
                            if (timing.id === action.timingId) {
                                return {
                                    ...timing,
                                    ...action.data,
                                };
                            }

                            return timing;
                        }),
                    },
                },
            };

        case APPROVE_TASK_TIMING:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.taskId]: {
                        ...state.tasks[action.taskId],
                        timings: state.tasks[action.taskId].timings?.map((timing) => {
                            if (timing.id === action.timingId) {
                                return {
                                    ...timing,
                                    is_edited: true,
                                    stime: timing.request?.stime,
                                    ftime: timing.request?.ftime,
                                    request: null,
                                };
                            }

                            return timing;
                        }),
                    },
                },
            };

        case CANCEL_TASK_TIMING:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.taskId]: {
                        ...state.tasks[action.taskId],
                        timings: state.tasks[action.taskId].timings?.map((timing) => {
                            if (timing.id === action.timingId) {
                                return {
                                    ...timing,
                                    is_edited: true,
                                    request: null,
                                };
                            }

                            return timing;
                        }),
                    },
                },
            };

        default:
            return { ...state };
    }
};

export default reducerTasks;
