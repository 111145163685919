import * as React from 'react';
import styles from './Auth.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, Outlet } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import favicon from '~/assets/icons/favicon.svg';
import { Helmet } from 'react-helmet';
import { deleteMascot } from '~/utils/utils';

const Auth = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    React.useEffect(() => {
        deleteMascot();
    }, []);

    return (
        <div className={styles.authContainer}>
            <Helmet>
                <link id="favicon" rel="icon" href={favicon} type="image/x-icon" />
            </Helmet>
            <div className={styles.firstColumn}>
                <div className={styles.routing}>
                    <NavLink
                        className={() =>
                            [
                                urlsMap.index,
                                urlsMap.resetPassword,
                                urlsMap.resetPasswordSuccess,
                            ].includes(pathname)
                                ? styles.active
                                : undefined
                        }
                        to={urlsMap.index}
                    >
                        {t('Вход')}
                    </NavLink>
                    <NavLink
                        to={urlsMap.register}
                        className={({ isActive }) => (isActive ? styles.active : '')}
                    >
                        {t('Регистрация')}
                    </NavLink>
                </div>
                <Outlet />
            </div>
            <div className={styles.secondColumn}></div>
        </div>
    );
};

export default Auth;
