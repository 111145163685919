import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import { State } from '~/store/reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { accountState } from '~/store/reducers/reducerAccount';

interface IStateSelector {
    account: IAccount;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        account: state.reducerAccount.account,
    };
};

const OtherRoutes = () => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);

    return state.account?.id ? <Navigate to={urlsMap.taskList} /> : <Outlet />;
};

export default OtherRoutes;
