import { Actions } from '~/store/actions';
import { UPDATE_ENTITY } from '~/store/actions/actionEntities';

export const ENTITIES = {
    SPRINTS: 'sprints' as const,
};

export interface entitiesState {
    [ENTITIES.SPRINTS]: ISprintEntities;
}

export const initialState: entitiesState = {
    [ENTITIES.SPRINTS]: {},
};

const reducerEntities = (state: entitiesState = initialState, action: Actions) => {
    switch (action.type) {
        case UPDATE_ENTITY:
            const stateEntity = state[action.entity];

            if (action.add) {
                return {
                    ...state,
                    [action.entity]: {
                        ...stateEntity,
                        [action.data.id]: action.data,
                    },
                };
            }

            if (action.remove) {
                return {
                    ...state,
                    [action.entity]: delete stateEntity[action.data.id],
                };
            }

            return {
                ...state,
                [action.entity]: {
                    ...stateEntity,
                    [action.data.id]: {
                        ...stateEntity[action.data.id],
                        ...action.data,
                    },
                },
            };

        default:
            return { ...state };
    }
};

export default reducerEntities;
