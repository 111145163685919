import store from '~/store';
import { apiGetTags } from '~/api/app';
import { _updateMeta, addTags, updateMeta } from '~/store/actions/actionApp';
import { apiGetGroup, apiGetMeta, apiUpdateMeta } from '~/api/user';
import { META, USER_META } from '~/const';
import { _updateTaskListConfig, _updateTaskListOptions } from '~/store/actions/actionTasks';
import { _updateProjectListOptions } from '~/store/actions/actionProjects';
import { _updateUserListOptions } from '~/store/actions/actionUsers';
import { _updateGroup } from '~/store/actions/actionAccount';
import { getNotifications } from '~/store/slices/notification/reducers';

export const prepareApp = async () => {
    // TODO: сейчас запросы кидаются последовательно, нужно сделать параллельные одновременные запросы (те, которые не зависят от предыдущих)

    store.dispatch(getNotifications());

    const localTags = localStorage.getItem('tags');
    if (!localTags) {
        await apiGetTags().then((res) => {
            const tags = res.filter((tag: ITag) => tag.name.length > 0);
            store.dispatch(addTags(tags));
        });
    } else {
        store.dispatch(addTags(JSON.parse(localTags)));
        apiGetTags().then((res) => {
            const tags = res.filter((tag: ITag) => tag.name.length > 0);
            store.dispatch(addTags(tags));
        });
    }

    await apiGetMeta(USER_META.TASKS_OPTIONS).then((res) => {
        store.dispatch(_updateTaskListOptions(JSON.parse(res[USER_META.TASKS_OPTIONS])));
    });

    await apiGetMeta(USER_META.TASKS_CONFIG).then((res) => {
        if (res[USER_META.TASKS_CONFIG]) {
            if (JSON.parse(res[USER_META.TASKS_CONFIG])['lang']) {
                const object = JSON.parse(res[USER_META.TASKS_CONFIG]);
                delete object['lang'];
                store.dispatch(_updateTaskListConfig(Object.values(object)));
                apiUpdateMeta(USER_META.TASKS_CONFIG, Object.values(object));
            } else {
                store.dispatch(_updateTaskListConfig(JSON.parse(res[USER_META.TASKS_CONFIG])));
            }
        }
    });

    await apiGetMeta(USER_META.PROJECTS_OPTIONS).then((res) => {
        store.dispatch(_updateProjectListOptions(JSON.parse(res[USER_META.PROJECTS_OPTIONS])));
    });

    await apiGetMeta(USER_META.USERS_OPTIONS).then((res) => {
        store.dispatch(_updateUserListOptions(JSON.parse(res[USER_META.USERS_OPTIONS])));
    });

    await apiGetMeta(META.CURRENT_GROUP).then((res) => {
        const object = JSON.parse(res[META.CURRENT_GROUP]);
        const groupId = object?.[META.CURRENT_GROUP];

        store.dispatch(_updateMeta(object ?? { [META.CURRENT_GROUP]: null }));
        if (groupId) {
            apiGetGroup(groupId)
                .then((res) => {
                    store.dispatch(_updateGroup(res.group));
                })
                .catch(() => {
                    // @ts-ignore
                    store.dispatch(updateMeta(META.CURRENT_GROUP, null));
                });
        }
    });
};
