import { Dispatch } from 'react';
import {
    IAddCalendar,
    IUpdateTaskList,
    IUpdateTaskListOptions,
    IUpdateTaskListConfig,
    IUpdateTask,
    IAddTasks,
    IUpdateCalendarIds,
    IAddTaskTiming,
    IUpdateTasksFilterName,
    IAddTaskLogs,
    IAddTaskTimings,
    IUpdateTaskTiming,
    IApproveTaskTiming,
    ICancelTaskTiming,
} from '~/store/actions/types/actionTasksTypes';
import {
    apiApproveTiming,
    apiCancelTiming,
    apiGetTask,
    apiGetTaskList,
    apiGetTaskLogs,
    apiGetTaskTimings,
    apiUpdateTask,
    apiUpdateTaskTiming,
} from '~/api/task';
import { apiUpdateMeta } from '~/api/user';
import {
    LOADERS,
    LOADERS_TYPE,
    MEET_STATUSES,
    TASK_FIELDS,
    TASK_TYPES,
    TOAST,
    USER_META,
} from '~/const';
import { addToast, updateLoaders, updateTags } from '~/store/actions/actionApp';
import {
    IAddToast,
    IUpdateFirstLoaded,
    IUpdateLoaders,
    IUpdateTags,
} from '~/store/actions/types/actionAppTypes';
import { prepareDateToServer } from '~/utils/utils';
import { prepareTaskFromServer } from '~/utils/tasks';
import { put } from '~/utils/api';
import store from '~/store';

export const ADD_TASKS = 'ADD_TASKS';
export const _addTasks = (tasks: ITask[], withClearState?: boolean): IAddTasks => {
    return {
        type: ADD_TASKS,
        tasks,
        withClearState,
    };
};

export const UPDATE_TASK = 'UPDATE_TASK';
export const _updateTask = (data: any, id: number): IUpdateTask => {
    return {
        type: UPDATE_TASK,
        data,
        id,
    };
};

export const UPDATE_TASK_LIST = 'UPDATE_TASK_LIST';
export const _updateTaskList = (
    ids: number[],
    totalCount?: number,
    additional?: boolean,
): IUpdateTaskList => {
    return {
        type: UPDATE_TASK_LIST,
        ids,
        totalCount,
        additional,
    };
};

export const UPDATE_TASK_LIST_OPTIONS = 'UPDATE_TASK_LIST_OPTIONS';
export const _updateTaskListOptions = (options: IMeta): IUpdateTaskListOptions => {
    return {
        type: UPDATE_TASK_LIST_OPTIONS,
        options,
    };
};

export const UPDATE_TASK_LIST_CONFIG = 'UPDATE_TASK_LIST_CONFIG';
export const _updateTaskListConfig = (config: ITableConfig[]): IUpdateTaskListConfig => {
    return {
        type: UPDATE_TASK_LIST_CONFIG,
        config,
    };
};

export const ADD_CALENDAR = 'ADD_CALENDAR';
export const _addCalendar = (taskIds: number[], userNorm: number): IAddCalendar => {
    return {
        type: ADD_CALENDAR,
        taskIds,
        userNorm,
    };
};

export const ADD_TASK_TIMING = 'ADD_TASK_TIMING';
export const _addTaskTiming = (timing: ITiming, id: number, title?: string): IAddTaskTiming => {
    return {
        type: ADD_TASK_TIMING,
        timing,
        id,
        title,
    };
};

export const UPDATE_CALENDAR_IDS = 'UPDATE_CALENDAR_IDS';
export const _updateCalendarIds = (id: number, additional: boolean): IUpdateCalendarIds => {
    return {
        type: UPDATE_CALENDAR_IDS,
        additional,
        id,
    };
};

export const UPDATE_TASKS_FILTER_NAME = 'UPDATE_TASKS_FILTER_NAME';
export const _updateTasksFilterName = (name: string): IUpdateTasksFilterName => {
    return {
        type: UPDATE_TASKS_FILTER_NAME,
        name,
    };
};

export const ADD_TASK_LOGS = 'ADD_TASK_LOGS';
export const _addTaskLogs = (taskId: number, logs: ILog[]): IAddTaskLogs => {
    return {
        type: ADD_TASK_LOGS,
        taskId,
        logs,
    };
};

export const ADD_TASK_TIMINGS = 'ADD_TASK_TIMINGS';
export const _addTaskTimings = (taskId: number, timings: ITiming[]): IAddTaskTimings => {
    return {
        type: ADD_TASK_TIMINGS,
        taskId,
        timings,
    };
};

export const UPDATE_TASK_TIMING = 'UPDATE_TASK_TIMING';
export const _updateTaskTiming = (
    taskId: number,
    timingId: number,
    data: any,
): IUpdateTaskTiming => {
    return {
        type: UPDATE_TASK_TIMING,
        taskId,
        timingId,
        data,
    };
};

export const APPROVE_TASK_TIMING = 'APPROVE_TASK_TIMING';
export const _approveTaskTiming = (taskId: number, timingId: number): IApproveTaskTiming => {
    return {
        type: APPROVE_TASK_TIMING,
        taskId,
        timingId,
    };
};

export const CANCEL_TASK_TIMING = 'CANCEL_TASK_TIMING';
export const _cancelTaskTiming = (taskId: number, timingId: number): ICancelTaskTiming => {
    return {
        type: CANCEL_TASK_TIMING,
        taskId,
        timingId,
    };
};

export const addTasks = (tasks: ITask[], withClearState = false) => {
    return async (dispatch: Dispatch<IAddTasks>): Promise<void> => {
        const preparedTasks = tasks.map((task) => prepareTaskFromServer(task, dispatch));
        dispatch(_addTasks(preparedTasks, withClearState));
    };
};

export const addCalendar = (calendar: ICalendarResponse) => {
    return (dispatch: Dispatch<IAddTasks | IAddCalendar | any>) => {
        const tasksCalendar = [...calendar.data.history, ...calendar.data.future];
        const taskIds = tasksCalendar.map((task) => task.id);
        const userNorm = Number(calendar.userNorm);

        dispatch(addTasks(tasksCalendar));
        dispatch(_addCalendar(taskIds, userNorm));
    };
};

export const updateTask = async (
    data: { [field: string]: any },
    taskId: number,
    previousData: ITask,
    withoutUpdate?: boolean,
    dataForState?: { [field: string]: any },
) => {
    return async (
        dispatch: Dispatch<IUpdateTask | IUpdateLoaders | IUpdateCalendarIds | IUpdateTags>,
    ): Promise<void> => {
        const key = Object.keys(data)[0];
        const isDate = key === TASK_FIELDS.EDATE || key === TASK_FIELDS.WDATE;
        const itAMeet = previousData.type === TASK_TYPES.MEETS;

        dispatch(_updateTask({ task: dataForState ?? data }, taskId));
        dispatch(
            updateLoaders({
                [LOADERS.TASK_LIST]: {
                    [key]: true,
                },
            }),
        );

        if (isDate) {
            data[key] = prepareDateToServer(Object.values(data)[0], undefined, itAMeet);
        }

        return apiUpdateTask(
            taskId,
            isDate && previousData.type === TASK_TYPES.MEETS
                ? { ...data, [TASK_FIELDS.WDATE]: Object.values(data)[0] }
                : data,
        )
            .then((res) => {
                const status = res.task.project.statuses.find(
                    (status: IStatus) => status.id === res.task.status,
                );

                if (status) {
                    dispatch(_updateCalendarIds(taskId, Boolean(status.is_open) && res.task.wdate));
                }

                if (res.task.tags) {
                    dispatch(updateTags(res.task.tags));
                }

                if (!withoutUpdate) {
                    const task = { ...prepareTaskFromServer(res.task, dispatch) };
                    dispatch(_updateTask({ task }, taskId));
                }
            })
            .catch((error) => {
                dispatch(_updateTask({ task: { ...previousData } }, taskId));
                throw error;
            })
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.TASK_LIST]: {
                            [key]: false,
                        },
                    }),
                );
            });
    };
};

export const loadTask = (id: number, additional?: boolean) => {
    return async (
        dispatch: Dispatch<IUpdateTaskList | IUpdateTask | IAddTasks | IUpdateLoaders>,
    ): Promise<void> => {
        dispatch(
            updateLoaders({
                [LOADERS.TASK_LIST]: {
                    [id]: true,
                },
            }),
        );
        await apiGetTask(id).then((res) => {
            const task = { ...prepareTaskFromServer(res.task, dispatch), fullData: true };
            if (!additional) {
                dispatch(_updateTask({ task }, Number(id)));
            } else {
                dispatch(_addTasks([task]));
            }
        });
        dispatch(
            updateLoaders({
                [LOADERS.TASK_LIST]: {
                    [id]: false,
                },
            }),
        );
    };
};

export const loadTaskList = (
    offset: number,
    options: IMeta,
    additional = false,
    withClearState = false,
) => {
    return async (
        dispatch: Dispatch<IUpdateTaskList | IAddTasks | IUpdateLoaders | IUpdateFirstLoaded | any>,
    ): Promise<void> => {
        dispatch(
            updateLoaders({
                [LOADERS.TASK_LIST]: {
                    [LOADERS_TYPE.LOADING]: !additional,
                    [LOADERS_TYPE.ADDITIONAL_LOADING]: additional,
                },
            }),
        );
        apiGetTaskList(offset, options)
            .then((res) => {
                dispatch(
                    _updateTaskList(
                        res.rows.map((task: ITask) => task.id),
                        res.totalCount,
                        additional,
                    ),
                );
                dispatch(addTasks(res.rows, withClearState));
            })
            .finally(() => {
                dispatch(
                    updateLoaders({
                        [LOADERS.TASK_LIST]: {
                            [LOADERS_TYPE.LOADING]: false,
                            [LOADERS_TYPE.ADDITIONAL_LOADING]: false,
                        },
                    }),
                );
            });
    };
};

export const updateTaskListOptions = async (options: IMeta, withoutLoadingTasks?: boolean) => {
    return async (dispatch: Dispatch<IUpdateTaskListOptions>): Promise<void> => {
        await apiUpdateMeta(USER_META.TASKS_OPTIONS, {
            orderby: options.orderby,
            calendarVisible: options.calendarVisible,
        }).then(() => {
            dispatch(_updateTaskListOptions(options));
            if (!withoutLoadingTasks) {
                // @ts-ignore
                dispatch(loadTaskList(0, options));
            }
        });
    };
};

export const updateTaskListConfig = async (config: ITableConfig[]) => {
    return async (dispatch: Dispatch<IUpdateTaskListConfig>): Promise<void> => {
        await apiUpdateMeta(USER_META.TASKS_CONFIG, config).then(() => {
            dispatch(_updateTaskListConfig(config));
        });
    };
};

export const updateMeetStatus = (status: 1 | 2 | 3, taskId: number, userId: number) => {
    return async (dispatch: Dispatch<IUpdateTask>): Promise<void> => {
        const prevData = { ...store.getState().reducerTasks.tasks[taskId]?.assignees };

        const assignees = {
            ...store.getState().reducerTasks.tasks[taskId]?.assignees,
            [userId]: {
                ...store.getState().reducerTasks.tasks[taskId]?.assignees?.[userId],
                sub_status: status,
            },
        };

        dispatch(_updateTask({ task: { [TASK_FIELDS.ASSIGNEES]: { ...assignees } } }, taskId));
        put(`tasks/${taskId}/assignees/${MEET_STATUSES[status]}`).catch(() => {
            dispatch(_updateTask({ task: { [TASK_FIELDS.ASSIGNEES]: prevData } }, taskId));
        });
    };
};

export const addTaskTiming = (timings: IOnlineTiming[]) => {
    return (dispatch: Dispatch<IAddTasks | IAddTaskTiming | any>) => {
        timings.forEach((item) => {
            const timing = {
                id: item.id,
                ftime: item.ftime,
                stime: item.stime,
                userid: item.userid,
                taskid: item.taskid,
            };
            dispatch(_addTaskTiming(timing, item.taskid, item.task_title));
        });
    };
};

export const getTaskLogs = (taskId: number) => {
    return (dispatch: Dispatch<IAddTaskLogs>) => {
        apiGetTaskLogs(taskId).then((res) => {
            dispatch(_addTaskLogs(taskId, res.logs));
        });
    };
};

export const getTaskTimings = (taskId: number) => {
    return (dispatch: Dispatch<IAddTaskTimings>) => {
        apiGetTaskTimings(taskId).then((res) => {
            dispatch(_addTaskTimings(taskId, res.timings));
        });
    };
};

export const updateTaskTiming = (taskId: number, timingId: number, data: any) => {
    return (dispatch: Dispatch<IUpdateTaskTiming | IAddToast>) => {
        apiUpdateTaskTiming(taskId, timingId, data).then((res) => {
            dispatch(
                _updateTaskTiming(taskId, timingId, {
                    request: {
                        stime: res.timing_change_request.stime,
                        ftime: res.timing_change_request.ftime,
                    },
                }),
            );
            dispatch(
                addToast({
                    type: TOAST.SUCCESS,
                    title: 'Запрос отправлен',
                    timer: 3000,
                }),
            );
        });
    };
};

export const cancelTiming = (taskId: number, timingId: number) => {
    return (dispatch: Dispatch<ICancelTaskTiming>) => {
        apiCancelTiming(taskId, timingId).then(() => {
            dispatch(_cancelTaskTiming(taskId, timingId));
        });
    };
};

export const approveTiming = (taskId: number, timingId: number) => {
    return (dispatch: Dispatch<IApproveTaskTiming>) => {
        apiApproveTiming(taskId, timingId).then(() => {
            dispatch(_approveTaskTiming(taskId, timingId));
        });
    };
};
