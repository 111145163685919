export const setTokens = (accessToken: string, refreshToken: string, socket?: string) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    document.cookie = `api_token=${accessToken}; sameSite=none; secure=true; path=/; domain=foach.site`;

    if (socket) {
        localStorage.setItem('socket', socket);
    }
};

export const removeTokens = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('socket');
};
