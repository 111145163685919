import { Actions } from '~/store/actions';
import {
    UPDATE_META,
    UPDATE_DICTIONARIES,
    UPDATE_LOADERS,
    ADD_TAGS,
    ADD_BLOB,
    ADD_TOAST,
    REMOVE_TOAST,
    CHANGE_VISIBLE_POPUP,
    CLEAR_STATE,
    UPDATE_FIRST_LOADED,
    CHANGE_VISIBLE_NOTIFICATION,
    ADD_NOTIFICATIONS,
    READ_NOTIFICATIONS,
    UPDATE_TAGS,
} from '~/store/actions/actionApp';
import { DATE_FORMAT_HHMM, LOADERS, LOADERS_TYPE } from '~/const';
import { uid } from 'uid';
import moment from 'moment';

export interface appState {
    meta: IAppMeta;
    dictionaries: IDictionaries;
    loaders: ILoaders;
    tags: ITag[];
    blobs: IBlob;
    toasts: IToast[];
    popup: IPopup | null;
    firstLoaded: boolean;
    notificationVisible: boolean;
    notifications: INotifications;
}

export const initialState: appState = {
    meta: [],
    dictionaries: {},
    loaders: {
        [LOADERS.TASK_LIST]: {
            [LOADERS_TYPE.LOADING]: true,
        },
        [LOADERS.PROJECT_LIST]: {
            [LOADERS_TYPE.LOADING]: true,
        },
        [LOADERS.SPRINT]: {},
        [LOADERS.SPRINT_TASKS_LIST]: {
            [LOADERS_TYPE.LOADING]: true,
        },
        [LOADERS.SPRINT_BACKLOG_LIST]: {
            [LOADERS_TYPE.LOADING]: true,
            [LOADERS_TYPE.ADDITIONAL_LOADING]: false,
        },
        [LOADERS.USER_LIST]: {
            [LOADERS_TYPE.LOADING]: true,
        },
        [LOADERS.TASK_COMMENT]: {
            [LOADERS_TYPE.LOADING]: false,
        },
        [LOADERS.WIKI]: {
            [LOADERS_TYPE.LOADING]: false,
        },
        [LOADERS.GROUP]: {
            [LOADERS_TYPE.LOADING]: false,
        },
        [LOADERS.GROUP_LIST]: {
            [LOADERS_TYPE.LOADING]: false,
        },
        [LOADERS.SET_PASSWORD]: {
            [LOADERS_TYPE.LOADING]: false,
        },
        [LOADERS.CONFIRM_EMAIL]: {
            [LOADERS_TYPE.LOADING]: false,
        },
        [LOADERS.SEND_CONFIRM_EMAIL]: {
            [LOADERS_TYPE.LOADING]: false,
        },
    },
    tags: [],
    blobs: {},
    toasts: [],
    popup: null,
    firstLoaded: false,
    notificationVisible: false,
    notifications: {
        rows: [],
        totalCount: 0,
        viewedCount: 0,
    },
};

const reducerApp = (state: appState = initialState, action: Actions) => {
    switch (action.type) {
        case CLEAR_STATE:
            return initialState;

        case UPDATE_FIRST_LOADED:
            return {
                ...state,
                firstLoaded: action.loaded,
            };

        case UPDATE_META:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    ...action.options,
                },
            };

        case UPDATE_DICTIONARIES:
            localStorage.setItem('dictionaries', JSON.stringify(action.dictionaries));
            return {
                ...state,
                dictionaries: {
                    ...state.dictionaries,
                    ...action.dictionaries,
                },
            };

        case ADD_TAGS:
            localStorage.setItem('tags', JSON.stringify(action.tags));
            return {
                ...state,
                tags: [...action.tags],
            };

        case UPDATE_TAGS:
            const tags = state.tags.map((item) => item.id);

            return {
                ...state,
                tags: [...state.tags, ...action.tags.filter((item) => !tags.includes(item.id))],
            };

        case UPDATE_LOADERS:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    [Object.keys(action.loaders)[0]]: {
                        ...state.loaders[Object.keys(action.loaders)[0]],
                        ...Object.values(action.loaders)[0],
                    },
                },
            };

        case ADD_BLOB:
            return {
                ...state,
                blobs: {
                    ...state.blobs,
                    ...action.blob,
                },
            };

        case ADD_TOAST:
            return {
                ...state,
                toasts: [
                    ...state.toasts,
                    {
                        ...action.toast,
                        id: uid(),
                        visible: true,
                        time: moment().format(DATE_FORMAT_HHMM),
                        viewed: false,
                    },
                ],
            };

        case REMOVE_TOAST:
            const innerToast = state.toasts.map((toast: IToast) => {
                if (toast.id === action.id) {
                    toast.visible = false;
                }

                return toast;
            });

            return {
                ...state,
                toasts: innerToast,
            };

        case CHANGE_VISIBLE_POPUP:
            return {
                ...state,
                popup: action.popup,
            };

        case CHANGE_VISIBLE_NOTIFICATION:
            return {
                ...state,
                notificationVisible: action.visible,
            };

        case ADD_NOTIFICATIONS:
            const currentNotifications = state.notifications.rows;
            const existsIds = currentNotifications.map((item) => item.id);

            action.row.forEach((item) => {
                if (existsIds.indexOf(item.id) === -1) {
                    currentNotifications.push(item);
                }
            });
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    rows: [...currentNotifications],
                    totalCount: action.totalCount ?? state.notifications.totalCount,
                },
            };

        case READ_NOTIFICATIONS:
            return {
                ...state,
                notifications: {
                    rows: state.notifications.rows.map((row) => {
                        return {
                            ...row,
                            is_read: action.ids
                                ? !row.is_read
                                    ? action.ids.includes(row.id)
                                    : row.is_read
                                : true,
                        };
                    }),
                    totalCount: action.totalCount ?? state.notifications.totalCount,
                    viewedCount: action.ids
                        ? action.viewedCount ?? state.notifications.viewedCount
                        : state.notifications.totalCount,
                },
            };

        default:
            return { ...state };
    }
};

export default reducerApp;
