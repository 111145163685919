import * as React from 'react';
import styles from './Gallery.module.scss';
import { useOnClickOutside } from 'usehooks-ts';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import classNames from 'classnames';
import { ReactComponent as Chevron } from '~/assets/icons/chevron.svg';
import { ReactComponent as Cross } from '~/assets/icons/cross.svg';
import ReactPlayer from 'react-player/lazy';
import Image from '~/components/Image/Image';
import {
    changeVisibleGallerySlide,
    selectGalleryFiles,
    selectVisibleGallerySlide,
    toggleGallery,
} from '~/store/slices/app/slice';
import { useAppSelector } from '~/store';
import { useDispatch } from 'react-redux';

const host = process.env.REACT_APP_API_HOST;

const Gallery = () => {
    const dispatch = useDispatch();
    const currentOriginal = useAppSelector((state) => selectVisibleGallerySlide(state));
    const files = useAppSelector((state) => selectGalleryFiles(state));

    const ref = React.useRef(null);
    const images = files
        .filter((file: IFile) => {
            const isImage = ['png', 'jpeg', 'jpg', 'gif', 'svg'].includes(file.type);
            const isVideo = ['mp4', 'mov', 'webm'].includes(file.type);

            return isImage || isVideo;
        })
        .map((file: IFile) => {
            const isVideo = ['mp4', 'mov', 'webm'].includes(file.type);

            return {
                original: `${host}files${file.link}`,
                renderItem: () => {
                    if (isVideo) {
                        return (
                            <ReactPlayer
                                width="auto%"
                                url={`${host}files${file.link}`}
                                controls={true}
                                playing={currentOriginal === `${host}files${file.link}`}
                            />
                        );
                    }

                    return (
                        <Image
                            alt={file.title}
                            className="image-gallery-image"
                            src={`${host}files${file.link}`}
                            withLoader={true}
                        />
                    );
                },
            } as ReactImageGalleryItem;
        });

    const startIndex = images.findIndex((item) => item.original === currentOriginal);

    useOnClickOutside(ref, () => dispatch(toggleGallery(false)));

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            dispatch(toggleGallery(false));
        }
    };

    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className={styles.galleryWrapper}>
            <div ref={ref}>
                <ImageGallery
                    startIndex={startIndex}
                    items={images}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    disableKeyDown={false}
                    slideDuration={300}
                    renderLeftNav={(onClick, disabled) => (
                        <div
                            className={classNames(styles.nav, styles.leftNav, {
                                ['disabled']: disabled,
                            })}
                            onClick={onClick}
                        >
                            <Chevron />
                        </div>
                    )}
                    renderRightNav={(onClick, disabled) => (
                        <div
                            className={classNames(styles.nav, styles.rightNav, {
                                ['disabled']: disabled,
                            })}
                            onClick={onClick}
                        >
                            <Chevron />
                        </div>
                    )}
                    onSlide={(nextSlide) => {
                        dispatch(changeVisibleGallerySlide(images[nextSlide].original));
                    }}
                />
            </div>
            <div className={styles.close} onClick={() => dispatch(toggleGallery(false))}>
                <Cross />
            </div>
        </div>
    );
};

export default Gallery;
