import * as React from 'react';
import Select, { components } from 'react-select';
import styles from './Select.module.scss';
import { getColor, prepareOptions } from '~/utils/utils';
import { ReactComponent as Chevron } from '~/assets/icons/chevron.svg';
import { customStyles } from '~/components/Form/Select/style';
import classNames from 'classnames';
import Status from '~/components/Icons/Status/Status';
import Priority from '~/components/Icons/Priority/Priority';
import { ICON } from '~/const';
import { ReactComponent as Check } from '~/assets/icons/check.svg';
import TaskType from '~/components/Icons/TaskType/TaskType';
import Circle from '~/components/Loader/Circle/Circle';

interface ISelect {
    options: ISelectOption;
    preparedOptions?: ISelectPreparedOptions;
    defaultValue?: string | number | null | any;
    placeholder?: string;
    isSearchable?: boolean;
    onChange: (value: ISelectValue) => void;
    className?: string;
    classNameWrapper?: string;
    label?: string;
    withoutBorder?: boolean;
    iconName?: string;
    loading?: boolean;
    multi?: boolean;
    multiColor?: boolean;
    disabled?: boolean;
    openMenuOnClick?: boolean;
    hiddenValue?: boolean;
    projectId?: number;
    menuLeft?: boolean;
    newMulti?: boolean;
    withoutSort?: boolean;
}

const SelectField = ({
    options,
    preparedOptions,
    defaultValue,
    placeholder = '',
    isSearchable = false,
    onChange,
    className,
    classNameWrapper,
    label,
    withoutBorder,
    iconName,
    loading,
    multi,
    multiColor,
    disabled,
    openMenuOnClick,
    hiddenValue,
    projectId,
    menuLeft,
    withoutSort,
}: ISelect) => {
    if (!options) {
        return null;
    }

    const innerPreparedOptions = preparedOptions ?? prepareOptions(options);

    if (!withoutSort) {
        innerPreparedOptions.sort((a, b) => a.label?.localeCompare(b.label));
    }

    let _defaultValue = multi
        ? defaultValue
        : innerPreparedOptions.find((option) => option.value == defaultValue);

    if (!_defaultValue && iconName === ICON.STATUS) {
        _defaultValue = { value: defaultValue, label: 'Неизвестный' };
    }

    if (!_defaultValue && iconName === ICON.PRIORITY) {
        _defaultValue = { value: 0, label: 'Не указан' };
    }

    const renderIcon = (value: string) => {
        switch (iconName) {
            case ICON.STATUS:
                return <Status statusId={value} projectId={projectId || 0} withoutTooltip={true} />;
            case ICON.PRIORITY:
                return <Priority priority={value} />;
            case ICON.TYPE:
                return <TaskType type={value} withoutTooltip={true} />;
            default:
                return null;
        }
    };

    return (
        <div className={classNames(styles.selectContainer, classNameWrapper)}>
            <div className={styles.selectTop}>
                {label && <label className={styles.selectLabel}>{label}</label>}
            </div>
            <Select
                // @ts-ignore
                styles={customStyles(withoutBorder, multiColor, menuLeft)}
                className={classNames(styles.select, className)}
                options={innerPreparedOptions}
                value={_defaultValue}
                onChange={(newValue) => onChange(newValue)}
                placeholder={placeholder}
                isLoading={loading}
                isSearchable={isSearchable}
                // @ts-ignore
                isMulti={multi}
                aria-sort={'ascending'}
                isClearable={false}
                openMenuOnClick={openMenuOnClick}
                closeMenuOnSelect={!hiddenValue}
                hideSelectedOptions={false}
                controlShouldRenderValue={!hiddenValue}
                menuIsOpen={hiddenValue}
                isDisabled={disabled || loading}
                openMenuOnFocus={true}
                isOptionDisabled={(option) => !!option.disabled}
                components={{
                    DropdownIndicator: (props) => {
                        if (disabled) {
                            return null;
                        } else {
                            return (
                                <components.DropdownIndicator {...props}>
                                    <Chevron />
                                </components.DropdownIndicator>
                            );
                        }
                    },
                    LoadingIndicator: () => {
                        return <Circle className={styles.loading} />;
                    },
                    Option: (props) => (
                        <components.Option {...props}>
                            {iconName && (
                                <div className={styles.optionIcon}>
                                    {renderIcon(props.data.value)}
                                </div>
                            )}
                            <div className={styles.option}>{props.data.label}</div>
                            {props.isMulti && props.isSelected && (
                                <div className={styles.selectedIcon}>
                                    <Check />
                                </div>
                            )}
                        </components.Option>
                    ),
                    SingleValue: ({ children, ...props }) => (
                        <components.SingleValue {...props}>
                            {iconName && (
                                <div className={styles.optionIcon}>
                                    {renderIcon(props.data.value)}
                                </div>
                            )}
                            <div className={styles.singleValue}>{children}</div>
                        </components.SingleValue>
                    ),
                    MultiValue: ({ children, ...props }) => {
                        return (
                            <components.MultiValue
                                {...props}
                                className={classNames(
                                    multiColor && getColor(String(children)),
                                    styles.multiValue,
                                )}
                            >
                                {children}
                            </components.MultiValue>
                        );
                    },
                    NoOptionsMessage: () => (
                        <div className={styles.noOptionsMessage}>Ничего не найдено</div>
                    ),
                }}
            />
        </div>
    );
};

export default SelectField;
