import * as React from 'react';
import styles from './Aside.module.scss';
import { urlsMap } from '~/utils/urls';
import { Link, Outlet, Route, Routes, useParams } from 'react-router-dom';
import { ReactComponent as Cross } from '~/assets/icons/cross.svg';
import { GROUP_ROLE, META } from '~/const';
import { State } from '~/store/reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';

const TaskListAside = ({
    projectId,
    visibleCreate,
}: {
    projectId?: string;
    visibleCreate: boolean;
}) => {
    return (
        <>
            {visibleCreate && (
                <Link
                    to={urlsMap.createTask}
                    state={{
                        projectId,
                    }}
                >
                    <Cross />
                </Link>
            )}
        </>
    );
};

const ProjectListAside = ({ visibleCreate }: { visibleCreate: boolean }) => {
    return (
        <>
            {visibleCreate && (
                <Link to={urlsMap.createProject}>
                    <Cross />
                </Link>
            )}
        </>
    );
};

interface IStateSelector {
    account: IAccount;
    meta: IAppMeta;
    dictionaries: IDictionaries;
    tasks: ITaskEntities;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        account: state.reducerAccount.account,
        meta: state.reducerApp.meta,
        dictionaries: state.reducerApp.dictionaries,
        tasks: state.reducerTasks.tasks,
    };
};

const Aside = () => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const projectId = Object.values(useParams())[0];
    const activeGroup = state.account?.groups?.find(
        (group) => group.id === state.meta[META.CURRENT_GROUP],
    );
    const canProjectCreate =
        [GROUP_ROLE.OWNER, GROUP_ROLE.MANAGER].includes(activeGroup?.group_role_id || 1) &&
        !!state.account.groups?.length;
    const projects = { ...state.dictionaries.projects };

    return (
        <aside className={styles.aside}>
            <Routes>
                <Route
                    path={`${urlsMap.taskList}/*`}
                    element={
                        <TaskListAside
                            projectId={
                                projectId ? state.tasks[projectId]?.project?.toString() : undefined
                            }
                            visibleCreate={Object.values(projects).length > 0}
                        />
                    }
                />
                <Route path={`${urlsMap.projectList}/*`} element={<Outlet />}>
                    <Route index element={<ProjectListAside visibleCreate={canProjectCreate} />} />
                    <Route
                        path=":id"
                        element={
                            <TaskListAside
                                projectId={projectId}
                                visibleCreate={Object.values(projects).length > 0}
                            />
                        }
                    />
                    <Route
                        path="*"
                        element={<ProjectListAside visibleCreate={canProjectCreate} />}
                    />
                </Route>
            </Routes>
        </aside>
    );
};

export default Aside;
