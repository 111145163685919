import * as React from 'react';
import styles from './App.module.scss';
import '~/styles/index.scss';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import store from '~/store';
import Routing from './Routing';
import { deleteMascot } from '~/utils/utils';
import Button from '~/components/Button/Button';
import { urlsMap } from '~/utils/urls';
import { BUTTON_COLOR } from '~/const';

const App = () => {
    const fallback = () => {
        deleteMascot();
        return (
            <div className={styles.frontendError}>
                <img src={require('~/assets/icons/error.png')} alt="Ошибка" />
                <p>
                    Произошла какая-то ошибка
                    <br />
                    мы уже передали её разработчикам
                </p>
                <a href={urlsMap.index}>
                    <Button color={BUTTON_COLOR.BLUE}>Вернуться на главную</Button>
                </a>
            </div>
        );
    };

    return (
        <Sentry.ErrorBoundary fallback={fallback}>
            <Provider store={store}>
                <Routing />
            </Provider>
        </Sentry.ErrorBoundary>
    );
};

export default App;
