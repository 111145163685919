import * as React from 'react';
import styles from './Calendar.module.scss';
import classNames from 'classnames';
import { State } from '~/store/reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import Popover from '~/containers/Tasks/Calendar/Popover';
import { useOnClickOutside } from 'usehooks-ts';
import moment, { Moment } from 'moment';
import {
    prepareTimeFromMinutes,
    difficultyToMs,
    generateRangeArray,
    prepareHMFromSeconds,
} from '~/utils/utils';
import { DATE_FORMAT, TASK_TYPES } from '~/const';

interface IFC {
    className: string;
}

interface IStateSelector {
    tasks: ITaskEntities;
    calendar: ICalendar;
    projects: IProjectEntities;
    accountId?: number;
    workedTime?: number;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        tasks: state.reducerTasks.tasks,
        calendar: state.reducerTasks.calendar,
        accountId: state.reducerAccount.account.id,
        workedTime: state.reducerAccount.account.workedTime,
        projects: state.reducerProjects.projects,
    };
};

export interface IPopoverPopup {
    date: Moment;
    isCurrentDate: boolean;
    popover: ITask[];
    top: number;
    right: number;
}

export const isFutureDay = (date?: string, day?: Moment) =>
    day &&
    moment(date, DATE_FORMAT).isSame(day, 'dates') &&
    moment(date, DATE_FORMAT).isSameOrAfter(moment(), 'dates');

export const isSameDay = (date?: string, day?: Moment) =>
    day && moment(date, DATE_FORMAT).isSame(day, 'dates');

export const isFutureWeek = (week: number, date?: string) =>
    date && week === moment(date, 'DD.MM.YYYY').week();

export const timeDiff = (stime: string | Moment, ftime: string | Moment) => {
    return moment(ftime).diff(moment(stime));
};

export const calculateTimeTask = (
    time: number,
    timing: ITiming[] = [],
    isSame: boolean,
    day?: Moment,
) => {
    let sum = time;
    sum += timing.reduce((acc, item) => {
        const diff = timeDiff(item.stime, item.ftime || moment());
        if (day && moment(day).isSame(item.stime, 'days')) {
            if (isSame) {
                if (!moment(item.stime).isSameOrAfter(moment(), 'dates')) {
                    acc += diff;
                }
            } else {
                acc += diff;
            }
        } else {
            acc -= diff;
        }
        return acc;
    }, 0);

    return sum < 0 ? 15 * 1000 * 60 : sum > 0 ? sum : time;
};

const calculateTimeWeekTasks = (
    isLeft: boolean,
    time: number,
    timing: ITiming[] = [],
    userId?: number,
    week?: number,
) => {
    let sum = time;
    sum += timing?.reduce((acc, item) => {
        const diff = timeDiff(item.stime, item.ftime || moment());
        if (week && week === moment(item.stime).week()) {
            if (!item.userid || item.userid === userId) {
                acc += diff;
            }
        } else {
            acc -= diff;
        }
        return acc;
    }, 0);

    if (isLeft) {
        return sum < 0 ? 15 * 1000 * 60 : sum > 0 ? sum : time;
    } else {
        return sum > 0 ? sum : time;
    }
};

const checkTaskExecutive = (executive?: number, accountId?: number) => executive !== accountId;

const isCurrentDay = (day: Moment) => day && day.isSame(moment(), 'dates');

const Calendar = ({ className }: IFC) => {
    const ref = React.useRef(null);
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const [popoverVisible, setPopoverVisible] = React.useState<IPopoverPopup | undefined>();
    const startDate = moment().weekday(-14);
    const rangeDayArray: Moment[] = generateRangeArray(35, startDate, 'days');
    const rangeWeekArray: number[] = generateRangeArray(35, startDate, 'days', true);

    const openPopover = (props: IPopoverPopup) => {
        setPopoverVisible(props);
    };

    const closePopover = () => {
        setPopoverVisible(undefined);
    };

    useOnClickOutside(ref, closePopover);

    const getDayData = (day: Moment) => {
        const tasksGetDayData: ITask[] = [];
        const taskIds: number[] = [];

        state.calendar.ids.forEach((id) => {
            const task = { ...state.tasks?.[id] };

            if (!task) return;

            if (
                checkTaskExecutive(task.executive, state.accountId) &&
                task.type !== TASK_TYPES.MEETS
            ) {
                task.wdate = '';
            }

            const currentTask = { ...task };
            const addedTimings: ITiming[] = [];
            if (isSameDay(task.wdate, day)) {
                if (!taskIds.includes(task.id)) {
                    taskIds.push(task.id);
                    tasksGetDayData.push(currentTask);
                }
            } else {
                task.timings = task?.timings?.filter(
                    (timing) => !timing.userid || timing.userid === state.accountId,
                );
                if (task.timings) {
                    task.timings.forEach((timing) => {
                        if (moment(day).isSame(timing.stime, 'days')) {
                            addedTimings.push(timing);

                            if (!taskIds.includes(task.id)) {
                                taskIds.push(task.id);
                                currentTask.timings = addedTimings;
                                tasksGetDayData.push(currentTask);
                            }
                        }
                    });
                }
            }
        });

        let allTime = 0;

        tasksGetDayData.forEach((task) => {
            const project = task?.project ? state.projects?.[task.project] : null;

            const status = project?.statuses.find((item) => item.id === task.status);

            let taskTime = 0;

            if (isFutureDay(task.wdate, day) && status?.is_open) {
                if (!!task.has_subtasks) {
                    taskTime = difficultyToMs(15);
                } else {
                    taskTime = difficultyToMs(task.difficulty);
                }

                if (task.timings && task.difficulty) {
                    taskTime = calculateTimeTask(taskTime, task.timings, false, undefined);
                }
            } else if (task.timings) {
                taskTime = calculateTimeTask(taskTime, task.timings, true, day);
            }

            allTime += taskTime;
        });

        return { tasks: tasksGetDayData, time: allTime / 1000 };
    };

    const weekTotalTime = (week: number) => {
        const weekTask: ITask[] = [];
        const taskIds: number[] = [];

        state.calendar.ids.forEach((id) => {
            const task = { ...state.tasks?.[id] };

            if (!task) return;

            if (
                checkTaskExecutive(task.executive, state.accountId) &&
                task.type !== TASK_TYPES.MEETS
            ) {
                task.wdate = '';
            }

            const currentTask = { ...task };
            const addTiming: ITiming[] = [];

            if (isFutureWeek(week, task.wdate)) {
                if (!taskIds.includes(task.id)) {
                    taskIds.push(task.id);
                    weekTask.push(currentTask);
                }
            } else {
                if (task.timings) {
                    task.timings.forEach((timing) => {
                        if (week === moment(timing.stime).week()) {
                            addTiming.push(timing);
                            if (!taskIds.includes(task.id)) {
                                taskIds.push(task.id);
                                currentTask.timings = addTiming;
                                weekTask.push(currentTask);
                            }
                        }
                    });
                }
            }
        });

        let workingTime = 0;
        let remainingTime = 0;

        weekTask.forEach((task) => {
            const project = task?.project ? state.projects?.[task.project] : null;

            const status = project?.statuses.find((item) => item.id === task.status);

            let taskTimeW = 0;
            let taskTimeR = 0;

            taskTimeW = calculateTimeWeekTasks(
                false,
                taskTimeW,
                task.timings,
                state.accountId,
                week,
            );

            if (
                week >= moment(task.wdate, DATE_FORMAT).week() &&
                week >= moment().week() &&
                moment(task.wdate, DATE_FORMAT).isSameOrAfter(moment(), 'dates') &&
                status?.is_open
            ) {
                if (!!task.has_subtasks) {
                    taskTimeR += difficultyToMs(15);
                } else {
                    taskTimeR += difficultyToMs(task.difficulty);
                }

                if (task.timings && task.difficulty) {
                    taskTimeR = calculateTimeWeekTasks(
                        true,
                        taskTimeR,
                        task.timings,
                        state.accountId,
                    );
                }
            }
            remainingTime += taskTimeR;
            workingTime += taskTimeW;
        });

        return { workingTime: workingTime / 1000 / 60, remainingTime: remainingTime / 1000 / 60 };
    };

    return (
        <div className={classNames(styles.calendarWrapper, className)}>
            <div className={styles.daysWeek}>
                {['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', ''].map((day, key) => (
                    <div key={key} className={styles.dayWeek}>
                        {day}
                    </div>
                ))}
            </div>
            <div className={styles.calendar}>
                <>
                    {rangeDayArray.map((day, key) => {
                        const dayData = getDayData(day);
                        const time = dayData.time / 60 / 60;
                        return (
                            <div
                                className={classNames(styles.day, {
                                    [styles.active]:
                                        popoverVisible && day.isSame(popoverVisible.date, 'dates'),
                                    [styles.dayToday]: day.isSame(new Date(), 'day'),
                                    [styles.dayBefore]: day.isBefore(new Date(), 'day'),
                                    [styles.cursorPointer]: !!dayData.tasks.length,
                                })}
                                key={key}
                                onClick={() =>
                                    !!dayData.tasks.length &&
                                    openPopover({
                                        date: day,
                                        isCurrentDate: day.isSame(moment(), 'dates'),
                                        popover: dayData.tasks,
                                        top: 35 + 55 * Math.ceil((key + 1) / 7),
                                        right: 55 * (Math.ceil((key + 1) / 7) * 7 - key),
                                    })
                                }
                            >
                                {!!dayData.tasks.length && (
                                    <div className={styles.tasksInDay}>{dayData.tasks.length}</div>
                                )}
                                <span
                                    className={classNames(styles.dayTime, {
                                        [styles.currentDate]: isCurrentDay(day),
                                        ['colorSuccess']: time >= state.calendar.userNorm,
                                        ['colorWarning']:
                                            time < state.calendar.userNorm &&
                                            time >= state.calendar.userNorm * 0.5,
                                        ['colorError']:
                                            time < state.calendar.userNorm &&
                                            time < state.calendar.userNorm * 0.5,
                                    })}
                                >
                                    {!!dayData.time &&
                                        !isCurrentDay(day) &&
                                        prepareTimeFromMinutes(dayData.time / 60)}
                                    {isCurrentDay(day) && (
                                        <>
                                            <p>{prepareHMFromSeconds(state.workedTime || 0)}</p>
                                            {prepareTimeFromMinutes(dayData.time / 60)}
                                        </>
                                    )}
                                </span>
                                <div className={styles.dayDate}>{day.format('D')}</div>
                            </div>
                        );
                    })}
                    <div className={styles.weekTotalList}>
                        {rangeWeekArray.map((week, key) => {
                            const time = weekTotalTime(week);
                            return (
                                <div key={key} className={styles.weekTotal}>
                                    {!!time.workingTime && prepareTimeFromMinutes(time.workingTime)}
                                    {!!time.remainingTime && (
                                        <p
                                            className={classNames(styles.timeLeft, {
                                                [styles.border]: !!time.workingTime,
                                            })}
                                        >
                                            {prepareTimeFromMinutes(time.remainingTime)}
                                        </p>
                                    )}
                                    <div className={styles.weekNumber}>{week}</div>
                                </div>
                            );
                        })}
                    </div>
                </>
            </div>
            <div ref={ref}>
                <Popover props={popoverVisible} closeHandler={closePopover} />
            </div>
        </div>
    );
};

export default Calendar;
