import * as React from 'react';
import styles from './Status.module.scss';
import classNames from 'classnames';
import Tooltip from '~/components/Tooltip/Tooltip';
import { State } from '~/store/reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { PLACE } from '~/const';
import Skeleton, { SKELETON } from '~/components/Skeleton/Skeleton';

interface IStateSelector {
    projects: IProjectEntities;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        projects: state.reducerProjects.projects,
    };
};

interface IStatus {
    statusId?: number | string;
    projectId?: number;
    className?: string;
    classNameTooltip?: string;
    withoutTooltip?: boolean;
    withoutProject?: boolean;
    place?: string;
}

const Status = ({
    statusId,
    projectId,
    className,
    withoutTooltip,
    withoutProject,
    place,
    classNameTooltip,
}: IStatus) => {
    if (!statusId || !projectId) return <Skeleton type={SKELETON.STATUS} />;

    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const statuses = state.projects?.[projectId]?.statuses;
    const statusData = statuses?.find((s) => s.id == statusId);

    const element = (
        <div
            className={classNames(
                styles.status,
                `status${
                    typeof statusData?.icon === 'number'
                        ? statusData.icon
                        : withoutProject
                        ? statusId
                        : 12
                }`,
                className,
            )}
        />
    );

    if (!withoutTooltip) {
        return (
            <Tooltip
                className={classNameTooltip}
                text={statusData?.title ?? 'Неизвестный'}
                place={place ? place : PLACE.LEFT}
            >
                {element}
            </Tooltip>
        );
    }

    return element;
};

export default Status;
