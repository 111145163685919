import * as React from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';
import { BUTTON_COLOR, BUTTON_SIZE } from '~/const';
import Circle from '~/components/Loader/Circle/Circle';

interface IButton {
    children: React.ReactChild | null;
    size?: string;
    color?: string;
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => void;
    className?: string;
    type?: 'button' | 'submit' | 'reset';
}

const Button = ({
    children,
    size,
    color,
    disabled,
    loading,
    onClick,
    className,
    type,
}: IButton) => {
    return (
        <button
            className={classNames(styles.button, className, {
                [styles.buttonBig]: size === BUTTON_SIZE.BIG,
                [styles.buttonSmall]: size === BUTTON_SIZE.SMALL,
                [styles.buttonBlue]: color === BUTTON_COLOR.BLUE,
                [styles.buttonBlue200]: color === BUTTON_COLOR.BLUE200,
                [styles.buttonRed]: color === BUTTON_COLOR.RED,
                [styles.buttonGray]: color === BUTTON_COLOR.GRAY,
                [styles.disabled]: disabled,
                [styles.loading]: loading,
            })}
            onClick={onClick}
            type={type}
        >
            {children}
            {!!loading && <Circle className={styles.loader} />}
        </button>
    );
};

export default Button;
