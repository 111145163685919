export const urlsMap = {
    index: '/',
    register: '/register',
    resetPassword: '/resetPassword',
    resetPasswordSuccess: '/resetPassword/success',
    taskList: '/tasks',
    createTask: '/tasks/create',
    projectList: '/projects',
    createProject: '/projects/create',
    sprintList: '/sprints',
    processList: '/processes',
    wiki: '/wiki',
    statisticList: '/statistics',
    userList: '/users',
    emailConfirm: '/email-confirm',
    statuses: '/statuses',
    edit: '/edit',
    online: '/online',
    logs: '/logs',
    timings: '/timings',
    inviteGroup: '/invite/group',
    inviteProject: '/invite/project',
};
